import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import SwitchGroup from '../controls/SwitchGroup';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function EventHideDeclined(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.HideDeclinedEvents;
  const preferenceValue = preferences?.hideDeclinedEvents;

  return (
    <SwitchGroup
      selected={preferenceValue === false}
      onSelect={() =>
        updatePreference(
          preferenceName,
          preferenceValue === true ? 'false' : 'true'
        )
      }
    >
      <PageControlHeader
        title="Show declined meetings"
        description="When you respond Not Going to an event, we still show it in your calendar"
        noPadding={true}
      />
    </SwitchGroup>
  );
});
