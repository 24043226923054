import { useCallback } from 'react';
import { useSendFeedbackMutation } from '../graphql/mutations/SendFeedback.graphql';

const useFeedback = () => {
  const [, sendFeedbackMutation] = useSendFeedbackMutation();

  const sendFeedback = useCallback(
    async (payload) => {
      await sendFeedbackMutation({
        content: payload.feedback,
        metadata: `${navigator.userAgent} - ${navigator.language}`,
      });
    },
    [sendFeedbackMutation]
  );

  return {
    sendFeedback,
  };
};

export default useFeedback;
