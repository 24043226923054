import React from 'react';
import ShortcutKey from './ShortcutKey';

interface Props {
  combo: string;
}

export default function ShortcutKeys({ combo }: Props): JSX.Element {
  const keys = combo.split('+');

  return (
    <div className="flex justify-between space-x-1.5 text-base select-none cursor-default">
      {keys.map((key) => (
        <ShortcutKey key={key} value={key} />
      ))}
    </div>
  );
}
