import classNames from 'classnames';
import { UseDraggingCursor } from 'hooks/useDraggingCursor';
import useInterface from 'hooks/useInterface';
import dynamic from 'next/dynamic';
import React from 'react';
import { TEST_ID_IS_LOGGED_IN } from 'utils/constants';
import ModalEventConfirmation from './ModalEventConfirmation';
import ModalSetStatus from './Status/ModalSetStatus';

const Toasts = dynamic(() => import('joy/Toasts'), { ssr: false });
interface Props {
  children: React.ReactNode;
}

export default React.memo(function LayoutLoggedIn({ children }: Props) {
  const { isScaled } = useInterface();

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 h-full w-full transform transition duration-240 ease-in-out',
        {
          'scale-95 opacity-0': isScaled,
          'scale-100 opacity-100': !isScaled,
        }
      )}
    >
      <span data-testid={TEST_ID_IS_LOGGED_IN} />
      <main className="flex h-screen w-full outline-none">{children}</main>
      <ModalEventConfirmation />
      <ModalSetStatus />
      <UseDraggingCursor />
      <Toasts />
    </div>
  );
});
