/* de5d3f1bb6a178502d64ed19d6d365a5921d7735
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCalendarLinkMutationVariables = Types.Exact<{
  objects: Types.UpdateCalendarLinkInput;
}>;


export type UpdateCalendarLinkMutation = { __typename?: 'mutation_root', updateCalendarLink: { __typename?: 'UpdateCalendarLinkOutput', id: string, alias?: string | null } };


export const UpdateCalendarLinkDocument = gql`
    mutation UpdateCalendarLink($objects: UpdateCalendarLinkInput!) {
  updateCalendarLink(objects: $objects) {
    id
    alias
  }
}
    `;

export function useUpdateCalendarLinkMutation() {
  return Urql.useMutation<UpdateCalendarLinkMutation, UpdateCalendarLinkMutationVariables>(UpdateCalendarLinkDocument);
};