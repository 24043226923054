import React from 'react';
import { useSelectedCalendarLink } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { useUpdateCalendarLink } from 'hooks/calendarLink/creation/useUpdateCalendarLink';
import { AliasCard } from './AliasCard';
import { LocationCard } from './LocationCard';
import { TitleAndDescriptionCard } from './TitleAndDescriptionCard';
import Button from 'joy/Button';
import IconCaretRight from 'components/Icons/IconCaretRight';

interface Props {
  handleHideDetails: () => void;
}

export default function ShareBarDetails({ handleHideDetails }: Props) {
  const calendarLink = useSelectedCalendarLink();
  const { updateCalendarLink } = useUpdateCalendarLink();
  if (!calendarLink) return null;

  return (
    <div className="relative flex gap-2">
      <div className="absolute left-0 -top-9 flex w-full items-center justify-center">
        <Button
          className="bg-popover pointer-events-auto flex h-7 items-center rounded-full px-3 shadow-md hover:bg-gray-100 dark:hover:bg-gray-600"
          onClick={handleHideDetails}
        >
          <IconCaretRight className="text-secondary h-2 w-2 rotate-90" />
          <small className="ml-1.5 text-[11px] font-black uppercase leading-7 tracking-wider text-gray-600 transition-colors dark:text-gray-300">
            Hide details
          </small>
        </Button>
      </div>

      <div className="bg-popover pointer-events-auto relative flex w-3/5 rounded-xl py-3 shadow-md">
        <TitleAndDescriptionCard
          calendarLink={calendarLink}
          onChange={updateCalendarLink}
        />
      </div>

      <div className="bg-popover pointer-events-auto relative flex w-2/5 gap-6 rounded-xl p-3 shadow-md">
        <div className="flex w-full flex-col gap-5">
          <LocationCard
            calendarLink={calendarLink}
            onChange={updateCalendarLink}
          />
          <AliasCard
            calendarLink={calendarLink}
            onChange={updateCalendarLink}
          />
        </div>
      </div>
    </div>
  );
}
