import { DragOverlay, useDndContext } from '@dnd-kit/core';
import IconEyeClosed from 'components/Icons/IconEyeClosed';
import { useUserEmail } from 'hooks/auth/authAtoms';
import useFavorites from 'hooks/useFavorites';
import useHotkey from 'hooks/useHotkey';
import {
  useSetVisibleCalendars,
  visibleContactsCalendarsAtom,
} from 'hooks/useVisibleCalendars';
import { useAtomValue } from 'jotai/utils';
import Avatar from 'joy/Avatar';
import { DateTime, Interval } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { DroppableId } from 'types/drag-and-drop';
import { UserStatus } from 'types/status';
import ContactAvatar from './ContactAvatar';
import NavigationItem from './NavigationItem';
import useAvailableCalendars from './useAvailableCalendars';

export default React.memo(function ContactList() {
  const { addCalendar, removeCalendar, resetContactsCalendars } =
    useSetVisibleCalendars();
  const visibleContactsCalendarsLength = useAtomValue(
    visibleContactsCalendarsAtom
  ).length;

  const availableCalendars = useAvailableCalendars();
  const userEmail = useUserEmail();
  const favorites = useFavorites();
  const { active, over } = useDndContext();

  const isAvatarActive = useCallback(
    (email: string) => availableCalendars.some((cal) => cal.id === email),
    [availableCalendars]
  );

  const toggleContactVisibility = useCallback(
    (email: string) => () => {
      if (active) return;

      if (isAvatarActive(email)) {
        removeCalendar(email);
      } else {
        addCalendar(email);
      }
    },
    [removeCalendar, isAvatarActive, addCalendar, active]
  );

  const contactList = useMemo(() => {
    if (!userEmail || !favorites.length) return [];

    const filteredContacts = favorites.filter(
      (cal) =>
        !cal.emailAddress.includes('+') &&
        !cal.emailAddress.includes('noreply') &&
        !cal.emailAddress.startsWith('team@') &&
        !cal.emailAddress.startsWith('contact@') &&
        !cal.emailAddress.startsWith('job@') &&
        !cal.emailAddress.startsWith('analytics') &&
        !cal.emailAddress.startsWith('support@')
    );

    /**
     * Not crazy about doing this here, but it's a mess to
     * override status based on current ongoing event.
     */
    const recalculatedStatuses = filteredContacts.map((contact) => {
      if (!contact.status) return contact;

      const onGoingEventInterval = Interval.fromDateTimes(
        new Date(contact.status.integrationAmieStartAt),
        new Date(contact.status.integrationAmieEndAt)
      );

      const isHappening = onGoingEventInterval.contains(DateTime.now());
      return {
        ...contact,
        status: {
          ...contact.status,
          status: isHappening ? 'busy' : contact.status.status,
        },
      };
    });

    return recalculatedStatuses;
  }, [userEmail, favorites]);

  useHotkey('shift+escape', 'global', resetContactsCalendars);

  const draggingContact = contactList.find(
    (contact) => contact.id && contact.id === active?.id
  );
  const isOverSchedule = over?.id === DroppableId.SCHEDULE;

  if (contactList.length === 0) return null;

  return (
    <div className="mb-2.5 flex flex-col items-center space-y-2.5">
      {contactList.map((contact, index) => {
        return (
          <ContactAvatar
            key={`contact-list-${contact.id}`}
            index={index + 1}
            user={{
              id: contact.id,
              displayName: contact.displayName,
              avatar: contact.avatar,
              emailAddress: contact.emailAddress,
              favoriteAt: contact.favoriteAt,
            }}
            status={contact.status as UserStatus}
            active={isAvatarActive(contact.emailAddress)}
            onClick={toggleContactVisibility(contact.emailAddress)}
          />
        );
      })}

      {!isOverSchedule && draggingContact && (
        <DragOverlay>
          <Avatar
            size={32}
            alternateStatus={true}
            src={draggingContact.avatar}
            name={draggingContact.displayName || draggingContact.emailAddress}
            isLoading={false}
            ring={undefined}
            isSidebar={true}
          />
        </DragOverlay>
      )}

      {visibleContactsCalendarsLength > 0 && (
        <NavigationItem
          shortcut="⇧esc"
          onClick={resetContactsCalendars}
          tooltipContent={`Hide ${visibleContactsCalendarsLength} ${
            visibleContactsCalendarsLength === 1 ? 'calendar' : 'calendars'
          }`}
        >
          <IconEyeClosed className={'h-4 w-4'} />
        </NavigationItem>
      )}
    </div>
  );
});
