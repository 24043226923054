import classNames from 'classnames';
import IconHome from 'components/Icons/IconHome';
import IconInbox from 'components/Icons/IconInbox';
import IconPlus from 'components/Icons/IconPlus';
import IconZap from 'components/Icons/IconZap';
import useSetNavItems from 'components/QuickMenu/hooks/useSetNavItems';
import useTrappedMode, {
  TrappedMode,
} from 'components/QuickMenu/hooks/useTrappedMode';
import { NavItemType } from 'components/QuickMenu/types';
import { useIsWorkEmail, usePrimaryCalendarId } from 'hooks/auth/authAtoms';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import useElectronIpc from 'hooks/useElectronIpc';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useHotkey from 'hooks/useHotkey';
import useTimezone from 'hooks/useTimeZone';
import { DateTime } from 'luxon';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';
import { isHomePage, isRootPage } from 'utils/router';
import CalendarButton from './CalendarButton';
import ContactList from './ContactList';
import NavigationItem from './NavigationItem';
import NavigationList from './NavigationList';
import UserItem from './UserItem';
import { CONTACTPANEL_WIDTH } from './utils';
import { SettingsPageType } from 'components/Settings/types';

interface Props {
  isSidepanelExpanded: boolean;
  toggleSidepanel: (value?: boolean) => void;
}

export default function LeftPanel({
  toggleSidepanel,
  isSidepanelExpanded,
}: Props): JSX.Element {
  const router = useRouter();
  const features = useFeatureFlag();
  const { isElectron } = useElectronIpc();
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const { setTrappedMode } = useTrappedMode();
  const { addNavItem } = useSetNavItems();
  const goToHome = useCallback(() => router.push('/home'), [router]);
  const goToCalendar = useCallback(() => router.push('/'), [router]);
  const primaryCalendarId = usePrimaryCalendarId();
  const isWorkEmail = useIsWorkEmail();

  const timezone = useTimezone();
  const { createDraftEvent } = useUpdateGridEvent();
  const { selectEvent } = useSetEventsSelection();

  const openCalendarSelect = useCallback(
    (event) => {
      event.preventDefault();
      setTrappedMode(TrappedMode.AddCalendar);
      openModal(ModalType.QuickMenu);
    },
    [setTrappedMode, openModal]
  );

  const openTimezoneSelect = useCallback(
    (event) => {
      event.preventDefault();
      addNavItem({ type: NavItemType.timezones });
      openModal(ModalType.QuickMenu);
    },
    [addNavItem, openModal]
  );
  const openSettings = useCallback(
    (event) => {
      event.preventDefault();
      openModal(ModalType.Settings);
    },
    [openModal]
  );

  const openShortcuts = useCallback(
    (event) => {
      event.preventDefault();
      openModal(ModalType.Settings);
      setSettingsPage(SettingsPageType.Shortcuts);
    },
    [openModal, setSettingsPage]
  );

  const createDraftEventNow = useCallback(
    (e) => {
      e.preventDefault();

      let newDraftTime = DateTime.now().setZone(timezone);

      if (newDraftTime.minute % 15 < 2) {
        // Set the minutes to the previous 15 minute mark.
        newDraftTime = newDraftTime.set({
          minute: Math.floor(newDraftTime.minute / 15) * 15,
        });
      } else {
        // Set the minutes to the next 15 minute mark.
        newDraftTime = newDraftTime.set({
          minute: Math.ceil(newDraftTime.minute / 15) * 15,
        });
      }

      const newEventId = generateEventUUID(primaryCalendarId);
      createDraftEvent({
        id: newEventId,
        startAt: newDraftTime,
        endAt: newDraftTime.plus({
          minutes: 30,
        }),
        attendees: [],
      });

      selectEvent(newEventId, true);
      openModal(ModalType.Event);
    },
    [createDraftEvent, openModal, primaryCalendarId, selectEvent, timezone]
  );

  useHotkey('a', { scope: 'global', enabled: isWorkEmail }, openCalendarSelect);
  useHotkey('f', 'global', goToHome);
  useHotkey('c', 'global', createDraftEventNow);
  useHotkey('z', 'global', openTimezoneSelect);
  useHotkey('command+,', 'global', openSettings);
  useHotkey('command+/, ctrl+/', 'global', openShortcuts);
  useHotkey('command+/, ctrl+/', 'settings', openShortcuts);

  return (
    <div
      style={{ minWidth: CONTACTPANEL_WIDTH }}
      className={classNames(
        'hidden-scrollbar border-separator bg-background flex max-h-full flex-col items-center overflow-y-scroll border-r transition-borderWidth duration-100',
        {
          'pt-10': isElectron,
          'pt-3': !isElectron,
          'border-r': isSidepanelExpanded,
          'border-0': !isSidepanelExpanded,
        }
      )}
    >
      <NavigationList>
        <NavigationItem
          tooltipContent="Calendar"
          transparent
          onClick={goToCalendar}
          active={isRootPage(router)}
        >
          <CalendarButton active={isRootPage(router)} />
        </NavigationItem>

        <NavigationItem
          tooltipContent="Todos"
          shortcut="L"
          onClick={() => toggleSidepanel(!isSidepanelExpanded)}
          active={isSidepanelExpanded}
        >
          <IconInbox />
        </NavigationItem>

        {features.feed && (
          <NavigationItem
            tooltipContent="Feed"
            shortcut="F"
            onClick={goToHome}
            active={isHomePage(router)}
          >
            <IconHome className="h-5 w-5" />
          </NavigationItem>
        )}
      </NavigationList>

      <div className="mx-auto my-3 w-6">
        <hr className="border-separator" />
      </div>

      <ContactList />

      {isWorkEmail && (
        <NavigationList>
          <NavigationItem
            shortcut="A"
            tooltipContent="Add calendar"
            onClick={openCalendarSelect}
          >
            <IconPlus className="h-3.5 w-3.5" />
          </NavigationItem>
        </NavigationList>
      )}

      <div className="mt-auto flex pt-3">
        <NavigationList>
          <NavigationItem
            shortcut="⌘J"
            tooltipContent="Quick menu"
            onClick={() => openModal(ModalType.QuickMenu)}
          >
            <IconZap />
          </NavigationItem>

          <UserItem />
        </NavigationList>
      </div>

      {/* Spacer */}
      <div className="flex h-3 w-full shrink-0" />
    </div>
  );
}
