import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function NavigationList({ children }: Props): JSX.Element {
  return (
    <div className="flex flex-col items-center space-y-2.5">{children}</div>
  );
}
