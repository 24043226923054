import {
  getEmojiOverride,
  getEndAtOverride,
  getMessageOverride,
  getStatusOverride,
} from 'components/Status/utils';
import { useUserStatusSubscription } from 'graphql/subscriptions/userStatus.graphql';
import { atom, useAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { UserStatus } from 'types/status';

interface Props {
  userEmail: string | undefined | null;
}

export const statusAtomFamily = atomFamily((userEmail: string) =>
  atom<UserStatus>({
    userId: '',
    userEmail,
    status: getStatusOverride(undefined),
  })
);

export default function useUserStatus({ userEmail }: Props): UserStatus {
  const [endAt, setEndAt] = useState<string | undefined>();
  const [userStatus, setUserStatus] = useAtom(
    statusAtomFamily(userEmail || '')
  );

  const [status] = useUserStatusSubscription({
    variables: { userEmail: userEmail || '' },
    pause: !userEmail,
  });

  const serverStatus = status.data?.userStatus_by_pk as UserStatus;

  useEffect(() => {
    if (!serverStatus) return;

    setUserStatus((status) => ({
      ...status,
      ...serverStatus,
      endAt: getEndAtOverride(serverStatus),
      userEmail: userEmail || '',
      message: getMessageOverride(serverStatus),
      emoji: getEmojiOverride(serverStatus),
      status: getStatusOverride({ ...status, ...serverStatus }),
    }));
  }, [userEmail, setUserStatus, serverStatus, endAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newEndAt = getEndAtOverride(serverStatus);
      setEndAt(newEndAt);
    }, 10_000);
    return () => clearInterval(interval);
  }, [serverStatus, setEndAt]);

  return userStatus;
}
