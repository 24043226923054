import classNames from 'classnames';
import React from 'react';

export function CardInput(
  props: { onChange: (val: string) => void; className?: string } & Omit<
    React.HTMLProps<HTMLInputElement>,
    'onChange'
  >
) {
  return (
    <input
      {...props}
      disabled={props.disabled}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
      className={classNames(
        'focus:shadow-outline h-9 w-full rounded-lg bg-gray-100 px-2.5 text-s outline-none hover:border-gray-200 focus:border-gray-200 focus:bg-gray-150 dark:bg-gray-600',
        props.className
      )}
    />
  );
}
