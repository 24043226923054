import React from 'react';
import ReactDOM from 'react-dom';
import dateStyles from 'components/EventPopover/DateTimePicker/EventDate.module.css';
import { PropsOf } from 'joy/utils';
import classNames from 'classnames';

interface Props extends PropsOf<'div'> {
  children: React.ReactNode;
}

export default React.memo(function PortalContainer({
  children,
  className,
  ...props
}: Props) {
  return ReactDOM.createPortal(
    <div className={classNames(dateStyles.datepicker, className)} {...props}>
      {children}
    </div>,
    document.body
  );
});
