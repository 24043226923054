import React from 'react';
import PageHeader from './PageHeader';
import PreviewCalendar from './previews/PreviewCalendar';
import CalendarDateFormat from './rows/CalendarDateFormat';
import CalendarStartDay from './rows/CalendarStartDay';
import CalendarTimeFormat from './rows/CalendarTimeFormat';
import CalendarWorkHours from './rows/CalendarWorkHours';
import EventDuration from './rows/EventDuration';
import EventPrivacy from './rows/EventPrivacy';
import RowSeparator from './rows/RowSeparator';

export default function SettingsPageCalendar(): JSX.Element {
  return (
    <>
      <PageHeader title="Calendar" />

      <PreviewCalendar />

      <div className="mt-10 flex flex-col">
        <CalendarStartDay />
        <RowSeparator />
        <CalendarTimeFormat />
        <RowSeparator />
        <CalendarDateFormat />
        <RowSeparator />
        <CalendarWorkHours />
        <RowSeparator />
        <EventDuration />
        <RowSeparator />
        <EventPrivacy />
      </div>
    </>
  );
}
