import { useDndContext } from '@dnd-kit/core';
import { isDraggingAtom } from 'components/Todos/todos-dnd';
import { useAtomValue } from 'jotai/utils';
import { useEffect } from 'react';

export function UseDraggingCursor(): null {
  useDraggingCursor();
  return null;
}

function useDraggingCursor(): void {
  const { active } = useDndContext();
  const isDragging = useAtomValue(isDraggingAtom);

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add('grabbing');
    } else {
      document.body.classList.remove('grabbing');
    }
  }, [isDragging]);

  useEffect(() => {
    if (active !== null) {
      document.body.classList.add('grabbing');
    } else {
      document.body.classList.remove('grabbing');
    }
  }, [active]);
}
