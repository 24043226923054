import React, { useEffect, useState, useMemo } from 'react';
import { APPLE_REGEX } from '../utils/constants';

const useUserAgent = () => {
  const [isMac, setIsMac] = useState<boolean | undefined>(undefined);
  const [didCheckUserAgent, setDidCheckUserAgent] = useState(false);

  useEffect(() => {
    const { userAgent } = window.navigator;

    if (userAgent) {
      if (userAgent.match(APPLE_REGEX)?.length) {
        setIsMac(true);
      } else {
        // Android/Linux/Windows 🤷‍♂️
        setIsMac(false);
      }
    }

    setDidCheckUserAgent(true);
  }, []);

  const icon = useMemo(() => {
    if (!didCheckUserAgent) return <></>;
    if (isMac) return <span>⌘</span>;
    return <span className="tracking-tight">Ctrl</span>;
  }, [didCheckUserAgent, isMac]);

  return { isMac, didCheckUserAgent, icon };
};

export default useUserAgent;
