import IconTime from 'components/Icons/IconTime';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import React, { useCallback, useMemo } from 'react';
import { formatDurationMinutes } from 'utils/time';
import Tooltip from 'joy/Tooltip';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';

interface Props {
  link: CalendarLink;
  onChange: (link: CalendarLink) => void;
}

export default function DurationDropdown({ link, onChange }: Props) {
  const setDraftDuration = useCallback(
    (durationMinutes: number) => {
      onChange({
        ...link,
        eventMinDurationMinutes: durationMinutes,
        eventMaxDurationMinutes: durationMinutes,
      });
    },
    [link, onChange]
  );

  const timeMenuItems: DropdownItem[] = useMemo(
    () => [
      {
        value: 'Slot duration',
        type: 'title',
      },
      {
        type: 'option',
        value: formatDurationMinutes(15, { long: true }),
        selected: link.eventMinDurationMinutes === 15,
        onSelect: () => setDraftDuration(15),
      },
      {
        type: 'option',
        value: formatDurationMinutes(30, { long: true }),
        selected: link.eventMinDurationMinutes === 30,
        onSelect: () => setDraftDuration(30),
      },
      {
        type: 'option',
        value: formatDurationMinutes(45, { long: true }),
        selected: link.eventMinDurationMinutes === 45,
        onSelect: () => setDraftDuration(45),
      },
      {
        type: 'option',
        value: formatDurationMinutes(60, { long: true }),
        selected: link.eventMinDurationMinutes === 60,
        onSelect: () => setDraftDuration(60),
      },
    ],
    [link.eventMinDurationMinutes, setDraftDuration]
  );

  return (
    <Dropdown items={timeMenuItems}>
      <Tooltip
        content="Change duration"
        placement="top-center"
        targetOffset={12}
      >
        <Button className="group flex h-9 w-full flex-grow items-center gap-1 rounded-lg border border-transparent bg-gray-100 px-3 text-s font-semibold outline-none !transition-colors hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:border-gray-600">
          <IconTime className="-ml-0.5 h-4 w-4 opacity-75" />
          {formatDurationMinutes(link?.eventMinDurationMinutes)}
        </Button>
      </Tooltip>
    </Dropdown>
  );
}
