/* 692d0c7ce879cff4d3c9092481649b528c93abc2
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetGMapsPlacesQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
}>;


export type GetGMapsPlacesQuery = { __typename?: 'query_root', getGMapsPlaces: { __typename?: 'GetGMapsPlacesOutput', places: Array<{ __typename?: 'GMapsPlace', description: string, id: string }> } };


export const GetGMapsPlacesDocument = gql`
    query GetGMapsPlaces($term: String!) {
  getGMapsPlaces(where: {term: $term}) {
    places {
      description
      id
    }
  }
}
    `;

export function useGetGMapsPlacesQuery(options: Omit<Urql.UseQueryArgs<GetGMapsPlacesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGMapsPlacesQuery>({ query: GetGMapsPlacesDocument, ...options });
};