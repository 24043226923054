import React from 'react';
import PageControlHeader from '../PageControlHeader';
import SwitchGroup from '../controls/SwitchGroup';

export default React.memo(function NotifcationsEmail(): JSX.Element {
  return (
    <SwitchGroup selected={false} disabled={true} onSelect={() => null}>
      <PageControlHeader
        title="Enable email notifications"
        description="Receive emails when events you have been invited to have changed."
        comingSoon={true}
        noPadding={true}
      />
    </SwitchGroup>
  );
});
