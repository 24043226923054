import React from 'react';
import SettingsPageAppearance from './SettingsPageAppearance';
import SettingsPageDownload from './SettingsPageDownload';
import SettingsPageCalendar from './SettingsPageCalendar';
import SettingsPageAccounts from './SettingsPageAccounts';
import SettingsPageConnections from './SettingsPageConnections';
import SettingsPageEvents from './SettingsPageEvents';
import SettingsPageNotifications from './SettingsPageNotifications';
import SettingsPageProfile from './SettingsPageProfile';
import SettingsPageShortcuts from './SettingsPageShortcuts';
import SettingsPageWhatsNew from './SettingsPageWhatsNew';
import SettingsPageFeedback from './SettingsPageFeedback';
import { SettingsPageType } from './types';
import SettingsPageMeetings from './SettingsPageMeetings';

interface Props {
  activePage: SettingsPageType;
}

export default function SettingsPages({
  activePage,
}: Props): JSX.Element | null {
  switch (activePage) {
    case SettingsPageType.Profile:
      return <SettingsPageProfile />;
    case SettingsPageType.Connections:
      return <SettingsPageConnections />;
    case SettingsPageType.Appearance:
      return <SettingsPageAppearance />;
    case SettingsPageType.Meetings:
      return <SettingsPageMeetings />;
    case SettingsPageType.Accounts:
      return <SettingsPageAccounts />;
    case SettingsPageType.Calendar:
      return <SettingsPageCalendar />;
    case SettingsPageType.Notifications:
      return <SettingsPageNotifications />;
    case SettingsPageType.Shortcuts:
      return <SettingsPageShortcuts />;
    case SettingsPageType.Download:
      return <SettingsPageDownload />;
    case SettingsPageType.WhatsNew:
      return <SettingsPageWhatsNew />;
    case SettingsPageType.Feedback:
      return <SettingsPageFeedback />;
    default:
      return null;
  }
}
