import TopPanel from 'components/Panels/TopPanel';
// import Room from 'components/room/Room';
import useInterface from 'hooks/useInterface';
import React, { useCallback } from 'react';
import BottomPanel from './BottomPanel';
import LeftPanel from './LeftPanel';
import SidePanel from './SidePanel';

interface Props {
  children: React.ReactNode;
}

export default function Panels({ children }: Props): JSX.Element {
  const {
    isSidepanelExpanded,
    setIsSidepanelExpanded,
    isSidepanelResizing,
    setIsSidepanelResizing,
  } = useInterface();

  const toggleSidepanel = useCallback(
    (newValue?: boolean) =>
      setIsSidepanelExpanded((val) =>
        newValue === undefined ? !val : newValue
      ),
    [setIsSidepanelExpanded]
  );

  return (
    <>
      <LeftPanel
        toggleSidepanel={toggleSidepanel}
        isSidepanelExpanded={isSidepanelExpanded}
      />
      <SidePanel
        isSidepanelResizing={isSidepanelResizing}
        setIsSidepanelResizing={setIsSidepanelResizing}
        isSidepanelExpanded={isSidepanelExpanded}
        toggleSidepanel={toggleSidepanel}
      />

      <div className="relative flex flex-shrink flex-grow flex-col">
        <TopPanel />
        {children}
        <BottomPanel />

        {/* <Room /> */}
      </div>
    </>
  );
}
