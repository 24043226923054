import { AvatarStatus } from 'joy/Avatar';
import type { UserStatus } from 'types/status';
import { DateTime } from 'luxon';
import { isFuture, isPast } from 'utils/time';
import { formatDistanceToNowStrict } from 'date-fns';
import { Maybe } from '@graphql-types@';

export function hasActiveStatus(status: UserStatus | undefined): boolean {
  if (!status) return false;
  if (status.integrationAmieEventId) return true;
  if (status.message !== undefined) return true;
  return false;
}

export function hasActiveDefaultStatus(
  status: Pick<UserStatus, 'emoji' | 'message'> | undefined
): boolean {
  return (
    (typeof status?.message === 'string' && status?.message?.trim() !== '') ||
    status?.emoji != undefined
  );
}

export function hasActiveAmieIntegration(
  status: UserStatus | undefined
): boolean {
  return status?.integrationAmieEventId != undefined;
}

export function getMessageOverride(
  status?: Maybe<UserStatus>
): string | undefined {
  if (!status?.endAt) return status?.message || undefined;
  return isPast(DateTime.fromISO(status.endAt))
    ? undefined
    : status?.message || undefined;
}

export function getEndAtOverride(
  status?: Maybe<UserStatus>
): string | undefined {
  if (!status?.endAt) return status?.endAt;
  return isPast(DateTime.fromISO(status.endAt)) ? undefined : status?.endAt;
}

export function getEmojiOverride(
  status: UserStatus | undefined
): string | undefined {
  if (!status?.endAt) return status?.emoji || undefined;
  return isPast(DateTime.fromISO(status.endAt))
    ? undefined
    : status?.emoji || undefined;
}

export function getStatusOverride(
  status: UserStatus | undefined
): UserStatus['status'] {
  if (!status) return 'away';
  if (isInAmieMeeting(getAmieMeetingStatus(status))) {
    return 'busy';
  }
  if (!status.endAt) {
    return status.status;
  }
  return isPast(DateTime.fromISO(status.endAt)) ? 'free' : status.status;
}

export function getStatusText(status?: UserStatus): string {
  if (!status) return 'Available';

  if (getAmieMeetingStatus(status) === 'inProgressWithAttendees') {
    return 'In a meeting';
  }

  switch (status.status) {
    case 'free':
      return 'Available';
    case 'away':
      return 'Away';
    case 'busy':
    default:
      return 'Busy';
  }
}

export function getAvatarStatus(status?: string | null): AvatarStatus {
  switch (status) {
    case 'free':
      return 'green';
    case 'away':
      return 'gray';
    case 'busy':
    default:
      return 'red';
  }
}

export function getStatusEndText(
  status: UserStatus | undefined
): string | undefined {
  if (!status) return undefined;
  if (!status.endAt) return undefined;
  return `Until ${DateTime.fromISO(status.endAt).toFormat('HH:mm')}`;
}

type AmieMeetingStatus =
  | 'ended'
  | 'inProgressWithAttendees'
  | 'inProgressWithoutAttendees'
  | 'planned';

export function isInAmieMeeting(
  meetingStatus: AmieMeetingStatus | undefined
): boolean {
  if (!meetingStatus) return false;
  if (meetingStatus === 'inProgressWithAttendees') return true;
  if (meetingStatus === 'inProgressWithoutAttendees') return true;
  return false;
}

export function getDurationProgress(startAt: string, endAt: string) {
  const startAtDate = DateTime.fromISO(startAt);
  const endAtDate = DateTime.fromISO(endAt);

  const diffToStart = DateTime.now().diff(startAtDate).toObject().minutes || 1;
  const eventDurationDiff = startAtDate.diff(endAtDate).toObject().minutes || 1;

  const progress = (diffToStart / eventDurationDiff) * 100;

  return progress;
}

export function getAmieStartText(startAt?: string | null): string {
  if (!startAt) return '';
  const startAtDate = DateTime.fromISO(startAt);
  return `In ${formatDistanceToNowStrict(startAtDate.toJSDate())}`;
}

export function getAmieEndText(endAt?: string | null): string {
  if (!endAt) return '';
  return `Until ${DateTime.fromISO(endAt).toFormat('HH:mm')}`;
}

export function getAmieMeetingStatus(
  status: UserStatus,
  hasAttendees = false
): AmieMeetingStatus | undefined {
  if (!status.integrationAmieEventId) return undefined;

  const startAt = status.integrationAmieStartAt;
  const endAt = status.integrationAmieEndAt;

  if (!startAt) {
    return 'ended';
  }

  if (endAt && isPast(DateTime.fromISO(endAt))) {
    return 'ended';
  }

  if (isFuture(DateTime.fromISO(startAt))) {
    return 'planned';
  }

  if (hasAttendees) {
    return 'inProgressWithAttendees';
  }

  return 'inProgressWithoutAttendees';
}

export function getAmieStatusTitle(meetingStatus: AmieMeetingStatus): string {
  switch (meetingStatus) {
    case 'ended':
      return 'Meeting ended';
    case 'inProgressWithAttendees':
      return 'In a meeting';
    case 'inProgressWithoutAttendees':
      return 'Right now';
    case 'planned':
      return 'Upcoming';
  }
}
