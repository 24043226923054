import { useId } from '@react-aria/utils';
import * as React from 'react';

export function Loader({
  style,
  width,
  height,
  children,
  className,
  ...props
}: React.SVGAttributes<SVGElement>) {
  const idClip = useId();
  const idGradient = useId();
  const keyTimes = '0; 0.25; 1';
  const duration = '2s';

  return (
    <svg
      role="img"
      style={{ ...style }}
      className={className}
      preserveAspectRatio="none"
      height={height}
      width={width}
      {...props}
    >
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        clipPath={`url(#${idClip})`}
        style={{ fill: `url(#${idGradient})` }}
      />

      <defs>
        <clipPath id={idClip}>{children}</clipPath>

        <linearGradient id={idGradient}>
          <stop className="bg-loader" offset="0%" stopOpacity={1}>
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>

          <stop className="bg-loader-alt" offset="50%" stopOpacity={1}>
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>

          <stop className="bg-loader" offset="100%" stopOpacity={1}>
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
