import React from 'react';
import NavigationItem from './NavigationItem';
import Avatar from 'joy/Avatar';
import { useFullUser } from 'hooks/auth/authAtoms';
import { getAvatarStatus } from 'components/Status/utils';
import UserPopover from 'components/UserPopover';
import Button from 'joy/Button';
import IconSmile from 'components/Icons/IconSmile';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import IconCalendar from 'components/Icons/IconCalendar';
import { SettingsPageType } from 'components/Settings/types';
import IconCog from 'components/Icons/IconCog';
import classNames from 'classnames';
import { UserStatus } from 'types/status';

export default React.memo(function UserItem() {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const user = useFullUser();

  if (!user) return null;

  return (
    <UserPopover
      placement="right-start"
      arbitraryContent={<SelfPopoverContent />}
      status={user.status as UserStatus}
      user={{
        id: user.id,
        emailAddress: user.email,
        displayName: user.display_name,
        avatar: user.photo_url,
      }}
    >
      <NavigationItem
        onClick={() => {
          setSettingsPage(SettingsPageType.Profile);
          openModal(ModalType.Settings);
        }}
      >
        <Avatar
          alternateStatus={true}
          status={getAvatarStatus(user.status?.status)}
          name={user.display_name || ''}
          src={user.photo_url}
          size={20}
          statusClassName="border-2 border-background group-hover:border-gray-150 dark:group-hover:border-gray-750"
        />
      </NavigationItem>
    </UserPopover>
  );
});

const sharedUserItemButtonStyles =
  'flex w-full items-center rounded-lg px-3.5 py-2 gap-2 text-s font-medium hover:bg-gray-100 dark:hover:bg-gray-800';

function SelfPopoverContent(): JSX.Element {
  const { openModal } = useUpdateModal();
  const user = useFullUser();
  const setSettingsPage = useUpdateSettingPageModal();
  const { emoji, message } = user?.status || {};
  const statusClass = `w-full text-left ${emoji ? 'pr-4' : ''}`;

  return (
    <div className="-ml-4 -mb-4 -mt-2" style={{ width: 'calc(100% + 32px)' }}>
      <Button
        onClick={() => openModal(ModalType.SetStatus)}
        className={classNames(
          sharedUserItemButtonStyles,
          emoji ? 'items-start' : 'items-center',
          'break-words'
        )}
      >
        {!emoji && !message ? (
          <>
            <IconSmile className="h-4 w-4 text-gray-500 dark:text-gray-400" />
            <span className={statusClass}>Set a custom status</span>
          </>
        ) : (
          <>
            {emoji && <span className="mr-2">{emoji}</span>}
            {message && <span className={statusClass}>{message}</span>}
          </>
        )}
      </Button>

      <Button
        className={sharedUserItemButtonStyles}
        onClick={() => {
          openModal(ModalType.Settings);
          setSettingsPage(SettingsPageType.Accounts);
        }}
      >
        <IconCalendar className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        Calendars
      </Button>

      <Button
        className={sharedUserItemButtonStyles}
        onClick={() => {
          openModal(ModalType.Settings);
          setSettingsPage(SettingsPageType.Profile);
        }}
      >
        <IconCog className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        Settings
      </Button>
    </div>
  );
}
