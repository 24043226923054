import { useCallback, useEffect, useState } from 'react';

function hasFocus() {
  if (typeof document === 'undefined') return false;
  return document.hasFocus();
}

export default function useWindowFocus(onChangeCallback?: () => void) {
  const [focused, setFocused] = useState(hasFocus);
  const [didRefocus, setDidRefocus] = useState(false);

  const onFocus = useCallback(() => {
    setFocused(true);
    onChangeCallback?.();
  }, [onChangeCallback]);

  const onBlur = useCallback(() => {
    setFocused(false);
    onChangeCallback?.();
  }, [onChangeCallback]);

  useEffect(() => {
    const handleChange = () => {
      if (
        typeof document !== 'object' ||
        document.visibilityState === 'visible'
      ) {
        setDidRefocus(true);
        setTimeout(() => setDidRefocus(false), 500);
      }
    };

    window.addEventListener('visibilitychange', handleChange);

    return () => {
      window.removeEventListener('visibilitychange', handleChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onBlur, onFocus]);

  return {
    focused,
    didRefocus,
  };
}
