import { Loader } from 'joy/Loader';
import React from 'react';

export default React.memo(function AmieIntegrationStatusLoader() {
  return (
    <Loader width="100%" height={20}>
      <rect y={5} rx={5} ry={5} width="60%" height={10} />
    </Loader>
  );
});
