import { VideoProvider_Enum } from '@graphql-types@';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import { useAtomCallback } from 'jotai/utils';
import { useUnmountEffect } from 'joy/utils';
import React, { useCallback } from 'react';
import { PreferenceName } from 'types/preference';
import ExternalLink from '../controls/ExternalLink';
import Input from '../controls/Input';
import PageControlHeader from '../PageControlHeader';
import { useZoom } from '../useSettings';

export default React.memo(
  function EventZoomLinkComponent(): JSX.Element | null {
    const { zoomLink, onSubmit } = useZoom();
    const preferences = usePreferences();
    const updatePreference = useUpdatePreference();

    const cleanup = useAtomCallback(
      useCallback(
        (get) => {
          if (
            !get(preferencesAtom).zoomLink &&
            get(preferencesAtom).videoProvider === VideoProvider_Enum.Zoom
          ) {
            updatePreference(
              PreferenceName.VideoProvider,
              VideoProvider_Enum.None
            );
          }
        },
        [updatePreference]
      )
    );

    useUnmountEffect(() => {
      cleanup();
    });

    if (preferences.videoProvider !== VideoProvider_Enum.Zoom) return null;

    return (
      <div className="flex flex-col">
        <PageControlHeader
          title="Zoom link"
          description={
            <>
              Your meeting link for{' '}
              <ExternalLink href="https://zoom.us/">Zoom</ExternalLink>.
              It&#39;ll be available in the event location.
            </>
          }
        />

        <Input
          placeholder="e.g https://zoom.us/r/amie"
          value={zoomLink || ''}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);
