import { CalendarStartsOn_Enum } from '@graphql-types@';
import { useUpdateEffect } from '@react-aria/utils';
import { useHotkeyContext } from 'contexts/hotkey';
import useHotkey from 'hooks/useHotkey';
import { usePreferences } from 'hooks/usePreferences';
import usePrevious from 'hooks/usePrevious';
import React, { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { enUS } from 'date-fns/locale';
import { CustomHeader } from 'components/EventPopover/DateTimePicker/EventDateTimePicker';
import PortalContainer from './PortalContainer';
import Button from 'joy/Button';

const DATE_BEGINNING_FORMAT = 'dd MMM yyyy';
const ReactDatePicker = dynamic(() => import('react-datepicker'));

interface Props {
  children: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  shortcut?: string;
  selected?: Date;
  onChange: (date: Date) => void;
}

export default React.memo(function DatePicker({
  children,
  selected,
  shortcut,
  onChange,
}: Props) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const wasCalendarOpen = usePrevious(isCalendarOpen);
  const { calendarStartsOn } = usePreferences();
  const { setScope, setPreviousScope } = useHotkeyContext();

  const weekStartsOn = useMemo(() => {
    switch (calendarStartsOn) {
      case CalendarStartsOn_Enum.Monday:
        return 1;
      case CalendarStartsOn_Enum.Sunday:
        return 0;
      default:
        return 0;
    }
  }, [calendarStartsOn]);

  useHotkey(
    shortcut || '',
    { scope: 'global', enabled: shortcut != undefined },
    () => {
      if (isCalendarOpen) return;
      setIsCalendarOpen(true);
    }
  );

  useHotkey(
    shortcut ? `escape, ${shortcut}` : '',
    { scope: 'calendar', enabled: shortcut != undefined },
    () => {
      if (!isCalendarOpen) return;
      setIsCalendarOpen(false);
    }
  );

  useUpdateEffect(() => {
    if (isCalendarOpen) {
      setScope('calendar');
    } else if (wasCalendarOpen) {
      setPreviousScope();
    }
  }, [isCalendarOpen, setPreviousScope, setScope, wasCalendarOpen]);

  return (
    <ReactDatePicker
      dateFormat={DATE_BEGINNING_FORMAT}
      open={isCalendarOpen}
      popperContainer={PortalContainer}
      popperPlacement="bottom-start"
      onCalendarClose={() => setIsCalendarOpen(false)}
      onClickOutside={() => setIsCalendarOpen(false)}
      selected={selected}
      onChange={onChange}
      locale={{
        ...enUS,
        options: {
          ...enUS.options,
          weekStartsOn,
        },
      }}
      renderCustomHeader={CustomHeader}
      dayClassName={() => 'text-primary text-s font-medium'}
      customInput={
        <Button
          className="flex"
          onClickCapture={(event: React.MouseEvent) => {
            event.stopPropagation();
            setIsCalendarOpen((val) => !val);
          }}
        >
          {typeof children === 'function' ? children(isCalendarOpen) : children}
        </Button>
      }
    />
  );
});
