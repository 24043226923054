import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import RadioList from '../controls/RadioList';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function AppearanceTheme() {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceValue = preferences?.theme;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Theme"
        description="Choose a preferred theme for the app."
      />

      <RadioList
        items={[
          {
            title: 'Light',
            selected: preferenceValue === 'LIGHT',
            onSelect: () => updatePreference(PreferenceName.Theme, 'LIGHT'),
          },
          {
            title: 'Dark',
            selected: preferenceValue === 'DARK',
            onSelect: () => updatePreference(PreferenceName.Theme, 'DARK'),
          },
          {
            title: 'Automatic',
            description: 'Sync theme with your system preference',
            selected: !preferenceValue,
            onSelect: () => updatePreference(PreferenceName.Theme, false),
          },
        ]}
      />
    </div>
  );
});
