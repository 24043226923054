import React from 'react';

interface Props {
  children: React.ReactText;
}

export default function NavItemTitle({ children }: Props): JSX.Element {
  return (
    <p className="uppercase text-[11px] tracking-wider px-2 mb-1.5 mt-2 font-black text-gray-500 dark:text-gray-400">
      {children}
    </p>
  );
}
