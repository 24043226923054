import { New_Contact } from '@graphql-types@';
import { mergeProps, useUpdateEffect } from '@react-aria/utils';
import { AnimatePresence } from 'framer-motion';
import { isReactText } from 'joy/utils';
import React, { useMemo, useState } from 'react';
import { useHover, useLayer } from 'react-laag';
import type { Placement } from 'react-laag';
import UserPopoverContent from './UserPopoverContent';
import PrefetchSingleEvent from './PrefetchSingleEvent';
import { SyncProfile } from 'hooks/useProfile';
import { useRouter } from 'next/router';
import { useModalValue } from 'hooks/useModal';
import { UserStatus } from 'types/status';

type ContactSubset = Pick<
  New_Contact,
  'id' | 'emailAddress' | 'displayName' | 'avatar' | 'favoriteAt'
>;
export type PopoverUserT = Partial<ContactSubset>;

interface Props {
  user: PopoverUserT;
  status?: UserStatus;
  children: React.ReactChild;
  placement?: Placement;
  disabled?: boolean;
  arbitraryContent?: React.ReactElement;
}

export default React.memo(function UserPopover({
  user,
  status,
  children,
  placement = 'bottom-start',
  disabled = false,
  arbitraryContent,
}: Props): JSX.Element {
  const currentOpenModal = useModalValue();
  const { asPath } = useRouter();
  const [isOver, hoverProps, close] = useHover({
    delayEnter: 100,
    delayLeave: 100,
  });
  const [isEditing, setEditing] = useState(false);
  const isOpen = (isOver && disabled === false) || isEditing;
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    placement,
    possiblePlacements: [
      'left-start',
      'right-start',
      'bottom-start',
      placement,
    ],
    auto: true,
    triggerOffset: 8,
    ResizeObserver,
  });

  const popoverProps = useMemo(
    () => ({
      onMouseDown: close,
    }),
    [close]
  );

  let trigger;

  if (isReactText(children)) {
    trigger = (
      <span
        {...mergeProps(triggerProps, hoverProps, popoverProps)}
        ref={triggerProps.ref}
      >
        {children}
      </span>
    );
  } else {
    trigger = React.cloneElement(children, {
      ...mergeProps(children.props, triggerProps, hoverProps, popoverProps),
      ref: triggerProps.ref,
    });
  }

  useUpdateEffect(() => {
    close();
  }, [asPath, currentOpenModal]);

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            // renderLayer should only run under the same condition as isOpen provided to useLayer
            // https://github.com/everweij/react-laag/issues/52#issuecomment-746146753
            // https://github.com/everweij/react-laag#quick-start
            <UserPopoverContent
              user={user}
              status={status}
              arbitraryContent={arbitraryContent}
              setEditing={setEditing}
              {...layerProps}
              {...hoverProps}
            />
          )}
        </AnimatePresence>
      )}

      {/* "Leaf" components which don't render any contents */}
      {status?.integrationAmieEventId && user.emailAddress && isOpen && (
        <>
          <SyncProfile
            key={`profile-${user.emailAddress}`}
            email={user.emailAddress}
          />
          <PrefetchSingleEvent
            eventId={status.integrationAmieEventId}
            calendarId={user.emailAddress}
          />
        </>
      )}
    </>
  );
});
