import {
  CalendarStartsOn_Enum,
  ColorFamily,
  NewEventRsvpEnum,
  NewEventVisibilityEnum,
} from '@graphql-types@';
import GridDay from 'components/Grid/GridDay';
import GridEvent from 'components/Grid/GridEvent';
import { GridLabelsWithOffset } from 'components/Grid/GridLabels';
import GridLines from 'components/Grid/GridLines';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCalendarDays } from 'hooks/useCalendar';
import { usePreferences } from 'hooks/usePreferences';
import React from 'react';
import { calculateCalendarTargetDate } from 'utils/time';
import PreviewTag from './PreviewTag';
import { DateTime } from 'luxon';

interface Props {
  showDefaultEvents?: boolean;
  showEvents?: boolean;
  showEventsDefaultColor?: boolean;
}

export default function PreviewCalendar({
  showDefaultEvents = false,
  showEvents = false,
  showEventsDefaultColor = false,
}: Props): JSX.Element {
  const days = useCalendarDays();
  const preferences = usePreferences();

  const {
    theme,
    ui24HourClock,
    calendarStartsOn,
    todoPrivacy,
    defaultDuration,
    defaultEventColor,
  } = preferences;

  const dates = getDates(calendarStartsOn);

  return (
    <div className="border-separator pointer-events-none relative flex h-60 w-full flex-col overflow-hidden rounded-lg border bg-gray-50 dark:bg-gray-800">
      <PreviewTag />

      <div
        className="grid grid-cols-calendar1Timezones py-3.5"
        style={{ width: '160%' }}
      >
        {dates.map((date, index) => (
          <GridDay
            key={index}
            date={date}
            index={index + 1}
            showActiveElement={false}
          />
        ))}
      </div>

      <div className="ml-4">
        <hr className="h-0.5 w-full border-0 bg-gray-100 dark:bg-gray-700" />
      </div>

      <div className="flex overflow-hidden" style={{ width: '160%' }}>
        <div
          className="grid w-full grid-cols-calendar1Timezones grid-rows-calendar transition-transform duration-700"
          style={{
            transform: `translateY(${theme === 'DARK' ? '-820px' : '-280px'})`,
          }}
        >
          <GridLines days={days} />
          <GridLabelsWithOffset offsets={[0]} />
          {showDefaultEvents && (
            <>
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  status: 'confirmed',
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'New Event',
                  dayIndex: 2,
                  location: '',
                  videoConferences: [],
                  description: '',
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 8 }),
                  endAt: dates[0].set({
                    hour: 8,
                    minute: defaultDuration || 15,
                  }),
                  colorFamily: defaultEventColor,
                  allOtherGuestsDeclined: false,
                  visibility: todoPrivacy
                    ? NewEventVisibilityEnum.Private
                    : NewEventVisibilityEnum.Default,
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                }}
              />

              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  status: 'confirmed',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'New Event',
                  dayIndex: 2,
                  location: '',
                  videoConferences: [],
                  description: '',
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 20 }),
                  endAt: dates[0].set({
                    hour: 20,
                    minute: defaultDuration || 15,
                  }),
                  colorFamily: defaultEventColor,
                  allOtherGuestsDeclined: false,
                  visibility: todoPrivacy
                    ? NewEventVisibilityEnum.Private
                    : NewEventVisibilityEnum.Default,
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                }}
              />
            </>
          )}

          {showEvents && (
            <>
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  status: 'confirmed',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Breakfast',
                  dayIndex: 1,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 7 }),
                  endAt: dates[0].set({ hour: 8, minute: 15 }),
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Yellow,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  status: 'confirmed',
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Planning',
                  dayIndex: 2,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 7, minute: 30 }),
                  endAt: dates[0].set({ hour: 9, minute: 30 }),
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Purple,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Go to airport',
                  dayIndex: 4,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 7, minute: 30 }),
                  endAt: dates[0].set({ hour: 8, minute: 30 }),
                  status: 'confirmed',
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Pink,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />

              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Read a book',
                  dayIndex: 1,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 21, minute: 30 }),
                  endAt: dates[0].set({ hour: 22, minute: 30 }),
                  status: 'confirmed',
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Blue,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Dinner',
                  dayIndex: 2,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 20 }),
                  endAt: dates[0].set({ hour: 21, minute: 30 }),
                  status: 'confirmed',
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Yellow,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />
              <GridEvent
                ui24HourClock={ui24HourClock}
                event={{
                  id: '',
                  calendarId: '',
                  attendees: [],
                  isSelfAsAttendee: false,
                  isOwnEvent: true,
                  isDraft: false,
                  title: 'Evening walk',
                  dayIndex: 4,
                  updatedAt: DateTime.now(),
                  createdAt: dates[0].set({ hour: 1 }).toJSDate(),
                  startAt: dates[0].set({ hour: 20, minute: 30 }),
                  endAt: dates[0].set({ hour: 21, minute: 30 }),
                  colorFamily: showEventsDefaultColor
                    ? defaultEventColor
                    : ColorFamily.Green,
                  allOtherGuestsDeclined: false,
                  location: '',
                  videoConferences: [],
                  description: '',
                  canEdit: true,
                  belongsToUserCalendar: true,
                  prevEndAt: DateTime.now(),
                  prevStartAt: DateTime.now(),
                  isAllDay: false,
                  rsvp: NewEventRsvpEnum.Yes,
                  status: 'confirmed',
                  visibility: NewEventVisibilityEnum.Default,
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function getDates(startDay: Maybe<CalendarStartsOn_Enum>) {
  const startDate = calculateCalendarTargetDate(
    DateTime.now(),
    startDay || CalendarStartsOn_Enum.Sunday
  );

  return Array(7)
    .fill(null)
    .map((_, index) => {
      return startDate.plus({ days: index });
    });
}
