import { useSingleEventQuery } from 'graphql/queries/singleEvent.graphql';

interface PrefetchSingleEventProps {
  eventId: string;
  calendarId: string;
}

export default function PreFetchSingleEvent(props: PrefetchSingleEventProps) {
  useSingleEventQuery({
    variables: {
      eventId: props.eventId,
      calendarId: props.calendarId,
    },
    requestPolicy: 'cache-first',
  });
  return null;
}
