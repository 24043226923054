import React from 'react';
import PageHeader from './PageHeader';
import { DownloadMobileCard } from './rows/DownloadMobileCard';
import { DownloadDesktopCard } from './rows/DownloadDesktopCard';

export default function SettingsPageDownload(): JSX.Element {
  return (
    <>
      <PageHeader title="Download apps" />
      <div className="space-y-6">
        <DownloadDesktopCard />
        <DownloadMobileCard />
      </div>
    </>
  );
}
