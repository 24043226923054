import { useUpdateContactByPkMutation } from 'graphql/mutations/UpdateContact.graphql';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export function useUnfollowUser({
  userId,
  userEmail,
}: {
  userId?: string;
  userEmail?: string;
}): () => Promise<void> {
  const { removeCalendar } = useSetVisibleCalendars();
  const [, updateContact] = useUpdateContactByPkMutation();

  const unfollowContact = useCallback(async () => {
    // hide their calendar events if displayed
    if (userEmail) {
      removeCalendar(userEmail);
    }
    // remove from favourites
    if (userId) {
      await updateContact({
        id: userId,
        _set: { favoriteAt: null },
      });
    }
  }, [removeCalendar, updateContact, userEmail, userId]);

  return unfollowContact;
}

export function useViewUserProfile({
  userEmail,
}: {
  userEmail?: string;
}): () => Promise<boolean> | undefined {
  const router = useRouter();

  const viewProfile = useCallback(() => {
    if (!userEmail) return;
    return router.push('/' + userEmail, undefined, { shallow: true });
  }, [router, userEmail]);

  return viewProfile;
}
