import { useState, useEffect } from 'react';

function getVisibility(): VisibilityState {
  if (typeof document === 'undefined') return 'visible'; // SSR
  return document.visibilityState;
}

export function useDocumentVisibility(): VisibilityState {
  const [documentVisibility, setDocumentVisibility] = useState<VisibilityState>(
    getVisibility()
  );

  function handleVisibilityChange() {
    setDocumentVisibility(getVisibility());
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return documentVisibility;
}
