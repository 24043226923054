import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-laag';
import Button from './Button';
import { PropsOf } from './utils';

const defaultElement = 'div';

export interface RangeSliderProps
  extends Omit<
    PropsOf<typeof defaultElement>,
    'value' | 'onChange' | 'onSubmit'
  > {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  displayValue?: string;
  onChange?: (value: number) => void;
  onSubmit?: (value: number) => void;
}

function RangeSliderComponent(
  {
    className,
    min = 0,
    max = 100,
    step = 1,
    value,
    displayValue,
    onChange,
    onSubmit,
    ...props
  }: RangeSliderProps,
  ref: React.Ref<HTMLInputElement>
) {
  const calculateConstrainedValue = useCallback(
    (newValue: number) =>
      Math.max(Math.min(Math.round(newValue / step) * step, max), min),
    [max, min, step]
  );

  const defaultRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentValue, setCurrentValue] = useState(
    calculateConstrainedValue(value)
  );
  const dimensions = useRef<DOMRect | undefined>(undefined);
  const widthPercent = (Math.max(min, currentValue) / max) * 100;

  const onDragStart = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragging(true);
    document.body.classList.add('cursor-ew-resize');
    dimensions.current = defaultRef.current?.getBoundingClientRect();
  }, []);

  const onDrag = useCallback(
    (event: MouseEvent) => {
      if (!dimensions.current) return 0;

      const sliderLeftMin = dimensions.current.left;
      const sliderLeftMax = dimensions.current.left + dimensions.current.width;
      const sliderClientX = event.clientX - sliderLeftMin;

      const decimalRatio = sliderClientX / (sliderLeftMax - sliderLeftMin);
      const ratio = Math.max(Math.min(decimalRatio, 1), 0);
      const value = max * ratio;
      const roundedValue = calculateConstrainedValue(value);
      setCurrentValue(value);
      onChange?.(roundedValue);

      return roundedValue;
    },
    [calculateConstrainedValue, max, onChange]
  );

  const onDragEnd = useCallback(() => {
    const finalValue = calculateConstrainedValue(currentValue);
    setCurrentValue(finalValue);
    onChange?.(finalValue);
    onSubmit?.(finalValue);
    setIsDragging(false);
    document.body.classList.remove('cursor-ew-resize');
  }, [calculateConstrainedValue, currentValue, onChange, onSubmit]);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      dimensions.current = defaultRef.current?.getBoundingClientRect();
      const roundedValue = onDrag(event.nativeEvent);
      onSubmit?.(roundedValue);
    },
    [onDrag, onSubmit]
  );

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', onDragEnd);
    }

    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', onDragEnd);
    };
  }, [isDragging, onDrag, onDragEnd]);

  useEffect(() => {
    if (isDragging) return;
    setCurrentValue(calculateConstrainedValue(value));
  }, [value, max, min, isDragging, step, calculateConstrainedValue]);

  return (
    <div
      role="presentation"
      ref={mergeRefs(defaultRef, ref)}
      onMouseDown={onMouseDown}
      className={classNames(
        'relative flex h-10 w-full items-center rounded-lg bg-gray-100 dark:bg-gray-750',
        className
      )}
      {...props}
    >
      <div
        className={classNames(
          'absolute left-0 bottom-0 z-10 h-10 overflow-hidden rounded-lg bg-gray-200 dark:bg-gray-600',
          {
            'transition-width duration-100': !isDragging,
          }
        )}
        style={{ width: `${widthPercent}%` }}
      >
        <Button
          className="absolute right-0 top-0 h-full w-4 cursor-ew-resize"
          onMouseDown={onDragStart}
        >
          {/* drag handle */}
          <div className="absolute right-2 top-1/2 z-10 -mt-2 h-4 w-0.5 rounded-lg bg-gray-400 dark:bg-gray-500" />
        </Button>
      </div>

      {displayValue && (
        <div className="absolute left-0 flex h-full items-center">
          <p className="relative z-20 ml-3 select-none text-sm font-medium">
            {displayValue}
          </p>
        </div>
      )}
    </div>
  );
}

const Input = React.memo(React.forwardRef(RangeSliderComponent));

export default Input;
