import { motion } from 'framer-motion';
import useHotkey from 'hooks/useHotkey';
import {
  useSettingPageModal,
  useUpdateModal,
  useUpdateSettingPageModal,
} from 'hooks/useModal';
import React, { useCallback, useEffect, useRef } from 'react';
import { ModalType } from 'types/modal';
import { TEST_ID_PREFERENCE_PANEL } from 'utils/constants';
import Nav from './Nav';
import PageLayout from './PageLayout';
import PageNavigation from './PageNavigation';
import RowSeparator from './rows/RowSeparator';
import SettingsCloseButton from './SettingsCloseButton';
import SettingsPages from './SettingsPages';
import { SettingsPageType } from './types';

export default function SettingsContent(): JSX.Element {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { closeModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const settingsPage = useSettingPageModal();

  useEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [settingsPage]);

  const closeSettingsPage = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      closeModal(ModalType.Settings);
    },
    [closeModal]
  );

  const navigateSettings = useCallback(
    (page: SettingsPageType) => {
      setSettingsPage(page);
    },
    [setSettingsPage]
  );

  useHotkey('escape, command+,, ctrl+,', 'settings', closeSettingsPage);

  useHotkey(
    'command+/, ctrl+/, command+shift+7, ctrl+shift+7',
    { scope: 'settings', enabled: settingsPage === SettingsPageType.Shortcuts },
    closeSettingsPage
  );

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
      transition={{ duration: 0.12 }}
      className="fixed top-0 left-0 z-100 flex h-full w-full bg-white transition-colors dark:bg-gray-800"
      data-testid={TEST_ID_PREFERENCE_PANEL}
    >
      <div className="fixed top-8 right-8">
        <SettingsCloseButton />
      </div>
      <div
        className="hidden-scrollbar h-full flex-1 overflow-y-scroll bg-gray-100 px-6 transition-colors dark:bg-gray-900/30"
        style={{ flexBasis: '320px' }}
      >
        <Nav active={settingsPage} onNavigate={navigateSettings} />
      </div>
      <div
        ref={contentRef}
        className="hidden-scrollbar h-full flex-1 overflow-y-scroll bg-white transition-colors dark:bg-gray-800"
        style={{ flexBasis: '800px' }}
      >
        <PageLayout>
          <SettingsPages activePage={settingsPage} />
          <div className="mt-auto flex flex-col">
            <RowSeparator />
            <PageNavigation activePage={settingsPage} />
          </div>
        </PageLayout>
      </div>
    </motion.div>
  );
}
