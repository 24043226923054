import React from 'react';
import PageHeader from './PageHeader';
import UserCalendars from './UserCalendars';

export default function SettingsPageCalendars(): JSX.Element {
  return (
    <>
      <PageHeader title="Accounts" />

      <UserCalendars />
    </>
  );
}
