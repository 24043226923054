/* aa6fe14eada61e28adabd90b4db42420e812554e
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckAliasAvailabilityQueryVariables = Types.Exact<{
  alias: Types.Scalars['String'];
}>;


export type CheckAliasAvailabilityQuery = { __typename?: 'query_root', checkAliasAvailability: { __typename?: 'CheckAliasAvailabilityOutput', alias: string, isAvailable: boolean } };


export const CheckAliasAvailabilityDocument = gql`
    query CheckAliasAvailability($alias: String!) {
  checkAliasAvailability(alias: $alias) {
    alias
    isAvailable
  }
}
    `;

export function useCheckAliasAvailabilityQuery(options: Omit<Urql.UseQueryArgs<CheckAliasAvailabilityQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckAliasAvailabilityQuery>({ query: CheckAliasAvailabilityDocument, ...options });
};