import React from 'react';

export default function IconInbox(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.57143 13L4.56138 13.1414L4.56138 13.1414L3.57143 13ZM7.08151 4H12.9185V2H7.08151V4ZM14.3044 5.20201L15.6187 14.402L17.5986 14.1192L16.2843 4.91917L14.3044 5.20201ZM5.76722 18H14.2328V16H5.76722V18ZM3.71568 4.91917L2.58148 12.8586L4.56138 13.1414L5.69558 5.20201L3.71568 4.91917ZM2.58148 12.8586L2.40139 14.1192L4.38129 14.402L4.56138 13.1414L2.58148 12.8586ZM15.6187 14.402C15.7392 15.2454 15.0847 16 14.2328 16V18C16.3018 18 17.8912 16.1674 17.5986 14.1192L15.6187 14.402ZM5.76722 16C4.91525 16 4.26081 15.2454 4.38129 14.402L2.40139 14.1192C2.10878 16.1674 3.69816 18 5.76722 18V16ZM12.9185 4C13.6152 4 14.2059 4.5123 14.3044 5.20201L16.2843 4.91917C16.045 3.24416 14.6105 2 12.9185 2V4ZM7.08151 2C5.3895 2 3.95497 3.24416 3.71568 4.91917L5.69558 5.20201C5.79411 4.5123 6.3848 4 7.08151 4V2Z"
        fill="currentColor"
      />
      <path
        d="M16.5 13V12.5H14.6875H13.351C13.0498 12.5 12.7647 12.6357 12.5749 12.8694L11.5501 14.1306C11.3603 14.3643 11.0752 14.5 10.774 14.5H10H9.22597C8.92484 14.5 8.63975 14.3643 8.44986 14.1306L7.42514 12.8694C7.23525 12.6357 6.95016 12.5 6.64903 12.5H3.5V13C3.5 15.7614 5.73858 18 8.5 18H11.5C14.2614 18 16.5 15.7614 16.5 13Z"
        fill="currentColor"
      />
    </svg>
  );
}
