import classNames from 'classnames';
import React from 'react';

export function CardTitle(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <small
      className={classNames(
        'uppercase font-black leading-snug tracking-wider text-[11px]',
        props.className
      )}
    >
      {props.children}
    </small>
  );
}
