import { getNextVisibleEvent } from 'components/QuickMenu/utils';
import { formatDistanceStrict } from 'date-fns';
import { DateTime } from 'luxon';
import { useTodayEvents } from 'hooks/home/useTodayEvents';
import useCurrentTime from 'hooks/useCurrentTime';
import React from 'react';
import {
  getLocationMetadata,
  handleVideoClick,
  MEET_LINK_PLACEHOLDER_DRAFT,
} from 'utils/video';
import useTimezone from 'hooks/useTimeZone';
import AvatarGroup from 'joy/AvatarGroup';
import { motion } from 'framer-motion';
import Button from 'joy/Button';
import IconVideoRoom from 'components/Icons/IconVideoRoom';
import useHotkey from 'hooks/useHotkey';
import Tooltip from 'joy/Tooltip';
import { useUserEmail } from 'hooks/auth/authAtoms';
import IconExternalLink from 'components/Icons/IconExternalLink';
import IconMapPin from 'components/Icons/IconMapPin';

export default React.memo(function JoinEvent() {
  const userEmail = useUserEmail();
  const events = useTodayEvents();
  const nextEvent = getNextVisibleEvent(events);
  const timezone = useTimezone();
  const now = useCurrentTime() || DateTime.now().setZone(timezone);

  const eventVideo = nextEvent?.videoConferences[0];

  const { label, href, isVideoLink, isExternal } = getLocationMetadata(
    eventVideo?.link || nextEvent?.location || ''
  );
  const videoHref =
    eventVideo?.provider === 'GOOGLE_MEET'
      ? `${href}?authuser=${userEmail}`
      : href;

  const minutesToEvent = nextEvent
    ? Math.floor(nextEvent?.startAt.diff(now, 'minutes').minutes)
    : Infinity;

  const hasAttendees =
    (nextEvent?.attendees || []).filter((a) => a.email !== userEmail).length >
    0;

  const isJoinable =
    videoHref != undefined && minutesToEvent <= 6 && hasAttendees;

  useHotkey(
    'v',
    { scope: 'all', enabled: nextEvent != undefined && isJoinable },
    () => handleVideoClick({ url: videoHref || '' })
  );

  if (!nextEvent || !isJoinable) return null;

  const timeDifference = formatDistanceStrict(
    nextEvent.startAt.toJSDate(),
    now.toJSDate()
  );

  const minutesLeftInEvent = `${formatDistanceStrict(
    now.toJSDate(),
    nextEvent.endAt.toJSDate()
  )} left`;

  return (
    <motion.div
      key="join-event"
      layout="size"
      transition={{ duration: 0.16 }}
      initial={{ scale: 0.98, y: 8, opacity: 0 }}
      animate={{ scale: 1, y: 0, opacity: 1, transition: { delay: 0.16 } }}
      exit={{ scale: 0.98, y: 8, opacity: 0 }}
      className="bg-popover pointer-events-auto relative flex w-80 shrink-0 items-center gap-3 overflow-hidden truncate rounded-full py-2 pl-6 pr-2 text-sm shadow-feintLg"
    >
      <div className="flex flex-col truncate">
        <p className="truncate font-semibold">{nextEvent.title}</p>
        <small className="text-secondary -mt-0.5 font-medium">
          {minutesToEvent <= 0
            ? `Now - ${minutesLeftInEvent}`
            : `In ${timeDifference}`}
        </small>
      </div>

      <div className="ml-auto flex shrink-0 items-center">
        <div className="mr-1.5 flex items-center">
          <AvatarGroup
            showTooltips={true}
            size={20}
            limit={3}
            avatars={nextEvent.attendees.map((a) => ({
              name: a.displayName || a.email,
              src: a.avatar,
              email: a.email,
            }))}
          />
        </div>

        <Tooltip
          content={label || ''}
          shortcut="V"
          disabled={label === undefined}
        >
          <Button
            onClick={() => handleVideoClick({ url: videoHref || '' })}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-xs font-semibold text-green-500  hover:text-green-600 active:scale-95 dark:bg-green-900 dark:hover:bg-green-800 dark:hover:text-green-400"
          >
            {isVideoLink ? (
              <IconVideoRoom />
            ) : isExternal ? (
              <IconExternalLink width={18} height={18} />
            ) : (
              <IconMapPin width={12} height={16} />
            )}
          </Button>
        </Tooltip>
      </div>
    </motion.div>
  );
});
