import { useEffect, useMemo, useState } from 'react';
import {
  ContactFavoriteAtQueryVariables,
  useContactFavoriteAtQuery,
} from 'graphql/queries/contacts.graphql';
import { useUpdateContactByPkMutation } from 'graphql/mutations/UpdateContact.graphql';
import * as Urql from 'urql';
import { useUserId } from 'hooks/auth/authAtoms';

interface UseFavoriteContactReturnT {
  favoriteAt: string;
  toggle: (state?: boolean) => Promise<void>;
  isFetching: boolean;
  isFavorite: boolean;
}

type QueryType = Omit<
  Urql.UseQueryArgs<ContactFavoriteAtQueryVariables>,
  'query'
>;

export function useFavoriteContact(
  contactId: string | undefined
): UseFavoriteContactReturnT {
  const userId = useUserId();
  const isNotSelf = contactId !== userId;
  const options = useMemo<QueryType>(
    () =>
      contactId && isNotSelf
        ? {
            variables: { id: contactId },
            context: {
              requestPolicy: 'cache-and-network' as const,
              suspense: true,
            },
          }
        : { pause: true },
    [contactId, isNotSelf]
  );

  const [query] = useContactFavoriteAtQuery(options);

  const [, updateContact] = useUpdateContactByPkMutation();
  const [favoriteAt, setFavoriteAt] = useState(
    query.data?.new_contact_by_pk?.favoriteAt
  );

  // Any updates from the server should override local optimistic states.
  useEffect(() => {
    setFavoriteAt(query.data?.new_contact_by_pk?.favoriteAt);
  }, [query.data?.new_contact_by_pk?.favoriteAt]);

  async function toggle(state = !!favoriteAt) {
    const newFavoriteAt = state ? null : new Date().toISOString();

    // Optimistically set favoriteAt.
    setFavoriteAt(newFavoriteAt);

    await updateContact({
      id: contactId,
      _set: { favoriteAt: newFavoriteAt },
    });
  }

  return {
    isFavorite: !!favoriteAt,
    isFetching: query.fetching,
    favoriteAt,
    toggle,
  };
}
