import React from 'react';
import PageHeader from './PageHeader';
import ConnectionGoogle from './rows/ConnectionGoogle';
import ConnectionSpotify from './rows/ConnectionSpotify';

export default function SettingsPageConnections(): JSX.Element {
  return (
    <>
      <PageHeader title="Connections" />

      <div className="flex flex-col space-y-3">
        <ConnectionGoogle />
        {/* TODO: Enable again once Zoom integration is approved */}
        {/* <ConnectionZoom /> */}
        <ConnectionSpotify />
      </div>
    </>
  );
}
