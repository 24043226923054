import { useGetGMapsPlacesQuery } from 'graphql/queries/getGMapsPlaces.graphql';
import ComboBox, { ComboBoxItem, ComboBoxActions } from 'joy/ComboBox';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PageControlHeader from '../PageControlHeader';
import { useThrottle } from 'react-use';
import Input from 'joy/Input';
import { useUserEmail } from 'hooks/auth/authAtoms';
import useProfile, { useProfileUpdate } from 'hooks/useProfile';
import useHotkey from 'hooks/useHotkey';

export default React.memo(function ProfileLocation(): JSX.Element {
  const userEmail = useUserEmail();
  const profile = useProfile(userEmail || '');
  const updateProfile = useProfileUpdate(userEmail || '');
  const initialValue = profile?.lastLocation;
  const ref = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState(initialValue || '');
  const query = useThrottle(value, 250);

  const [gMapsPlacesQueryResult] = useGetGMapsPlacesQuery({
    pause: !value.length,
    variables: {
      term: query,
    },
  });

  const autoCompleteItems = useMemo(() => {
    const items: ComboBoxItem[] = [];
    const places = gMapsPlacesQueryResult.data?.getGMapsPlaces.places || [];

    if (places.length > 0 && value.length > 0) {
      const itemsPlaces: ComboBoxItem[] = places.slice(0, 5).map((item) => ({
        id: item.id,
        type: 'option',
        value: item.description,
      }));

      if (itemsPlaces) {
        items.push({ type: 'title', value: 'Places' });
        items.push(...itemsPlaces);
      }
    }

    return items;
  }, [gMapsPlacesQueryResult.data?.getGMapsPlaces.places, value]);

  const onItemSelect = useCallback(
    async (
      input: string,
      item: ComboBoxItem | undefined,
      actions: ComboBoxActions
    ) => {
      if (!profile?.lastLocation) return;

      if (input.length > 0) {
        if (item == null || item.type != 'option') {
          setValue(profile.lastLocation || '');
          return;
        }

        if (item.value != initialValue) {
          actions.setOpen(false);
          actions.setInputFocused(false);

          updateProfile({ lastLocation: item.value });
        }
      } else {
        updateProfile({ lastLocation: null });
      }
    },
    [initialValue, profile?.lastLocation, updateProfile]
  );

  const onBlur = useCallback(() => {
    updateProfile({ lastLocation: value });
  }, [updateProfile, value]);

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  useHotkey('enter', { scope: 'settings', enabledWithinInput: true }, () => {
    ref.current?.blur();
  });

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Location"
        description="Set your current location."
      />

      <ComboBox
        className="w-full"
        items={autoCompleteItems}
        submitOnBlur
        onInputChange={setValue}
        onSubmit={onItemSelect}
        inputRef={ref}
      >
        <Input
          ref={ref}
          className="w-full rounded-lg bg-gray-100 px-3 py-3 text-sm outline-none transition-colors hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600"
          value={value}
          placeholder="e.g Berlin, Germany"
          onBlur={onBlur}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
      </ComboBox>
    </div>
  );
});
