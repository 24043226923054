import { useCreateCalendarLinkMutation } from 'graphql/mutations/CreateCalendarLink.graphql';
import {
  DeleteLinkDocument,
  DeleteLinkMutation,
  DeleteLinkMutationVariables,
} from 'graphql/mutations/DeleteCalendarLink.graphql';
import { useUpdateCalendarLinkMutation } from 'graphql/mutations/UpdateCalendarLink.graphql';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { isCalendarLinkDraft } from './useCreateDraftCalendarLink';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { frontendSlotsToServerSlots } from '../helpers/transformCalendarLinkHelper';
import { CalendarSlotInput } from '@graphql-types@';
import { trackEvent } from 'utils/analytics';
import { EventName } from 'types/analytics';
import { urqlClientWithoutSubscriptions } from 'graphql/client';
import { selectedCalendarLinkIdAtom } from './useSelectedCalendarLink';
import {
  calendarLinksFamily,
  calendarLinkIdsAtom,
  CalendarLink,
  CalendarLinkType,
} from '../calendarLinkAtoms';
import useToaster from 'joy/Toasts/useToaster';
import useCopyShareLink from 'components/ShareBar/useCopyShareLink';

export function useUpdateCalendarLink() {
  const [createCalendarLinkStatus, createLink] =
    useCreateCalendarLinkMutation();
  const [, updateLink] = useUpdateCalendarLinkMutation();
  const { add } = useToaster();
  const { copySlots, copyLink } = useCopyShareLink();

  const deleteCalendarLink = useAtomCallback(
    useCallback((_get, set, calendarLinkId: string) => {
      calendarLinksFamily.remove(calendarLinkId);
      set(selectedCalendarLinkIdAtom, null);
      set(calendarLinkIdsAtom, (ids) =>
        ids.filter((id) => id !== calendarLinkId)
      );
      deleteCalendarLinkMutation({
        id: calendarLinkId,
      });
      trackEvent(EventName.DeletedShareLink);
      return;
    }, [])
  );

  const updateCalendarLink = useAtomCallback(
    useCallback((_get, set, calendarLink: CalendarLink) => {
      set(calendarLinksFamily(calendarLink.id), calendarLink);
    }, [])
  );

  const saveCalendarLink = useAtomCallback(
    useCallback(
      async (_get, set, calendarLink: CalendarLink) => {
        if (isCalendarLinkDraft(calendarLink)) {
          set(calendarLinksFamily(calendarLink.id), {
            ...calendarLink,
            status: 'creating',
          });
          set(calendarLinkIdsAtom, (ids) => [...ids, calendarLink.id]);
          set(selectedCalendarLinkIdAtom, null);

          add({
            id: 'share-link-create',
            label: 'Creating share link...',
            kind: 'loading',
          });

          const res = await createLink({
            objects: {
              id: calendarLink.id,
              description: calendarLink.description,
              eventMaxDurationMinutes: calendarLink.eventMaxDurationMinutes,
              eventMinDurationMinutes: calendarLink.eventMinDurationMinutes,
              location: calendarLink.location,
              title: calendarLink.title,
              alias: calendarLink.alias ? calendarLink.alias : null,
              slots: sanitizeSlots(calendarLink.slots, calendarLink.type),
            },
          });
          const newCalendarLink = res.data?.createCalendarLink;
          set(calendarLinksFamily(calendarLink.id), {
            ...calendarLink,
            status: 'published',
          });

          if (!newCalendarLink) {
            return;
          }

          add({
            id: 'share-link-create',
            label: 'Share link created',
            description: 'Slots and link are ready to share',
            kind: 'success',
            actions: [
              {
                label: 'Copy slots',
                onClick: () =>
                  copySlots(newCalendarLink.id, calendarLink.slots),
              },
              {
                label: 'Copy link',
                onClick: () =>
                  copyLink(
                    newCalendarLink.id,
                    newCalendarLink.alias || undefined
                  ),
              },
            ],
          });

          trackEvent(EventName.CreatedShareLink);
          return newCalendarLink;
        }

        const res = await updateLink({
          objects: {
            id: calendarLink.id,
            alias: calendarLink.alias,
            bufferDurationMinutes: calendarLink.bufferDurationMinutes,
            calendarId: calendarLink.calendarId,
            description: calendarLink.description,
            location: calendarLink.location,
            title: calendarLink.title,
            eventMaxDurationMinutes: calendarLink.eventMaxDurationMinutes,
            eventMinDurationMinutes: calendarLink.eventMinDurationMinutes,
            slots: sanitizeSlots(calendarLink.slots, calendarLink.type),
          },
        });
        set(selectedCalendarLinkIdAtom, null);
        trackEvent(EventName.UpdatedShareLink);
        return res.data?.updateCalendarLink;
      },
      [updateLink, add, createLink, copySlots, copyLink]
    )
  );

  return {
    deleteCalendarLink,
    updateCalendarLink,
    saveCalendarLink,
    createCalendarLinkStatus,
  };
}

function sanitizeSlots(
  slots: CalendarSlotDate[],
  type: CalendarLinkType
): CalendarSlotInput[] {
  if (type === 'recurring') {
    return frontendSlotsToServerSlots(slots);
  }
  return frontendSlotsToServerSlots(
    slots.map((slot) => ({ ...slot, recurrenceRules: [] }))
  );
}

async function deleteCalendarLinkMutation(
  payload: DeleteLinkMutationVariables
) {
  trackEvent(EventName.CreatedEvent);

  return urqlClientWithoutSubscriptions
    .mutation<DeleteLinkMutation>(DeleteLinkDocument, payload, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise();
}
