/* 1f803f0c88d5cc35d2961b8cb4bd904324597b0d
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CalendarHistorySubscriptionVariables = Types.Exact<{
  calendarIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type CalendarHistorySubscription = { __typename?: 'subscription_root', calendarHistory: Array<{ __typename?: 'calendarHistory', id: string, updatedAt: any }> };


export const CalendarHistoryDocument = gql`
    subscription CalendarHistory($calendarIds: [String!]!) {
  calendarHistory(where: {id: {_in: $calendarIds}}) {
    id
    updatedAt
  }
}
    `;

export function useCalendarHistorySubscription<TData = CalendarHistorySubscription>(options: Omit<Urql.UseSubscriptionArgs<CalendarHistorySubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CalendarHistorySubscription, TData>) {
  return Urql.useSubscription<CalendarHistorySubscription, TData, CalendarHistorySubscriptionVariables>({ query: CalendarHistoryDocument, ...options }, handler);
};