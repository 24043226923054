import { motion } from 'framer-motion';
import React, { useState } from 'react';
import CalendarLinkDetails from './ShareBarDetails';
import ShareBarContent from './ShareBarContent';
import { useSelectedCalendarLink } from 'hooks/calendarLink/creation/useSelectedCalendarLink';

export default React.memo(function ShareBar() {
  const [showDetails, setShowDetails] = useState(false);
  const calendarLink = useSelectedCalendarLink();

  if (!calendarLink) return null;

  return (
    <motion.aside
      key="share-bar"
      layout="size"
      className="flex w-[560px] flex-col gap-2"
      transition={{ duration: 0.16 }}
      initial={{ scale: 0.98, y: 8, opacity: 0 }}
      animate={{ scale: 1, y: 0, opacity: 1, transition: { delay: 0.16 } }}
      exit={{ scale: 0.98, y: 8, opacity: 0 }}
    >
      {showDetails && (
        <motion.div
          transition={{ duration: 0.12 }}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
        >
          <CalendarLinkDetails
            handleHideDetails={() => setShowDetails(false)}
          />
        </motion.div>
      )}

      <ShareBarContent
        onHideDetails={() => setShowDetails(false)}
        onShowDetails={() => setShowDetails(true)}
        link={calendarLink}
        detailsOpen={showDetails}
      />
    </motion.aside>
  );
});
