import { useUpdateEffect } from '@react-aria/utils';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Select, SelectRef } from 'joy/Select';
import { monthDays, months } from './WidgetBirthday';

export function WidgetBirthdayPicker({
  date,
  onChange,
}: {
  date: string | null;
  onChange: (date: Date) => void;
}) {
  const parsedDate = date ? DateTime.fromISO(date) : DateTime.now();

  const [month, setMonth] = useState(date ? parsedDate.monthLong : '');
  const [day, setDay] = useState(date ? parsedDate.day.toString() : '');

  const getMonthLength = useCallback(() => {
    return (monthDays as { [x: string]: number })[month.toLowerCase()];
  }, [month]);

  useEffect(() => {
    if (!date) {
      setMonth('');
      setDay('');
    } else {
      setMonth(DateTime.fromISO(date).monthLong);
      setDay(DateTime.fromISO(date).day.toString());
    }
  }, [date, setMonth, setDay]);

  useUpdateEffect(() => {
    if (month && day) {
      const newDate = DateTime.fromObject({
        month: months.indexOf(month) + 1,
        day: parseInt(day),
      });

      onChange(newDate.toJSDate());
    }
  }, [month, day]);

  const dayRef = useRef<SelectRef>(null);

  return (
    <div className="mt-2 flex w-full space-x-2">
      <div className="w-8/12">
        <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
          Month
        </small>
        <Select
          onChange={(month) => {
            setMonth(month);
            setDay('');
            dayRef.current?.focus();
          }}
          options={months.map((m) => ({
            type: 'option',
            value: m,
          }))}
          value={month}
          placeholder={'eg. March'}
        />
      </div>

      <div className="w-4/12">
        <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
          Day
        </small>
        <Select
          onChange={setDay}
          options={new Array(getMonthLength()).fill(0).map((_, i) => ({
            type: 'option',
            value: Number(i + 1).toString(),
          }))}
          disabled={!month}
          value={day}
          placeholder={'eg. 11'}
          ref={dayRef}
        />
      </div>
    </div>
  );
}
