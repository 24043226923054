import React from 'react';
import Connection from '../controls/Connection';
import IconGoogle from '../icons/IconGoogle';
import { useUser } from 'hooks/auth/authAtoms';

export default React.memo(function ConnectionGoogle(): JSX.Element {
  const user = useUser();
  const isConnected = user?.email !== undefined;

  return (
    <Connection
      title="Google"
      description={user?.email || ''}
      icon={IconGoogle}
      connected={isConnected}
      onConnect={() => null}
      onDisconnect={() => null}
    />
  );
});
