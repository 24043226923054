/* 0a7237bc373626ddf37d05505982a938b7f85099
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserStatusSubscriptionVariables = Types.Exact<{
  userEmail: Types.Scalars['String'];
}>;


export type UserStatusSubscription = { __typename?: 'subscription_root', userStatus_by_pk?: { __typename?: 'userStatus', emoji?: string | null, endAt?: any | null, integrationAmieEventId?: string | null, integrationAmieStartAt?: any | null, integrationAmieEndAt?: any | null, message?: string | null, startAt?: any | null, status: string, userId?: string | null, userEmail: string } | null };


export const UserStatusDocument = gql`
    subscription UserStatus($userEmail: String!) {
  userStatus_by_pk(userEmail: $userEmail) {
    emoji
    endAt
    integrationAmieEventId
    integrationAmieStartAt
    integrationAmieEndAt
    message
    startAt
    status
    userId
    userEmail
  }
}
    `;

export function useUserStatusSubscription<TData = UserStatusSubscription>(options: Omit<Urql.UseSubscriptionArgs<UserStatusSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<UserStatusSubscription, TData>) {
  return Urql.useSubscription<UserStatusSubscription, TData, UserStatusSubscriptionVariables>({ query: UserStatusDocument, ...options }, handler);
};