import classNames from 'classnames';
import IconCheckCircle from 'components/Icons/IconCheckCircle';
import IconMore from 'components/Icons/IconMore';
import IconEditPencil from 'components/Settings/icons/IconEditPencil';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';
import { useRefreshCalendarLinks } from 'hooks/calendarLink/creation/useCalendarLink';
import { isCalendarLinkDraft } from 'hooks/calendarLink/creation/useCreateDraftCalendarLink';
import { useSetSelectedCalendarLinkId } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { useUpdateCalendarLink } from 'hooks/calendarLink/creation/useUpdateCalendarLink';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';
import useToaster from 'joy/Toasts/useToaster';
import Tooltip from 'joy/Tooltip';
import React, { useCallback, useRef, useState } from 'react';
import { formatShareLink } from 'utils/share';
import DurationDropdown from './DurationDropdown';
import ShareLinkOptions from './ShareLinkOptions';

interface Props {
  link: CalendarLink;
  detailsOpen: boolean;
  onShowDetails: () => void;
  onHideDetails: () => void;
}

export default function ShareBarContent({
  link,
  detailsOpen,
  onShowDetails,
  onHideDetails,
}: Props) {
  const titleRef = useRef<HTMLInputElement | null>(null);
  const [titleFocused, setTitleFocused] = useState(false);
  const refresh = useRefreshCalendarLinks();
  const isDraft = isCalendarLinkDraft(link);
  const setSelectedCalendarLinkId = useSetSelectedCalendarLinkId();
  const { updateCalendarLink, saveCalendarLink, deleteCalendarLink } =
    useUpdateCalendarLink();
  const { add } = useToaster();

  const isCalendarLinkValid = validateCalendarLink(link);

  const handleClose = useCallback(() => {
    setSelectedCalendarLinkId(null);
  }, [setSelectedCalendarLinkId]);

  const handleCreateDraft = useCallback(async () => {
    onHideDetails();
    handleClose();
    const result = await saveCalendarLink(link);
    if (!result?.id) return;

    refresh();
  }, [onHideDetails, handleClose, saveCalendarLink, link, refresh]);

  const handleDone = useCallback(async () => {
    onHideDetails();
    handleClose();

    await saveCalendarLink(link);
  }, [link, handleClose, onHideDetails, saveCalendarLink]);

  const handleDeleteCalendarLink = useCallback(async () => {
    onHideDetails();
    handleClose();

    if (isDraft) return;

    try {
      add({
        id: 'share-link-delete',
        label: 'Deleting share link...',
        kind: 'loading',
      });

      await deleteCalendarLink(link.id);

      add({
        id: 'share-link-delete',
        label: 'Share link deleted',
        description: "The link can't be used anymore",
        kind: 'success',
      });
    } catch {
      add({
        id: 'share-link-delete-error',
        label: 'Share link could not be deleted',
        description: 'Please try again later',
        kind: 'error',
      });
    }
  }, [add, link.id, deleteCalendarLink, handleClose, isDraft, onHideDetails]);

  const hasSelectedSlots = !!link?.slots && link.slots.length > 0;

  useHotkey('esc', { scope: 'global', enabledWithinInput: true }, () => {
    if (titleFocused && isDraft) {
      titleRef.current?.blur();
    } else {
      handleClose();
    }
  });

  return (
    <div className="bg-popover pointer-events-auto z-100 flex items-center rounded-xl p-3 shadow-feintLg">
      <div className="flex flex-col truncate">
        {isDraft && (
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            ref={titleRef}
            tabIndex={0}
            type="text"
            onFocus={() => setTitleFocused(true)}
            onBlur={() => setTitleFocused(false)}
            className={classNames(
              'h-9 min-w-[120px] truncate rounded-md px-2.5 py-1.5 text-sm font-medium leading-none outline-none transition-colors',
              {
                'bg-gray-100 dark:bg-gray-600': titleFocused,
                'bg-transparent hover:bg-gray-100 dark:hover:bg-gray-600':
                  !titleFocused,
              }
            )}
            spellCheck={false}
            value={link?.title}
            onChange={(event) => {
              updateCalendarLink({
                ...link,
                title: event.currentTarget.value || '',
              });
            }}
          />
        )}
        {!isDraft && (
          <div className="ml-1 flex flex-col">
            <small className="text-sm font-semibold outline-none">
              {link.title}
            </small>
            <small className="text-secondary max-w-[200px] truncate text-xs font-medium">
              {formatShareLink(link)}
            </small>
          </div>
        )}
      </div>

      <div className="ml-auto flex shrink-0 items-center">
        <div className="flex items-center gap-1.5">
          <DurationDropdown link={link} onChange={updateCalendarLink} />

          <div className="flex items-center">
            <Tooltip
              content={detailsOpen ? 'Hide details' : 'Edit details'}
              placement="top-center"
              targetOffset={12}
            >
              <Button
                onClick={detailsOpen ? onHideDetails : onShowDetails}
                className="flex h-9 w-9 items-center justify-center rounded-l-lg bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-500"
              >
                <IconEditPencil />
              </Button>
            </Tooltip>
            <div className="bg-separator my-auto h-full w-px" />
            <ShareLinkOptions
              link={link}
              handleDelete={handleDeleteCalendarLink}
            >
              <Button className="flex h-9 w-9 items-center justify-center rounded-r-lg bg-gray-100 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-500">
                <IconMore className="h-5 w-5 rotate-90" />
              </Button>
            </ShareLinkOptions>
          </div>
        </div>

        <div className="mx-2.5 my-auto h-5 w-px rounded bg-gray-200 opacity-100 dark:bg-gray-600" />

        <Button
          onClick={isDraft ? handleCreateDraft : handleDone}
          disabled={!isCalendarLinkValid || !hasSelectedSlots}
          className={classNames(
            'flex h-9 items-center gap-1 rounded-lg bg-green-500 px-3.5 text-s font-semibold text-white transition-all group-active:scale-95 dark:bg-green-600',
            {
              'opacity-100': isCalendarLinkValid && hasSelectedSlots,
              'opacity-50': !isCalendarLinkValid || !hasSelectedSlots,
            }
          )}
        >
          <IconCheckCircle className="-ml-0.5 h-4 w-4 text-green-100" />
          {isDraft ? 'Create' : 'Done'}
        </Button>
      </div>
    </div>
  );
}

function validateCalendarLink(calendarLink: CalendarLink | null): boolean {
  if (!calendarLink) return false;
  return calendarLink.title.length > 2;
}
