/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { dateYearOrdinal } from 'utils/time';
import {
  useFetchEvents,
  useFetchVisibleWeekEvents,
} from './events/useGridEvents';
import { calendarStartDateAtom } from './useCalendar';

/**
 * Simple wrapper around use useExtendedFetchEvents to avoid overfetching
 */
const eagerlyLoadedCalendars = new Set();

export function useLoadCalendarsCurrentWeekEagerly() {
  const fetchCalendarEvents = useFetchVisibleWeekEvents();
  return useAtomCallback(
    useCallback(
      (get, _set, calendarId: string) => {
        const startAt = get(calendarStartDateAtom).toISO();
        const key = `${calendarId}-${startAt}`;
        if (eagerlyLoadedCalendars.has(key)) {
          return;
        }
        eagerlyLoadedCalendars.add(key);
        fetchCalendarEvents(calendarId);
      },
      [fetchCalendarEvents]
    )
  );
}

export function useLoadCalendarsNextWeekEagerly() {
  const prefetchCalendarEvents = useLoadCalendarWeekEagerly();
  return useCallback(
    async (calendarId: string) => {
      prefetchCalendarEvents({ calendarId, weekOffset: 1 });
      prefetchCalendarEvents({ calendarId, weekOffset: 2 });
      prefetchCalendarEvents({ calendarId, weekOffset: 3 });
    },
    [prefetchCalendarEvents]
  );
}
export function useLoadCalendarsPreviousWeekEagerly() {
  const prefetchCalendarEvents = useLoadCalendarWeekEagerly();
  return useCallback(
    async (calendarId: string) => {
      prefetchCalendarEvents({ calendarId, weekOffset: -1 });
      prefetchCalendarEvents({ calendarId, weekOffset: -2 });
      prefetchCalendarEvents({ calendarId, weekOffset: -3 });
    },
    [prefetchCalendarEvents]
  );
}

function useLoadCalendarWeekEagerly() {
  const fetchCalendarEvents = useFetchEvents();
  return useAtomCallback(
    useCallback(
      (
        get,
        _set,
        { calendarId, weekOffset }: { calendarId: string; weekOffset: number }
      ) => {
        const startAt = get(calendarStartDateAtom).plus({ week: weekOffset });
        const key = `${calendarId}-${dateYearOrdinal(startAt)}`;
        if (eagerlyLoadedCalendars.has(key)) {
          return;
        }
        eagerlyLoadedCalendars.add(key);
        fetchCalendarEvents({
          calendarId,
          startAt,
          endAt: startAt.plus({ days: 7 }),
        });
      },
      [fetchCalendarEvents]
    )
  );
}
