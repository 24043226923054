/* 8ad059548dd340a7f10bbd3dfed2cb6926126014
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserStatusByPkMutationVariables = Types.Exact<{
  userEmail: Types.Scalars['String'];
  _set: Types.UserStatus_Set_Input;
}>;


export type UpdateUserStatusByPkMutation = { __typename?: 'mutation_root', update_userStatus_by_pk?: { __typename?: 'userStatus', status: string, message?: string | null } | null };


export const UpdateUserStatusByPkDocument = gql`
    mutation UpdateUserStatusByPk($userEmail: String!, $_set: userStatus_set_input!) {
  update_userStatus_by_pk(pk_columns: {userEmail: $userEmail}, _set: $_set) {
    status
    message
  }
}
    `;

export function useUpdateUserStatusByPkMutation() {
  return Urql.useMutation<UpdateUserStatusByPkMutation, UpdateUserStatusByPkMutationVariables>(UpdateUserStatusByPkDocument);
};