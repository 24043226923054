import React from 'react';
import classNames from 'classnames';
import IconHand from 'components/Icons/IconHand';
interface Props {
  value: string;
}

export default function ShortcutKey({ value }: Props): JSX.Element {
  const isNonKey = isSpecialCharacter(value);
  const key = replaceSpecialCharacter(value);
  const icon = getIcon(value);

  return (
    <div
      className={classNames(
        'ss02 flex h-7 min-w-[28px] items-center justify-center rounded-md bg-gray-200 px-2 font-medium text-gray-700 dark:bg-gray-700 dark:text-gray-300',
        { 'text-sm': !isNonKey, uppercase: key.length === 1 }
      )}
    >
      {isNonKey ? (
        <span className="flex h-full items-center justify-center lowercase">
          {key}
        </span>
      ) : icon ? (
        icon
      ) : (
        key
      )}
    </div>
  );
}

function isMacSystem() {
  return /(Mac)/i.test(window.navigator.platform);
}

function isSpecialCharacter(value: string): boolean {
  return ['⌘', '←', '→', '↑', '↓'].includes(value);
}

function getIcon(value: string): React.ReactElement | undefined {
  switch (value) {
    // add more icon cases as needed
    case 'drag':
      return (
        <>
          <IconHand className="mr-1 h-4 w-4" /> drag
        </>
      );
  }
}

function replaceSpecialCharacter(value: string) {
  if (isMacSystem()) return value;

  switch (value) {
    case '⌘':
      return 'ctrl';
    case '⌥':
      return 'alt';
    default:
      return value;
  }
}
