import { ColorFamily } from '@graphql-types@';
import { motion } from 'framer-motion';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import {
  fullSelectionEventsAtom,
  useEventsSelection,
} from 'hooks/useEventsSelection';
import { useColorHotkey } from 'hooks/useHotkey';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomCallback } from 'jotai/utils';
import Button from 'joy/Button';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';
import { TEST_ID_BULK_ACTION_CONTAINER } from 'utils/constants';

export default React.memo(function EventActionsHintBar(): JSX.Element {
  const { openModal } = useUpdateModal();
  const { saveGridEvent } = useUpdateGridEvent();
  const selectedEvents = useEventsSelection();

  const updateSelectedEvents = useAtomCallback(
    useCallback(
      async (get, _, color: ColorFamily) => {
        const fullSelectedEvents = get(fullSelectionEventsAtom);

        await Promise.all(
          fullSelectedEvents.map(async (event) => {
            if (!event.belongsToUserCalendar) return Promise.resolve();

            await saveGridEvent({
              id: event.id,
              colorFamily: color,
            });
          })
        );
      },
      [saveGridEvent]
    )
  );

  useColorHotkey((color) => updateSelectedEvents(color), 'all');

  const onActionClick = useCallback(
    () => openModal(ModalType.QuickMenu),
    [openModal]
  );

  const numEventsText = `${selectedEvents.length} selected`;

  return (
    <motion.aside
      key="actions-bar"
      layout="size"
      data-testid={TEST_ID_BULK_ACTION_CONTAINER}
      transition={{ duration: 0.16 }}
      initial={{ scale: 0.98, y: 8, opacity: 0 }}
      animate={{ scale: 1, y: 0, opacity: 1, transition: { delay: 0.16 } }}
      exit={{ scale: 0.98, y: 8, opacity: 0 }}
      className="bg-popover pointer-events-auto relative left-4 flex shrink-0 items-center justify-between gap-4 overflow-hidden rounded-xl py-2 pr-2 pl-3.5 text-sm shadow-feintLg"
    >
      <div className="text-primary">{numEventsText}</div>
      <Button
        className="text-primary group ml-2 flex items-center rounded-lg bg-gray-150 p-1.5 pl-2.5 hover:bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-500"
        onClick={onActionClick}
      >
        <p className="text-xs font-semibold">Actions</p>
        <div className="ml-2 rounded bg-gray-300 px-1 py-0.5 text-xs font-semibold transition-colors group-hover:bg-gray-300 dark:bg-gray-500 dark:group-hover:bg-gray-400">
          ⌘J
        </div>
      </Button>
    </motion.aside>
  );
});
