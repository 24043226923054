import React from 'react';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';
import { Card } from './Card';
import { CardTitle } from './CardTitle';
import LocationInput from 'components/EventPopover/EventLocation/LocationInput';
import { ColorFamily } from '@graphql-types@';
import { WorkerProvider } from 'contexts/worker';

export function LocationCard({
  calendarLink,
  onChange,
}: {
  calendarLink: CalendarLink;
  onChange: (calendarLink: CalendarLink) => void;
}) {
  const handleChangeLocation = (location?: string) => {
    onChange({ ...calendarLink, location: location || '' });
  };

  return (
    <Card>
      <div className="flex flex-col">
        <CardTitle className=" text-pink-400 dark:text-pink-300">
          Location
        </CardTitle>
        <p className="text-secondary mt-1 truncate text-sm font-medium">
          Where will you meet?
        </p>
        <WorkerProvider>
          <LocationInput
            colorFamily={ColorFamily.Gray}
            onChange={handleChangeLocation}
            readOnly={false}
            value={calendarLink.location}
            className="mt-2 rounded-lg bg-gray-100 dark:bg-gray-600 "
            inputClassName="
           w-full h-9 bg-gray-100 dark:bg-gray-600 outline-none focus:shadow-outline rounded-md px-2.5 text-s outline-none  focus:border-gray-200 hover:border-gray-300 focus:bg-gray-150"
            locationButtonClassName="absolute -right-1 top-0"
            isSlotSharing={true}
            isDraft={true}
          />
        </WorkerProvider>
      </div>
    </Card>
  );
}
