import Dropdown, { DropdownItem } from 'joy/Dropdown';
import React, { useMemo } from 'react';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';
import { isCalendarLinkDraft } from 'hooks/calendarLink/creation/useCreateDraftCalendarLink';
import Tooltip from 'joy/Tooltip';
import { formatShareLink } from 'utils/share';
import IconTrash from 'components/Icons/IconTrash';
import IconLinkExternal from 'components/Icons/IconLinkExternal';
import IconCopyText from 'components/Icons/IconCopyText';
import IconCopy from 'components/Icons/IconCopy';
import useCopyShareLink from './useCopyShareLink';
import { useSetSelectedCalendarLinkId } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import IconEditPencil from 'components/Settings/icons/IconEditPencil';
import type { Placement } from 'react-laag';

interface Props {
  children: React.ReactChild;
  link: CalendarLink;
  showEdit?: boolean;
  tooltipPlacement?: Placement;
  handleDelete: () => void;
}

export default function ShareLinkOptions({
  link,
  children,
  showEdit = false,
  tooltipPlacement = 'top-center',
  handleDelete,
}: Props) {
  const isDraft = isCalendarLinkDraft(link);
  const { copySlots, copyLink } = useCopyShareLink();
  const setSelectedCalendarLinkId = useSetSelectedCalendarLinkId();

  const menuItems: DropdownItem[] = useMemo(
    () => [
      { value: 'Link options', type: 'title' },
      {
        value: `Copy ${link.slots.length} ${
          link.slots.length === 1 ? 'slot' : 'slots'
        }`,
        type: 'option',
        icon: <IconCopyText />,
        hidden: link.slots.length === 0,
        onSelect: () => copySlots(link.id, link.slots),
      },
      {
        value: 'Copy link',
        type: 'option',
        hidden: isDraft,
        icon: <IconCopy className="h-4 w-4" />,
        onSelect: () => copyLink(link.id, link.alias),
      },
      {
        value: 'Open in new tab',
        type: 'option',
        hidden: isDraft,
        icon: <IconLinkExternal />,
        onSelect: () => {
          window.open(formatShareLink(link, false), '_blank');
        },
      },
      { type: 'separator', hidden: !showEdit },
      {
        value: 'Edit details',
        type: 'option',
        hidden: !showEdit,
        icon: <IconEditPencil />,
        onSelect: () => {
          setSelectedCalendarLinkId(link.id);
        },
      },
      { type: 'separator' },
      {
        type: 'option',
        value: isDraft ? 'Discard link' : 'Delete link',
        variant: 'red',
        onSelect: handleDelete,
        icon: <IconTrash className="h-4 w-4" />,
      },
    ],
    [
      isDraft,
      link,
      showEdit,
      copySlots,
      copyLink,
      handleDelete,
      setSelectedCalendarLinkId,
    ]
  );

  return (
    <Dropdown items={menuItems}>
      <Tooltip content="Options" placement={tooltipPlacement} targetOffset={12}>
        {children}
      </Tooltip>
    </Dropdown>
  );
}
