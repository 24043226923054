import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';

type ChangelogState =
  | {
      count: number;
      lastRead: number;
    }
  | undefined;
const LOCALSTORAGE_CHANGELOG = 'changelog';
const changelogAtom = atomWithStorage<ChangelogState>(
  LOCALSTORAGE_CHANGELOG,
  undefined
);

function useChangeLogCount() {
  const [changelog, setChangelog] = useAtom(changelogAtom);
  const [hasNewItem, setHasNewItem] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const allChangelogs = require.context(
      '../content/changelog/',
      false, // recursive?
      /\.md$/
    );

    setCurrentCount(allChangelogs.keys().length);
  }, []);

  useEffect(() => {
    const isFirstCheck = currentCount && !changelog;
    if (isFirstCheck) return setHasNewItem(true);

    const didCountIncrease = Boolean(
      changelog?.count && changelog.count < currentCount
    );
    const isInPast =
      changelog &&
      DateTime.now().diff(DateTime.fromSeconds(changelog.lastRead), 'seconds')
        .seconds > 1;

    if (didCountIncrease && isInPast) {
      return setHasNewItem(true);
    }

    return setHasNewItem(false);
  }, [changelog, currentCount]);

  const setHasReadChangelog = () => {
    setChangelog({
      count: currentCount,
      lastRead: DateTime.now().toSeconds(),
    });
  };

  return {
    setHasReadChangelog,
    hasNewItem,
  };
}

export default useChangeLogCount;
