import dynamic from 'next/dynamic';
import HomeControl from 'components/HomeControl';
import { useCalendarModeValue } from 'hooks/useCalendarMode';
import { useRouter } from 'next/router';
import React from 'react';
import { isHomePage, isRootPage } from 'utils/router';
import DateControl from '../DateControl';
import DateHeader from 'components/DateHeader';

const SharePopover = dynamic(() => import('components/SharePopover'), {
  ssr: false,
});

export default React.memo(function TopPanel() {
  const router = useRouter();
  const calendarMode = useCalendarModeValue();

  if (!isRootPage(router)) return null;

  return (
    <div
      className="bg-background absolute z-80 flex h-15 w-full shrink-0 items-center pl-2.5 pr-4"
      style={{ WebkitAppRegion: 'drag' } as React.CSSProperties}
    >
      {calendarMode !== 'selecting-recurring-slots' && <DateHeader />}

      {isHomePage(router) && <HomeControl />}

      <div className="ml-auto flex items-center">
        {calendarMode !== 'selecting-recurring-slots' && <DateControl />}

        <SharePopover />
      </div>
    </div>
  );
});
