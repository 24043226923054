import React, { useCallback } from 'react';
import { useUpdateSettingPageModal } from 'hooks/useModal';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';
import IconArrowLeft from './icons/IconArrowLeft';
import IconArrowRight from './icons/IconArrowRight';
import { SettingsPageType } from './types';
import { getNextPage, getPreviousPage, settingsPages } from './utils';

interface Props {
  activePage: SettingsPageType;
}

export default function PageNavigation({ activePage }: Props): JSX.Element {
  const setSettingsPage = useUpdateSettingPageModal();
  const previousPageType = getPreviousPage(activePage);
  const nextPageType = getNextPage(activePage);

  const goToPage = useCallback(
    (pageType: SettingsPageType) =>
      (event: KeyboardEvent | React.MouseEvent) => {
        event.preventDefault();
        setSettingsPage(pageType);
      },
    [setSettingsPage]
  );

  useHotkey(
    'left, up',
    {
      scope: 'settings',
      enabledWithinInput: activePage === SettingsPageType.Feedback,
    },
    previousPageType ? goToPage(previousPageType) : () => null
  );

  useHotkey(
    'right, down, enter',
    {
      scope: 'settings',
      enabledWithinInput: activePage === SettingsPageType.Feedback,
    },
    nextPageType ? goToPage(nextPageType) : () => null
  );

  return (
    <div className="flex w-full space-x-6">
      {previousPageType && (
        <Button
          onClick={goToPage(previousPageType)}
          className="group flex flex-1 items-center justify-between rounded-lg bg-gray-100 p-4 hover:bg-gray-200 dark:bg-gray-750 dark:hover:bg-gray-700"
        >
          <div className="flex translate-x-2 opacity-30 transition-all group-hover:translate-x-1 group-hover:opacity-60">
            <IconArrowLeft />
          </div>
          <div className="flex flex-col items-end">
            <p className="text-sm font-semibold">
              {settingsPages[previousPageType].title}
            </p>
            <p className="text-secondary mt-0.5 text-xs font-medium">
              Previous
            </p>
          </div>
        </Button>
      )}

      {nextPageType && (
        <Button
          onClick={goToPage(nextPageType)}
          className="group flex flex-1 items-center justify-between rounded-lg bg-gray-100 p-4 hover:bg-gray-200 dark:bg-gray-750 dark:hover:bg-gray-700"
        >
          <div className="flex flex-col items-start">
            <p className="text-sm font-semibold">
              {settingsPages[nextPageType].title}
            </p>
            <p className="text-secondary mt-0.5 text-xs font-medium">Next</p>
          </div>
          <div className="flex -translate-x-2 opacity-30 transition-all group-hover:-translate-x-1 group-hover:opacity-60">
            <IconArrowRight />
          </div>
        </Button>
      )}
    </div>
  );
}
