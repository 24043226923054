import React from 'react';
import RadioListItem, { RadioListItemProps } from './RadioListItem';

interface Props {
  items: RadioListItemProps[] | (() => RadioListItemProps[]);
}

export default function RadioList({ items }: Props): JSX.Element {
  const radioItems = typeof items === 'function' ? items() : items;

  return (
    <div className="flex flex-col space-y-1.5">
      {radioItems.map((item) => (
        <RadioListItem key={`videoOption-${item.title}`} {...item} />
      ))}
    </div>
  );
}
