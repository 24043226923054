import Button from 'joy/Button';
import React from 'react';
import classNames from 'classnames';

interface Props {
  isFollowing: boolean;
  compact?: boolean;
  handleClick: (state: boolean) => void;
}

export default React.memo(function FollowToggle({
  isFollowing,
  compact = false,
  handleClick,
}: Props) {
  return (
    <Button
      onClick={() => {
        handleClick(isFollowing);
      }}
      className={classNames(
        'group self-start rounded-lg border font-semibold',
        {
          'bg-dropdown px-2.5 py-1.5 text-[11px]': compact,
          'px-6 py-3 text-sm': !compact,
          'border-gray-150 hover:bg-gray-150 dark:border-gray-600 dark:hover:bg-gray-600':
            isFollowing,
          'border-transparent bg-gray-200 dark:bg-gray-700':
            !isFollowing && compact,
          'border-transparent bg-green-500 text-white dark:bg-green-600':
            !isFollowing && !compact,
        }
      )}
    >
      <span className="opacity-100 group-hover:opacity-0">
        {isFollowing ? 'Following' : 'Follow'}
      </span>
      <span className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 group-hover:opacity-100">
        {isFollowing ? 'Unfollow' : 'Follow'}
      </span>
    </Button>
  );
});
