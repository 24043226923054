import React from 'react';
import PageHeader from './PageHeader';
import PreviewCalendar from './previews/PreviewCalendar';
import AppearanceTheme from './rows/AppearanceTheme';
import EventColor from './rows/EventColor';
import EventColorRename from './rows/EventColorRename';
import RowSeparator from './rows/RowSeparator';

export default function SettingsPageAppearance(): JSX.Element {
  return (
    <>
      <PageHeader title="Appearance" />

      <PreviewCalendar showEvents={true} showEventsDefaultColor={true} />

      <div className="mt-10 flex flex-col">
        <AppearanceTheme />
        <RowSeparator />
        {/* TODO: Decide what to do with this part
        <EventColor />
        <RowSeparator /> */}
        <EventColorRename />
      </div>
    </>
  );
}
