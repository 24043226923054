/* f1ed8776ac84ca875d1607e9da94a342227e4196
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SendFeedbackMutationVariables = Types.Exact<{
  content: Types.Scalars['String'];
  metadata: Types.Scalars['String'];
}>;


export type SendFeedbackMutation = { __typename?: 'mutation_root', sendFeedback: { __typename?: 'SendFeedbackOutput', success: boolean, feedback?: { __typename?: 'Feedback', id: string, content: string, metadata: string } | null } };


export const SendFeedbackDocument = gql`
    mutation SendFeedback($content: String!, $metadata: String!) {
  sendFeedback(input: {content: $content, metadata: $metadata}) {
    success
    feedback {
      id
      content
      metadata
    }
  }
}
    `;

export function useSendFeedbackMutation() {
  return Urql.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(SendFeedbackDocument);
};