import { NewEventRsvpEnum } from '@graphql-types@';
import type { EventConfirmation } from 'hooks/useEventConfirmations';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { rrulestr } from 'rrule';
import { Attendee, EventDiff, EventDiffValue, IGridEvent } from 'types/events';
import { ObjectKeys } from 'utils/toolbox';
import sanitizeHtml from 'xss';

interface LabelProps {
  event: EventConfirmation['event'];
  hasDiff: boolean;
  addedGuests: number;
  removedGuests: number;
  isRecurring: boolean;
  hasExistingAttendees: boolean;
  type: EventConfirmation['type'];
}

export function getConfirmationLabel({
  isRecurring,
  type,
  addedGuests,
}: LabelProps): string {
  if (type === 'create') {
    let title = 'Create';
    if (addedGuests === 0) title += ' event';
    if (addedGuests === 1) title += ' & send invite';
    if (addedGuests > 1) title += ` & send ${addedGuests} invites`;

    return title;
  }

  if (type === 'update') {
    let title = 'Edit';
    if (addedGuests === 0 && !isRecurring) title += ' event';
    if (addedGuests === 0 && isRecurring) title += ' recurring event';
    if (addedGuests === 1) title += ' & send invite';
    if (addedGuests > 1) title += ` & send ${addedGuests} invites`;

    return title;
  }

  return isRecurring ? 'Delete recurring event' : 'Delete event';
}

export function getConfirmationDescription({
  type,
  hasDiff,
  addedGuests,
  removedGuests,
  isRecurring,
  hasExistingAttendees,
}: LabelProps): string {
  if (type === 'delete') {
    return 'Are you sure you want to delete this event?';
  }

  console.log({
    hasDiff,
    addedGuests,
    hasExistingAttendees,
  });

  if (!hasExistingAttendees && addedGuests === 0 && hasDiff && isRecurring) {
    return 'Apply changes to this or all future events.';
  }

  if (!hasExistingAttendees && addedGuests === 0 && hasDiff) {
    return 'Apply changes to this event only.';
  }

  if (hasExistingAttendees && addedGuests === 0 && hasDiff && isRecurring) {
    return 'Apply changes to this or all future events, and notify guests.';
  }

  if (hasExistingAttendees && addedGuests === 0 && hasDiff) {
    return 'Apply changes to this event, and notify guests.';
  }

  if (addedGuests && removedGuests) {
    return 'Apply changes to this event, and notify guests.';
  }

  if (addedGuests === 1) {
    return 'The guest will be notified and invited to the event.';
  }

  if (removedGuests === 1) {
    return 'The guest will be removed and notified.';
  }

  if (removedGuests > 1) {
    return `${removedGuests} guests will be removed and notified.`;
  }

  return `${addedGuests} invitees will be notified and invited to the event.`;
}

export function getButtonLabel(
  { hasDiff, type, addedGuests }: LabelProps,
  short = false
): string {
  if (addedGuests > 0 && !hasDiff) {
    return `Send invite${addedGuests === 1 ? '' : 's'}`;
  }

  if (type === 'create') {
    return short ? 'Create' : 'Create event';
  }

  if (type === 'delete') {
    return short ? 'Delete' : 'Delete event';
  }

  return short ? 'Update' : 'Update event';
}

export function getDiffKeyLabel(key: keyof IGridEvent): string {
  switch (key) {
    case 'title':
      return 'Title';
    case 'startAt':
      return 'Start time';
    case 'endAt':
      return 'End time';
    case 'location':
      return 'Location';
    case 'isAllDay':
      return 'All day';
    case 'description':
      return 'Description';
    case 'videoConferences':
      return 'Video link';
    case 'recurrenceRules':
      return 'Recurrence';
    case 'attendees':
      return 'Guests';
    case 'colorFamily':
      return 'Color';
    default:
      return key;
  }
}

function isDiffAttendees(
  key: string,
  _value: EventDiffValue
): _value is Attendee[] {
  return key === 'attendees';
}

export function sanitizeDescription(description: string): string {
  const markup = sanitizeHtml(description, {
    whiteList: {
      a: ['href', 'target', 'rel'],
      p: [],
      span: [],
      li: [],
      ul: [],
      ol: [],
      strong: [],
      div: [],
      em: [],
      s: [],
      u: [],
      b: [],
      i: [],
      mark: [],
      sub: [],
      sup: [],
    },
  });

  return markup.replace(/<[^>]*>?/gm, '');
}

export function getDiffValue(
  key: keyof IGridEvent,
  value: EventDiffValue,
  timeFormat: string
): string | Attendee[] {
  if (key === 'rsvp') {
    if (value === NewEventRsvpEnum.Yes) return 'Going';
    if (value === NewEventRsvpEnum.No) return 'Not going';
    if (value === NewEventRsvpEnum.NotInvited) return 'Not going';
    if (value === NewEventRsvpEnum.Unknown) return 'Unknown';
  }

  if (key === 'colorFamily' && typeof value === 'string') {
    return capitalize(value);
  }

  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value.toString();
  if (value instanceof DateTime) return value.toFormat(timeFormat);
  if (isDiffAttendees(key, value) && Array.isArray(value)) {
    return value;
  }

  return '';
}

export interface DiffEntry {
  label: string;
  old: string | Attendee[];
  new: string | Attendee[];
}

export function getAttendeeDiff(diff: EventDiff | undefined): {
  added: Attendee[];
  removed: Attendee[];
} {
  let added: Attendee[] = [];
  let removed: Attendee[] = [];

  if (!diff || !diff.attendees) return { added, removed };
  const oldValue = isDiffAttendees('attendees', diff.attendees.old)
    ? diff.attendees.old
    : [];
  const newValue = isDiffAttendees('attendees', diff.attendees.new)
    ? diff.attendees.new
    : [];

  added = newValue.filter(
    (attendee) =>
      !oldValue.some((oldAttendee) => oldAttendee.email === attendee.email)
  );
  removed = oldValue.filter(
    (attendee) =>
      !newValue.some((newAttendee) => newAttendee.email === attendee.email)
  );

  /**
   * Hide the current user from the diff when we have anothe user added
   */
  if (added.length >= 2) {
    added = added.filter((attendee) => !attendee.organizer);
  }

  return { added, removed };
}

export function getConfirmationDiffArray(
  diff: EventDiff,
  timeFormat: string
): DiffEntry[] {
  const sanitizedValues = ObjectKeys(diff).reduce((result, key) => {
    const value = diff[key];
    if (!value) {
      return result;
    }
    switch (key) {
      case 'attendees':
        return result;
      case 'recurrenceRules':
        return {
          ...result,
          recurrence: getRecurrenceDiff(diff),
        };
      case 'startAt':
      case 'endAt':
      case 'isAllDay':
        return {
          ...result,
          Time: getTimeDiff(diff, timeFormat),
        };
      case 'location':
      case 'videoConferences':
        return {
          ...result,
          location: getLocationDiff(diff),
        };
      default:
        return {
          ...result,
          [key]: {
            label: key,
            old: getDiffValue(key as keyof IGridEvent, value.old, timeFormat),
            new: getDiffValue(key as keyof IGridEvent, value.new, timeFormat),
          },
        };
    }
  }, {});

  return Object.values(sanitizedValues);
}

function getTimeDiff(diff: EventDiff, timeFormat: string): DiffEntry | null {
  const startAtDiff = diff.startAt;
  const endAtDiff = diff.endAt;
  if (!startAtDiff || !endAtDiff) {
    return null;
  }

  const didDayChange =
    startAtDiff.old instanceof DateTime && startAtDiff.new instanceof DateTime
      ? startAtDiff.old.ordinal !== startAtDiff.new.ordinal
      : false;

  const fullFormat = didDayChange ? `dd MMM, ${timeFormat}` : timeFormat;

  const oldStartAtValue = getDiffValue('startAt', startAtDiff.old, fullFormat);
  const newStartAtValue = getDiffValue('startAt', startAtDiff.new, fullFormat);

  const oldEndAtValue = getDiffValue('endAt', endAtDiff.old, timeFormat);
  const newEndAtValue = getDiffValue('endAt', endAtDiff.new, timeFormat);

  if (diff.isAllDay) {
    return {
      label: 'Time',
      old: diff.isAllDay.old
        ? 'All day'
        : `${oldStartAtValue} - ${oldEndAtValue}`,
      new: diff.isAllDay.new
        ? 'All day'
        : `${newStartAtValue} - ${newEndAtValue}`,
    };
  }

  return {
    label: 'Time',
    old: `${oldStartAtValue} - ${oldEndAtValue}`,
    new: `${newStartAtValue} - ${newEndAtValue}`,
  };
}

function getLocationDiff(diff: EventDiff): DiffEntry | null {
  if (
    !diff.location ||
    typeof diff.location.old !== 'string' ||
    typeof diff.location.new !== 'string'
  ) {
    return null;
  }

  return {
    label: 'Location',
    old: diff.location.old || 'No location',
    new: diff.location.new || 'No location',
  };
}

function getRecurrenceDiff(diff: EventDiff): DiffEntry | null {
  if (!diff.recurrenceRules) {
    return null;
  }

  const formatRecurrence = (recurrenceRules?: null | string[]) => {
    if (!recurrenceRules || recurrenceRules.length === 0) {
      return 'None';
    }
    return capitalize(rrulestr(recurrenceRules.join('\n')).toText());
  };

  return {
    label: 'Recurrence',
    old: formatRecurrence(diff.recurrenceRules.old as string[]),
    new: formatRecurrence(diff.recurrenceRules.new as string[]),
  };
}
