import React, { useCallback } from 'react';
import { motion, transform, useAnimation } from 'framer-motion';
import useCurrentTime from 'hooks/useCurrentTime';
import { PropsOf } from 'joy/utils';
import classNames from 'classnames';

interface Props extends PropsOf<'div'> {
  active?: boolean;
}

function CalendarButtonComponent(
  { active, ...props }: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const currentTime = useCurrentTime();
  const controls = useAnimation();

  const onMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const bounds = event.currentTarget.getBoundingClientRect();
      const { offsetWidth, offsetHeight } = event.currentTarget;

      const newX = (event.clientX - bounds.x) / offsetWidth;
      const newY = (event.clientY - bounds.y) / offsetHeight;

      const angle = 15;
      const rotateX = transform(newX, [0, 1], [angle, -angle]);
      const rotateY = transform(newY, [0, 1], [angle, -angle]);

      controls.start({ rotateX, rotateY });
    },
    [controls]
  );

  const onMouseLeave = useCallback(() => {
    controls.start({ rotateX: 0.5, rotateY: 0.5 });
  }, [controls]);

  if (!currentTime) return null;

  return (
    <div
      className="group relative flex h-9 w-9 scale-100 transform cursor-pointer transition active:scale-95"
      style={{ perspective: 100 }}
      ref={ref}
      {...props}
    >
      <motion.div
        animate={controls}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        transition={{ type: 'spring', mass: 0.44, stiffness: 90 }}
        className="group relative flex h-9 w-9 flex-col items-center overflow-hidden rounded-[10px] bg-white font-bold shadow-feint dark:bg-gray-700"
      >
        <div className="z-10 flex w-full items-center justify-center pt-2 pb-1.5">
          <div
            className={classNames('h-0.5 w-4 rounded-full transition-colors', {
              'bg-rose-500 dark:bg-rose-400': active,
              'bg-gray-400 group-hover:bg-rose-500 dark:bg-gray-500 dark:group-hover:bg-rose-400':
                !active,
            })}
          />
        </div>
        <div className="text-primary relative z-20 flex h-full w-full flex-col">
          <div className="flex w-full flex-grow justify-center text-[12px] font-bold leading-none tracking-normal">
            {currentTime.toFormat('d')}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const CalendarButton = React.forwardRef(CalendarButtonComponent);
export default React.memo(CalendarButton);
