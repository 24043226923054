import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';
import SettingsContent from './SettingsContent';
import useInterface from 'hooks/useInterface';

const Modal = dynamic(() => import('components/Modal'), { ssr: false });

export default function Settings(): JSX.Element {
  const { setIsScaled } = useInterface();

  const onOpen = useCallback(() => {
    setIsScaled(true);
  }, [setIsScaled]);

  const onClose = useCallback(() => {
    setIsScaled(false);
  }, [setIsScaled]);

  return (
    <Modal
      id={ModalType.Settings}
      scope="settings"
      onOpen={onOpen}
      onClose={onClose}
    >
      <SettingsContent />
    </Modal>
  );
}
