import { useUpdateEffect } from '@react-aria/utils';
import classNames from 'classnames';
import ComboBox, { ComboBoxItem, ComboBoxItemOption } from 'joy/ComboBox';
import Input from 'joy/Input';
import { noop } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

interface Props {
  value?: string;
  options: ComboBoxItem[] | (() => ComboBoxItem[]);
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

export interface SelectRef {
  focus: () => void;
}

export const Select = React.forwardRef(
  (
    { value, options, onChange, className, placeholder, disabled }: Props,
    ref: React.Ref<SelectRef>
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [localValue, setLocalValue] = useState(value || '');
    const [input, setInput] = useState(() => localValue);
    const [inputFocused, setInputFocused] = useState(false);
    const activeOptionRef = useRef<HTMLLIElement | null>(null);
    const [localOptions, setLocalOptions] = useState<ComboBoxItemOption[]>(
      () => {
        const comboboxItems =
          typeof options === 'function' ? options() : options;

        return comboboxItems.filter(
          (o) => o.type === 'option'
        ) as ComboBoxItemOption[];
      }
    );

    const optionsFiltered = useMemo(() => {
      const optionsResults = localOptions.filter((option) =>
        option.value.toLowerCase().includes(input.toLowerCase())
      );

      return value === input ? localOptions : optionsResults;
    }, [localOptions, input, value]);

    const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(true);
      event.currentTarget.select();
    }, []);

    const getValidValue = useCallback(
      (v: string) => {
        return localOptions.find(
          (o) =>
            o.type === 'option' && o.value.toLowerCase() === v.toLowerCase()
        );
      },
      [localOptions]
    );

    const onSelect = useCallback(
      (value: string) => {
        const validValue = getValidValue(value);
        if (validValue) {
          setInput(validValue.value);
          setLocalValue(validValue.value);
        } else {
          setInput('');
        }

        inputRef.current?.blur();
      },
      [setInput, inputRef, setLocalValue, getValidValue]
    );

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          inputRef.current?.focus();
        },
      };
    });

    useEffect(() => {
      if (!inputFocused) return noop();

      setTimeout(() => {
        if (!activeOptionRef.current) return noop();

        // For Jest
        if (typeof activeOptionRef.current.scrollIntoView !== 'function')
          return noop();
        activeOptionRef.current.scrollIntoView({
          block: 'center',
        });
      }, 0);
    }, [inputFocused]);

    useUpdateEffect(() => {
      if (value !== localValue) {
        onChange(localValue);
      }
      setInputFocused(false);
    }, [localValue]);

    useUpdateEffect(() => {
      setLocalOptions(options as ComboBoxItemOption[]);
    }, [options]);

    useUpdateEffect(() => {
      if (value === localValue) return noop();

      if (typeof value !== 'undefined') {
        setInput(value);
        setLocalValue(value);
      }
    }, [value]);

    return (
      <ComboBox
        onSubmit={onSelect}
        onInputChange={setInput}
        items={optionsFiltered}
        inputRef={inputRef}
        onInputSubmit={() => {
          onSelect(input);
        }}
        onInputFocusChange={(focused) => {
          setInputFocused(focused);
        }}
        submitOnBlur={true}
      >
        <Input
          disabled={disabled}
          value={input}
          readOnly={false}
          ref={inputRef}
          spellCheck={false}
          placeholder={placeholder}
          className={classNames(
            'flex w-full rounded-lg bg-gray-100 px-3 py-3 text-sm outline-none transition-colors hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-600/60 dark:hover:bg-gray-600 dark:focus:bg-gray-600',
            {
              'cursor-pointer': !inputFocused,
              'cursor-text': inputFocused,
            },
            className
          )}
          onFocus={onFocus}
        />
      </ComboBox>
    );
  }
);

Select.displayName = 'Select';
