import classNames from 'classnames';
import Button, { ButtonProps } from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React from 'react';

interface Props extends ButtonProps<'button'> {
  active?: boolean;
  transparent?: boolean;
  shortcut?: string;
  className?: string;
  tooltipContent?: React.ReactChild;
  children: React.ReactNode;
}

function NavigationItemComponent(
  {
    active = false,
    transparent = false,
    shortcut,
    className,
    tooltipContent,
    children,
    ...props
  }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  return (
    <Tooltip
      disabled={!tooltipContent}
      shortcut={shortcut}
      placement="right-center"
      content={tooltipContent || ''}
    >
      <div
        ref={ref}
        className="group relative flex items-center justify-center px-1"
      >
        <Button
          className={classNames(
            'flex items-center justify-center transition-colors',
            className,

            {
              'h-9 w-9 rounded-[10px] hover:bg-gray-150 dark:hover:bg-gray-750':
                !transparent,
              'text-icons hover:text-gray-600 dark:hover:text-gray-300':
                !active && !transparent,
              'bg-gray-150 text-gray-800 dark:bg-gray-750 dark:text-gray-100':
                active && !transparent,
            }
          )}
          {...props}
        >
          {children}
        </Button>
      </div>
    </Tooltip>
  );
}

const NavigationItem = React.forwardRef(NavigationItemComponent);
export default NavigationItem;
