import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function PageLayout({ children }: Props): JSX.Element {
  return (
    <div className="flex flex-col min-w-[520px] max-w-[740px] min-h-full w-full px-16 py-16">
      {children}
    </div>
  );
}
