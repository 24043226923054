import classNames from 'classnames';
import Button from 'joy/Button';
import React, { useMemo } from 'react';

export interface RadioListItemProps {
  title: string;
  badge?: string;
  description?: string;
  example?: string;
  disabled?: boolean;
  selected: boolean;
  onSelect: () => void;
}

export default function RadioListItem({
  title,
  description,
  example,
  disabled,
  selected,
  onSelect,
  badge,
}: RadioListItemProps): JSX.Element {
  const isActive = useMemo(() => !disabled && selected, [disabled, selected]);

  return (
    <Button
      onClick={onSelect}
      activeScale={false}
      className={classNames(
        'flex w-full cursor-pointer items-center rounded-lg p-3 transition-colors',
        {
          'bg-gray-200 dark:bg-gray-600': selected,
          'bg-gray-100 dark:bg-gray-700 ': !selected,
          'hover:bg-gray-150 dark:hover:bg-gray-600': !disabled,
          'cursor-not-allowed opacity-60': disabled,
        }
      )}
      disabled={disabled}
    >
      <div
        className={classNames(
          'flex h-5 w-5 items-center justify-center rounded-full border-2',
          {
            'border-gray-600  bg-gray-600 dark:border-gray-300 dark:bg-gray-300':
              selected,
            'border-gray-300 bg-transparent dark:border-gray-400': !selected,
          }
        )}
      >
        <span
          className={classNames(
            'flex h-2 w-2 origin-center rounded-full bg-white transition-transform dark:bg-gray-600',
            {
              'scale-100': isActive,
              'scale-0': !isActive,
            }
          )}
        />
      </div>

      <div className="ml-2.5 flex flex-col text-left">
        <div className="flex">
          <p className="text-sm font-medium">{title}</p>
          {badge && (
            <div className="text-secondary ml-2 flex rounded-full bg-gray-200 px-2.5 py-1 text-[10px] font-bold uppercase tracking-wider dark:bg-gray-750">
              Coming soon
            </div>
          )}
        </div>
        {description && (
          <p className="text-secondary mb-1 mt-0.5 text-xs">{description}</p>
        )}
      </div>

      {example && (
        <div className="ml-auto flex text-left">
          <p className="text-secondary text-xs font-medium">{example}</p>
        </div>
      )}
    </Button>
  );
}
