import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import ComboBox, { ComboBoxActions, ComboBoxItem } from 'joy/ComboBox';
import Input from 'joy/Input';
import { EVENT_PLACEHOLDER_LOCATION } from 'utils/events';
import { EventColor } from 'types/color';
import { AnimatePresence } from 'framer-motion';
import GoToLocationButton from './GoToLocationButton';
import { useWorkerContext } from 'contexts/worker';
import { useLocationAutoComplete } from './useLocation';
import { AMIE_VIDEO_LINK_PLACEHOLDER } from 'utils/video';
import { DROPDOWN_TITLE_PLACES } from './utils';
import ContextMenu, {
  ContextMenuType,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { getTokenFromFirebaseOrCookie } from 'graphql/exchanges/fetchAuthExchange';

interface LocationComboboxProps {
  onChange: (value: string | undefined) => void;
  onSave?: () => void;
  inputClassName?: string;
  value?: string;
  readOnly: boolean;
  hasFocus?: boolean;
  colorMap: EventColor;
  locationButtonClassName?: string;
  isDraft?: boolean;
}

export default function LocationCombobox({
  onChange,
  onSave,
  inputClassName,
  value,
  readOnly,
  hasFocus,
  colorMap,
  locationButtonClassName,
  isDraft,
}: LocationComboboxProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { getComboBoxItems } = useLocationAutoComplete({
    value,
    isDraft,
  });

  const { getContextMenuItems } = useLocationAutoComplete({
    value,
    isDraft,
    readOnly,
  });
  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();

  const { handleLocationSearch, locationResults } = useWorkerContext();

  const getComboBoxLocationItems = useCallback(() => {
    const videoItems = getComboBoxItems();

    const mapItems: ComboBoxItem[] = [];

    if (locationResults.length > 0 && value != null && value.length > 0) {
      const itemsPlaces: ComboBoxItem[] = locationResults.map((item) => ({
        id: item.id,
        type: 'option',
        value: item.description,
      }));

      if (itemsPlaces) {
        mapItems.push({ type: 'title', value: DROPDOWN_TITLE_PLACES });
        mapItems.push(...itemsPlaces);
      }
    }

    // display location matches if any, or video options
    return mapItems.length > 0 ? mapItems : videoItems;
  }, [getComboBoxItems, locationResults, value]);

  const onLocationChange = useCallback(
    async (event) => {
      const token = await getTokenFromFirebaseOrCookie();
      const text = event.target.value;

      handleLocationSearch({
        text,
        token,
      });

      return onChange(text);
    },
    [handleLocationSearch, onChange]
  );

  const onComboBoxItemSelect = useCallback(
    async (
      inputValue: string,
      item: ComboBoxItem | undefined,
      actions: ComboBoxActions
    ) => {
      const value = item?.type === 'option' ? item.value : inputValue;

      if (
        item?.type === 'option' &&
        item.value === AMIE_VIDEO_LINK_PLACEHOLDER
      ) {
        actions.setInputValue(AMIE_VIDEO_LINK_PLACEHOLDER);
      }

      onChange(value ? value : undefined);

      actions.setInputValue(value);
      actions.setOpen(false);

      // save is needed to attach a Google Meet link
      // we run this for all locations however for consistency
      // and to avoid users having to guess when their location gets saved on selection
      onSave?.();
    },
    [onChange, onSave]
  );

  return (
    <>
      <ContextMenu
        items={getContextMenuItems}
        onClose={() => closeContextMenu(ContextMenuType.Location)}
        placement={'right-start'}
        className="w-full"
      >
        <div
          onClickCapture={() => openContextMenu(ContextMenuType.Location)}
          className="w-full"
        >
          <ComboBox
            className="w-full"
            items={getComboBoxLocationItems}
            onInputChange={(inputValue) => {
              if (value === 'settings') {
                return;
              }
              onChange(inputValue);
            }}
            onSubmit={onComboBoxItemSelect}
            inputRef={inputRef}
          >
            <Input
              ref={inputRef}
              value={value}
              disabled={readOnly}
              placeholder={EVENT_PLACEHOLDER_LOCATION}
              onChange={onLocationChange}
              spellCheck={false}
              className={
                inputClassName ||
                classNames(
                  'w-full truncate bg-transparent text-sm font-medium outline-none',
                  {
                    [colorMap.placeholder]: hasFocus,
                  }
                )
              }
            />
          </ComboBox>
        </div>
      </ContextMenu>
      <AnimatePresence>
        {!hasFocus && (
          <div className={locationButtonClassName}>
            <GoToLocationButton location={value} visible={value !== ''} />
          </div>
        )}
      </AnimatePresence>
    </>
  );
}
