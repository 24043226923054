import useCalendar, { useUpdateCalendar } from 'hooks/useCalendar';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React from 'react';
import IconChevronLeft from './Icons/IconChevronLeft';
import IconChevronRight from './Icons/IconChevronRight';

const caretClassNames =
  'flex w-8 h-8 items-center justify-center hover:bg-gray-150 rounded-xl dark:hover:bg-gray-700 hover:text-gray-600 dark:hover:text-gray-200';

export default React.memo(function DateControl(): JSX.Element | null {
  const { startDate } = useCalendar();
  const { goToNextWeek, goToPrevWeek, goToToday } = useUpdateCalendar();

  useHotkey('t', 'global', goToToday);

  if (!startDate) return null;

  return (
    <>
      <div className="text-icons group flex items-center justify-center">
        <Tooltip content="Previous week" shortcut="←">
          <Button
            className={caretClassNames}
            aria-label="Go back one week"
            onClick={goToPrevWeek}
          >
            <span className="relative right-px">
              <IconChevronLeft />
            </span>
          </Button>
        </Tooltip>

        <Tooltip content="Next week" shortcut="→">
          <Button
            className={caretClassNames}
            aria-label="Go forward one week"
            onClick={goToNextWeek}
          >
            <span className="relative left-px">
              <IconChevronRight />
            </span>
          </Button>
        </Tooltip>
      </div>
      <Tooltip content="Go to today" shortcut="T" placement="bottom-center">
        <Button
          onClick={goToToday}
          className="ml-1.5 flex h-8 items-center justify-center rounded-lg bg-gray-150 px-3 text-s font-semibold leading-snug dark:bg-gray-750 dark:hover:bg-gray-700"
        >
          Today
        </Button>
      </Tooltip>
    </>
  );
});
