import classNames from 'classnames';
import React from 'react';
import Avatar, { AvatarOwnProps } from './Avatar';
import Tooltip from './Tooltip';
import { PropsOf } from './utils';
import Link from 'next/link';
import Button from './Button';

export interface AvatarGroupProps
  extends Omit<AvatarOwnProps, 'name' | 'src'>,
    PropsOf<'div'> {
  limit?: number;
  showTooltips?: boolean;
  avatars: (Pick<AvatarOwnProps, 'name' | 'src'> & { email: string })[];
}

function AvatarGroupComponent(
  {
    className,
    limit,
    avatars,
    size,
    grayscale,
    status,
    alternateStatus,
    ring,
    statusClassName,
    isLoading,
    isSidebar,
    showTooltips = false,
    ...props
  }: AvatarGroupProps,
  ref: React.Ref<HTMLDivElement>
) {
  const avatarProps = {
    size: (size = 24),
    grayscale,
    alternateStatus,
    status,
    ring,
    statusClassName,
    isLoading,
    isSidebar,
  };
  const hardLimit = limit || avatars.length;
  const remainingAvatarsTotal = avatars.length - hardLimit;
  const visibleAvatars = avatars.slice(0, hardLimit);
  const remainingAvatars = avatars.slice(hardLimit);

  return (
    <div ref={ref} className={classNames('flex', className)} {...props}>
      {visibleAvatars.map((avatar, index) => (
        <Link key={`avatar-group-${index}`} replace href={avatar.email}>
          <Button>
            <Tooltip disabled={!showTooltips} content={avatar.name}>
              <Avatar
                style={{ zIndex: index }}
                className="cursor-pointer ring ring-white dark:ring-gray-700"
                {...avatar}
                {...avatarProps}
              />
            </Tooltip>
          </Button>
        </Link>
      ))}

      {remainingAvatarsTotal > 0 && (
        <Tooltip
          disabled={!showTooltips}
          content={
            <div className="flex flex-col gap-1">
              {remainingAvatars.map((avatar, index) => (
                <div
                  className="flex items-center gap-1"
                  key={`tooltip-${index}`}
                >
                  <Avatar size={16} src={avatar.src} name={avatar.email} />
                  <small className="text-xs font-semibold text-gray-100">
                    {avatar.name || avatar.email}
                  </small>
                </div>
              ))}
            </div>
          }
        >
          <div
            className="flex cursor-default   items-center justify-center rounded-full bg-gray-200 ring ring-white dark:bg-gray-500 dark:ring-gray-700"
            style={{ width: size, height: size, zIndex: avatars.length + 1 }}
          >
            <small className="ss02 text-secondary text-xs font-bold text-gray-600 dark:text-gray-200">
              {remainingAvatarsTotal}
            </small>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

const AvatarGroup = React.forwardRef(AvatarGroupComponent);
export default React.memo(AvatarGroup);
