import { useHotkeyContext } from 'contexts/hotkey';
import { AnimatePresence } from 'framer-motion';
import useHotkey from 'hooks/useHotkey';
import useMounted from 'hooks/useMounted';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import usePrevious from 'hooks/usePrevious';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { HotkeyScope } from 'types/hotkeys';
import { ModalType } from 'types/modal';
interface Props {
  id: ModalType;
  children: React.ReactNode;
  scope?: HotkeyScope;
  blockClose?: boolean;
  style?: React.CSSProperties;
  onOpen?: () => void;
  onClose?: (closeReason?: string) => void;
}

function Modal({
  id,
  children,
  scope = 'modal',
  blockClose,
  style,
  onOpen,
  onClose,
}: Props) {
  const portalRef = useRef<HTMLBodyElement | null>(null);
  const isMounted = useMounted();

  const isOpen = useIsModalOpen(id);
  const { closeModal } = useUpdateModal();

  const { setScope, setPreviousScope, resetScope } = useHotkeyContext();

  const wasOpen = usePrevious(isOpen);

  useEffect(() => {
    portalRef.current = document.querySelector('body');
  }, []);

  useEffect(() => {
    if (!wasOpen && isOpen) {
      onOpen?.();

      if (scope) {
        resetScope();
        setScope(scope);
      }
    } else if (wasOpen && !isOpen) {
      onClose?.();
      if (scope) setPreviousScope(scope);
    }
  }, [
    isOpen,
    scope,
    setScope,
    setPreviousScope,
    onOpen,
    onClose,
    wasOpen,
    resetScope,
  ]);

  useHotkey('escape', { scope, enabled: !blockClose }, () => closeModal(id));

  if (!portalRef.current == null || !isMounted) return null;

  return createPortal(
    <AnimatePresence>
      <div id={`modal-${id}`} style={style}>
        {isOpen && children}
      </div>
    </AnimatePresence>,
    portalRef.current || document.body
  );
}

export default Modal;
