import { sanitizeAlias } from 'utils/share';
import { useCheckAliasAvailabilityQuery } from 'graphql/queries/CheckAliasAvailability.graphql';
import React, { useEffect } from 'react';
import { Card } from './Card';
import { CardInput } from './CardInput';
import { CardTitle } from './CardTitle';
import IconCheck from 'components/Icons/IconCheck';
import IconClose from 'components/Icons/IconClose';
import IconCubeTransparent from 'components/Icons/IconCubeTransparent';
import Tooltip from 'joy/Tooltip';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';

type AliasInputStatus =
  | 'no-alias'
  | 'unavailable'
  | 'available'
  | 'checking-availability';

interface Props {
  calendarLink: CalendarLink;
  onChange: (calendarLink: CalendarLink) => void;
}

export function AliasCard({ calendarLink, onChange }: Props) {
  const [inputValue, setInputValue] = React.useState(calendarLink.alias);
  const [aliasStatus, setAliasStatus] =
    React.useState<AliasInputStatus>('available');
  const [result] = useCheckAliasAvailabilityQuery({
    variables: { alias: inputValue },
    pause: !inputValue,
  });

  useEffect(() => {
    if (!inputValue) {
      setAliasStatus('no-alias');
      return;
    }
    if (inputValue === calendarLink.alias) {
      setAliasStatus('available');
      return;
    }
    const responseData = result.data?.checkAliasAvailability;
    if (responseData?.alias === inputValue && responseData.isAvailable) {
      setAliasStatus('available');
      onChange({
        ...calendarLink,
        alias: inputValue,
      });
    } else if (
      responseData?.alias === inputValue &&
      !responseData.isAvailable
    ) {
      setAliasStatus('unavailable');
    } else {
      setAliasStatus('checking-availability');
    }
  }, [result, inputValue, calendarLink.alias, calendarLink, onChange]);

  const handleChangeAlias = (alias: string) => {
    setInputValue(sanitizeAlias(alias));
  };

  return (
    <Card>
      <div className="relative flex flex-col">
        <CardTitle className="text-purple-400 dark:text-purple-300">
          Link Alias
        </CardTitle>
        <p className="text-secondary mt-1 mb-2 truncate text-sm font-medium">
          https://amie.so/s/
        </p>
        <CardInput
          className="pr-8"
          disabled={false}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          value={inputValue}
          onChange={handleChangeAlias}
          placeholder="e.g meet-with-dennis"
        />
        <Tooltip placement="top-center" content={aliasTooltipText(aliasStatus)}>
          <div className="absolute bottom-2.5 right-2">
            <AliasStatus aliasStatus={aliasStatus} />
          </div>
        </Tooltip>
      </div>
    </Card>
  );
}

function AliasStatus(props: { aliasStatus: AliasInputStatus }) {
  switch (props.aliasStatus) {
    case 'available':
      return <IconCheck className="h-4 w-4 text-green-400" />;
    case 'unavailable':
      return <IconClose className="h-4 w-4 text-red-400" />;
    case 'checking-availability':
      return (
        <IconCubeTransparent className="h-4 w-4 animate-spin text-gray-400" />
      );
    default:
      return null;
  }
}

function aliasTooltipText(aliasStatus: AliasInputStatus): string {
  switch (aliasStatus) {
    case 'available':
      return `Alias available`;
    case 'unavailable':
      return `Alias unavailable`;
    case 'checking-availability':
      return `Checking availability...`;
    default:
      return 'Add an alias';
  }
}
