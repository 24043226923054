import { useContactsValue } from 'hooks/useContacts';
import Avatar from 'joy/Avatar';
import React from 'react';
import { Attendee } from 'types/events';

interface Props {
  attendee: Attendee;
}

export default function DiffAttendee({ attendee }: Props) {
  const contacts = useContactsValue();
  const attendeeNameOrEmail = attendee.displayName || attendee.email;
  const attendeeAvatarSrc =
    attendee.avatar ||
    contacts.find((x) => x.emailAddress === attendee.email)?.avatar ||
    undefined;

  return (
    <div
      key={attendee.id}
      className="flex h-8 items-center self-start rounded-md bg-gray-100 px-2 py-1.5 leading-none dark:bg-gray-700"
    >
      <div className="flex items-center">
        <Avatar size={20} src={attendeeAvatarSrc} name={attendee.email} />

        <p className="ml-2 truncate text-s font-medium">
          {attendeeNameOrEmail}
        </p>

        {attendee.displayName && (
          <p className="text-secondary ml-1 truncate text-s font-medium">
            {attendee.email}
          </p>
        )}
      </div>
    </div>
  );
}
