import React from 'react';
import RadioList from '../controls/RadioList';
import PageControlHeader from '../PageControlHeader';
import { useGetMeetingOptions } from '../useSettings';

export default React.memo(function EventDefaultVideoCall(): JSX.Element {
  const getRadioItems = useGetMeetingOptions();

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Default meeting provider"
        description="Choose the preferred video call provider for your meetings."
      />

      <RadioList items={getRadioItems} />
    </div>
  );
});
