import { useDndContext } from '@dnd-kit/core';
import JoinEvent from 'components/JoinEvent';
import ShareLinkBottomBar from 'components/ShareBar';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelectedCalendarLink } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { useEventsSelection } from 'hooks/useEventsSelection';
import { useIsModalOpen } from 'hooks/useModal';
import React from 'react';
import { ModalType } from 'types/modal';
import EventActionsHintBar from '../EventActionsHintBar';

export default React.memo(function BottomPanel(): JSX.Element {
  const { active } = useDndContext();
  const isEventModalOpen = useIsModalOpen(ModalType.Event);
  const selectedEvents = useEventsSelection();
  const calendarLink = useSelectedCalendarLink();

  const isHintBarVisible =
    active === null && selectedEvents.length > 0 && !isEventModalOpen;

  const isShareLinkBarVisible = calendarLink && !isEventModalOpen;

  return (
    <AnimatePresence>
      <motion.div
        layout="position"
        className="pointer-events-none absolute bottom-0 left-0 right-0 z-30 flex flex-col items-center justify-center gap-3 p-3"
      >
        {isHintBarVisible && <EventActionsHintBar />}
        {isShareLinkBarVisible && <ShareLinkBottomBar />}
        {!isHintBarVisible && !isShareLinkBarVisible && <JoinEvent />}
      </motion.div>
    </AnimatePresence>
  );
});
