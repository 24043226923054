import React from 'react';

export function DownloadCardTitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) {
  return (
    <div className="mb-9">
      <p className="text-xs font-bold uppercase tracking-wider text-pink-200">
        {title}
      </p>
      <p className="text-lg font-semibold">{subtitle}</p>
    </div>
  );
}
