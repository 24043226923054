import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useTimezone from './useTimeZone';
import { useDocumentVisibility } from './useDocumentVisibility';

const TEN_SECONDS = 1000 * 10;

// This hook will return the current time, specified in the timezone of the logged in user.
// The returned value can be undefined, in case the hook ran in server-side rendering.
// This should not be used in server side because it will return the current time with the timezone
// setting of the server.
const useCurrentTime = (): DateTime | undefined => {
  const timezone = useTimezone();
  const [now, setNow] = useState<DateTime | undefined>(undefined);

  const documentVisibility = useDocumentVisibility();

  useEffect(() => {
    setNow(DateTime.now().setZone(timezone));

    const interval = setInterval(() => {
      setNow(DateTime.now().setZone(timezone));
    }, TEN_SECONDS);

    return () => clearInterval(interval);
  }, [timezone, documentVisibility]);

  return now;
};

export default useCurrentTime;
