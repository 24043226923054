import React from 'react';
import PageHeader from './PageHeader';
import EventAroundLink from './rows/EventAroundLink';
import EventDefaultVideoCall from './rows/EventDefaultVideoCall';
import EventHideDeclined from './rows/EventHideDeclined';
import EventZoomLink from './rows/EventZoomLink';
import RowSeparator from './rows/RowSeparator';

export default function SettingsPageMeetings(): JSX.Element {
  return (
    <>
      <PageHeader title="Meetings" />

      <div className="flex flex-col space-y-10">
        <EventDefaultVideoCall />
        <EventAroundLink />
        <EventZoomLink />
        <RowSeparator />
        <EventHideDeclined />
      </div>
    </>
  );
}
