import { Theme_Enum } from '@graphql-types@';
import classNames from 'classnames';
import { usePreferences } from 'hooks/usePreferences';
import React from 'react';
import { DownloadButtons } from './DownloadButtons';
import { DownloadCardTitle } from './DownloadTitle';

export function DownloadDesktopCard() {
  const { theme } = usePreferences();

  return (
    <div
      className={classNames(
        'relative h-[230px] w-full overflow-hidden rounded-3xl bg-white shadow-feint dark:bg-gray-750'
      )}
    >
      <div className="flex flex-row flex-wrap">
        <div className="w-6/12 p-6">
          <DownloadCardTitle
            title={`Desktop App`}
            subtitle={`In your dock with menu bar and notifications`}
          />
          <DownloadButtons />
        </div>
        <div className="w-6/12">
          <div className="-mr-8 -mb-8 mt-8">
            <img
              src={`/assets/download/mac-${
                theme === Theme_Enum.Dark ? 'dark' : 'light'
              }.png`}
              alt="desktop-app"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
