import IconQuestionMarkCircle from 'components/Icons/IconQuestionMarkCircle';
import { WidgetBirthdayPicker } from 'components/Profile/WidgetBirthdayPicker';
import useBirthday, { useBirthdayUpdate } from 'hooks/useBirthday';
import Button from 'joy/Button';
import { DateTime } from 'luxon';
import React from 'react';
import PageControlHeader from '../PageControlHeader';

interface Props {
  email: string;
}

export default React.memo(function ProfileBirthday({
  email,
}: Props): JSX.Element {
  const { date, isSuggested } = useBirthday(email);
  const { setDate, removeBirthday } = useBirthdayUpdate(email);

  const parsedDate = date ? DateTime.fromISO(date) : null;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Birthday"
        description="Set your birthday. Only day and month will be visible."
      />

      <div className="relative -mt-4 mb-4">
        <WidgetBirthdayPicker
          date={date}
          onChange={(date) => {
            setDate(date);
          }}
        />

        {parsedDate && !isSuggested && (
          <Button
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              removeBirthday();
            }}
            className="text-secondary absolute -bottom-6 left-0 flex items-center justify-center text-s font-semibold hover:text-red-500 dark:hover:text-red-400"
          >
            Remove
          </Button>
        )}
      </div>

      {isSuggested && parsedDate && (
        <div className="mt-4 flex w-full items-center rounded-lg border-t border-white bg-gray-100 py-1 pl-3 pr-1 dark:border-gray-800 dark:bg-gray-700 ">
          <div className="flex items-center gap-1.5 text-gray-400">
            <IconQuestionMarkCircle />
            <p className="text-secondary text-sm font-medium">
              Someone has suggested this date. Is it correct?
            </p>
          </div>

          <div className="ml-auto flex items-center gap-1">
            <Button
              onClick={() => setDate(parsedDate.toJSDate())}
              className="flex h-8 items-center gap-1 rounded-lg px-3 text-sm font-semibold text-green-500 dark:text-green-300"
            >
              Yes
            </Button>
            <div className="h-4 w-px bg-gray-200 dark:bg-gray-600" />
            <Button
              onClick={removeBirthday}
              className="flex h-8 items-center gap-1 rounded-lg px-3 text-sm font-semibold text-red-500 dark:text-red-300"
            >
              No
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
