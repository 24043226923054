import React from 'react';
import PageControlHeader from '../PageControlHeader';
import SwitchGroup from '../controls/SwitchGroup';

export default React.memo(function NotifcationsTodoSchedule(): JSX.Element {
  return (
    <SwitchGroup selected={false} disabled={true} onSelect={() => null}>
      <PageControlHeader
        title="Enable todo schedule notifications"
        description="Get notified when a todo has been scheduled."
        comingSoon={true}
        noPadding={true}
      />
    </SwitchGroup>
  );
});
