import classNames from 'classnames';
import Button from 'joy/Button';
import React from 'react';
import IconCheckCircle from '../icons/IconCheckCircle';
import IconTwitter from '../icons/IconTwitter';

interface Props {
  title: string;
  description?: string;
  icon?: typeof IconTwitter;
  connected: boolean;
  comingSoon?: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
}

export default function Connection({
  title,
  description,
  connected,
  comingSoon,
  icon: Icon,
  onConnect,
}: Props): JSX.Element {
  return (
    <div
      className={classNames(
        'flex items-center justify-between rounded-lg bg-gray-100 p-6 dark:bg-gray-750',
        { 'opacity-60 dark:opacity-40': comingSoon }
      )}
    >
      <div className="flex items-center space-x-3.5">
        {Icon && <Icon />}
        <div className="flex flex-col">
          <p className="text-sm font-medium">{title}</p>
          {description && (
            <p className="text-secondary mt-0.5 text-xs">{description}</p>
          )}
          {comingSoon && (
            <p className="text-secondary mt-0.5 text-xs">Coming soon</p>
          )}
        </div>
      </div>

      <div className="ml-auto">
        {connected ? (
          <div className="flex items-center space-x-1 text-green-500 dark:text-green-400">
            <div className="opacity-80">
              <IconCheckCircle />
            </div>
            <p className="text-xs font-medium">Connected</p>
          </div>
        ) : (
          <Button
            className="rounded-lg bg-gray-200 px-3 py-2 text-xs font-semibold hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
            onClick={onConnect}
          >
            Connect
          </Button>
        )}
      </div>
    </div>
  );
}
