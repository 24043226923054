import { DateTime } from 'luxon';
import useCalendar, { useUpdateCalendar } from 'hooks/useCalendar';
import { usePreferences } from 'hooks/usePreferences';
import React, { useCallback, useMemo } from 'react';
import Tooltip from 'joy/Tooltip';
import IconCaret from './Icons/IconCaret';
import { startOfWeek } from 'utils/time';
import DatePicker from './DatePicker';
import classNames from 'classnames';

export default React.memo(function DateHeader(): JSX.Element | null {
  const preferences = usePreferences();
  const { startDate, previewDate } = useCalendar();
  const { setStartDate, setPreviewDate } = useUpdateCalendar();

  const calendarWeeksOffset = Math.round(
    startOfWeek(startDate, preferences?.calendarStartsOn).diff(
      startOfWeek(
        DateTime.now().setZone(preferences.timezone),
        preferences?.calendarStartsOn
      ),
      'weeks'
    ).weeks
  );

  const fullWeekText = useMemo(() => {
    const currentWeekString = `W${startDate.toFormat('W')}`;

    if (calendarWeeksOffset === 0) {
      return currentWeekString;
    }

    if (calendarWeeksOffset > 0) {
      return `${currentWeekString} (+${calendarWeeksOffset})`;
    }

    if (calendarWeeksOffset < 0) {
      return `${currentWeekString} (${calendarWeeksOffset})`;
    }
  }, [calendarWeeksOffset, startDate]);

  const onChange = useCallback(
    (date: Date) => {
      setStartDate(DateTime.fromJSDate(date));
      setPreviewDate(DateTime.fromJSDate(date));
    },
    [setPreviewDate, setStartDate]
  );

  if (!startDate) return null;

  return (
    <DatePicker
      shortcut="m"
      selected={previewDate.toJSDate()}
      onChange={onChange}
    >
      {(isOpen) => (
        <Tooltip
          content={isOpen ? 'Hide date picker' : 'Show date picker'}
          shortcut="M"
          placement="bottom-center"
        >
          <div
            className={classNames(
              'group flex items-center rounded-lg px-2.5 py-1 transition-colors hover:bg-gray-150 dark:hover:bg-gray-750',
              {
                'bg-gray-150 dark:bg-gray-750': isOpen,
              }
            )}
          >
            <div className="flex items-baseline gap-1 text-lg tracking-tight">
              <p className="font-semibold">{startDate.toFormat('MMMM')}</p>
              <p className="font-semibold">{startDate.toFormat('yyyy')}</p>
            </div>

            <div className="text-secondary ml-2 flex items-baseline gap-1 text-base tracking-tight">
              <p className="font-medium opacity-40">/</p>
              <p className="ml-1 font-medium">{fullWeekText}</p>
            </div>

            <div className="text-icons ml-2">
              <span
                className={classNames(
                  'flex scale-75 transform transition-transform',
                  {
                    '-rotate-180': isOpen,
                    'rotate-0 group-hover:-rotate-90': !isOpen,
                  }
                )}
              >
                <IconCaret />
              </span>
            </div>
          </div>
        </Tooltip>
      )}
    </DatePicker>
  );
});
