import { ColorFamily } from '@graphql-types@';
import { EventTimePicker } from 'components/EventPopover/EventTimePicker';
import { EventCardFocusProvider } from 'contexts/eventCardFocus';
import { DateTime } from 'luxon';
import useFocusWithin from 'hooks/useFocusWithin';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React, { useRef } from 'react';
import { PreferenceName } from 'types/preference';
import SwitchGroup from '../controls/SwitchGroup';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function CalendarWorkHours(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();

  const startTimeRef = useRef<HTMLDivElement | null>(null);
  const { hasFocus: startTimeHasFocus } = useFocusWithin(startTimeRef);

  const endTimeRef = useRef<HTMLDivElement | null>(null);
  const { hasFocus: endTimeHasFocus } = useFocusWithin(endTimeRef);

  return (
    <div className="flex flex-col">
      <SwitchGroup
        selected={preferences?.workHoursEnabled === true}
        onSelect={() =>
          updatePreference(
            PreferenceName.WorkHoursEnabled,
            !preferences?.workHoursEnabled
          )
        }
        onlyButtonClickable={true}
      >
        <PageControlHeader
          title="Enable Working Hours"
          description="Set the hours you're available during the day. This will affect slot sharing."
          noPadding={!preferences?.workHoursEnabled}
        />
      </SwitchGroup>

      {preferences?.workHoursEnabled && (
        <div className="flex flex-row">
          <EventCardFocusProvider hasFocus={startTimeHasFocus}>
            <div className="flex flex-1 flex-col space-y-2" ref={startTimeRef}>
              <small className="text-secondary mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                Start time
              </small>

              <EventTimePicker
                time={
                  preferences?.workHoursStartTime ||
                  DateTime.fromObject({ hour: 7, minute: 0 })
                }
                onChange={(newTime) =>
                  updatePreference(PreferenceName.WorkHoursStartTime, newTime)
                }
                colorFamily={ColorFamily.Pink}
                readOnly={false}
                className="group relative flex w-full flex-grow cursor-pointer items-center rounded-lg border border-transparent bg-gray-150 px-3 py-2.5 text-sm font-medium outline-none !transition-colors hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:border-gray-600"
              />
            </div>
          </EventCardFocusProvider>

          <EventCardFocusProvider hasFocus={endTimeHasFocus}>
            <div
              className="ml-7 flex flex-1 flex-col space-y-2"
              ref={endTimeRef}
            >
              <small className="text-secondary mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                End time
              </small>

              <EventTimePicker
                time={
                  preferences?.workHoursEndTime ||
                  DateTime.fromObject({ hour: 19, minute: 0 })
                }
                onChange={(newTime) =>
                  updatePreference(PreferenceName.WorkHoursEndTime, newTime)
                }
                colorFamily={ColorFamily.Pink}
                readOnly={false}
                className="group relative flex w-full flex-grow cursor-pointer items-center rounded-lg border border-transparent bg-gray-150 px-3 py-2.5 text-sm font-medium outline-none !transition-colors hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:border-gray-600"
              />
            </div>
          </EventCardFocusProvider>
        </div>
      )}
    </div>
  );
});
