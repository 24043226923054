import React, { useRef, useCallback, useState } from 'react';
import classNames from 'classnames';
import { default as JoyInput } from 'joy/Input';
import useHotkey from 'hooks/useHotkey';
import { useEffect } from 'react';

interface Props {
  value: string;
  placeholder: string;
  disabled?: boolean;
  onSubmit: (newValue: string) => void;
}

export default function Input({
  value,
  placeholder,
  disabled,
  onSubmit,
}: Props): JSX.Element {
  const [currentValue, setCurrentValue] = useState(value);
  const ref = useRef<HTMLInputElement | null>(null);

  const onBlur = useCallback(() => {
    onSubmit(currentValue);
  }, [currentValue, onSubmit]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useHotkey('enter', { scope: 'settings', enabledWithinInput: true }, () => {
    ref.current?.blur();
  });

  return (
    <JoyInput
      ref={ref}
      className={classNames(
        'rounded-lg bg-gray-100 px-3 py-3 text-sm outline-none transition-colors hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600',
        {
          'cursor-not-allowed text-gray-400 dark:text-gray-500': disabled,
        }
      )}
      disabled={disabled}
      onBlur={onBlur}
      value={currentValue}
      placeholder={placeholder}
      onChange={(event) => setCurrentValue(event.currentTarget.value)}
    />
  );
}
