import { useUser } from 'hooks/auth/authAtoms';
import { useUpdateModal } from 'hooks/useModal';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import { useRouter } from 'next/router';
import React from 'react';
import { ModalType } from 'types/modal';
import { AMIE_PATTERN_BG_HEIGHT } from 'utils/constants';

export default function ProfileView(): JSX.Element {
  const user = useUser();
  const { closeModal } = useUpdateModal();
  const { push } = useRouter();

  return (
    <div className="relative flex flex-col overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-750 dark:shadow-feint">
      <div className="absolute inset-0 h-full w-full">
        <div
          className="bg-pattern-amie h-full w-full animate-backgroundScroll"
          style={{
            height: AMIE_PATTERN_BG_HEIGHT * 2,
            backgroundSize: '100%',
          }}
        />
      </div>
      <div className="absolute inset-0 h-full w-full bg-gradient-to-t from-gray-100 to-gray-100/20 dark:from-gray-750 dark:to-gray-750/20" />

      <div className="flex h-48 w-full items-center overflow-hidden px-7">
        <div className="relative flex rounded-full shadow-lg">
          <Avatar size={110} name={user?.displayName || ''} src={user?.photo} />
        </div>
      </div>
      <div className="relative flex w-full items-center justify-between pb-8 pl-8 pr-8">
        <div className="flex flex-col">
          <div className="flex items-center gap-1">
            <h4 className="text-xl font-semibold leading-none">
              {user?.displayName}
            </h4>
          </div>

          <h4 className="text-secondary mt-1.5 text-base font-medium leading-none">
            {user?.email}
          </h4>
        </div>

        <Button
          className="rounded-lg border border-gray-150 px-6 py-3 text-sm font-semibold hover:bg-gray-150 dark:border-gray-600 dark:hover:bg-gray-600"
          onClick={() => {
            closeModal(ModalType.Settings);
            push(`/${user?.email}`);
          }}
        >
          Visit profile
        </Button>
      </div>
    </div>
  );
}
