import classNames from 'classnames';
import Button from 'joy/Button';
import React from 'react';
import { accentColorAtom } from 'hooks/preferences/preferencesAtoms';
import { useAtomValue } from 'jotai/utils';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

interface Props {
  children: React.ReactText;
  icon?: () => JSX.Element;
  onClick: () => void;
  active?: boolean;
  variant?: 'red';
  notification?: boolean;
}

export default function NavItem({
  onClick,
  children,
  icon: Icon,
  active,
  variant,
  notification,
}: Props): JSX.Element {
  const defaultEventColor = useAtomValue(accentColorAtom);
  const mappedColor = EVENT_COLOR_MAP[defaultEventColor];

  return (
    <Button
      onClick={onClick}
      className={classNames(
        'group relative my-0.5 flex items-center rounded-lg px-2 py-1.5 text-left text-sm font-medium',
        {
          'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-100':
            active && !variant,
          'text-gray-700 hover:bg-gray-200/40 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-gray-200':
            !active && !variant,
          'bg-red-200 text-red-500 dark:text-red-400':
            active && variant === 'red',
          'text-red-500 hover:bg-red-100 dark:text-red-400 dark:hover:bg-red-400/10':
            !active && variant === 'red',
        }
      )}
    >
      {Icon && (
        <div
          className={classNames('mr-2 transition-colors', {
            'text-gray-600 dark:text-gray-300': active && !variant,
            'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400':
              !active && !variant,
            'text-red-400': variant === 'red',
          })}
        >
          <Icon />
        </div>
      )}
      <span className="flex-1">{children}</span>

      {notification && (
        <span
          className={`inline-block h-2 w-2 rounded-full ${mappedColor.solidBg}`}
        />
      )}
    </Button>
  );
}
