import React from 'react';
import classNames from 'classnames';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { ColorFamily } from '@graphql-types@';
import {
  AMIE_VIDEO_LINK_PLACEHOLDER,
  getLocationMetadata,
  MEET_LINK_PLACEHOLDER,
  MEET_LINK_PLACEHOLDER_DRAFT,
} from 'utils/video';
import PreloadImages from './LocationPreloadProviderIcons';
import LocationDropdown from './LocationDropdown';
import LocationCombobox from './LocationComboBox';
// import { useAmieVideo } from './useLocation'; // needed for generating Amie Video link, see TODO

interface LocationInputProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  onSave?: () => void;
  readOnly: boolean;
  colorFamily: ColorFamily;
  hasFocus?: boolean;
  className?: string;
  inputClassName?: string;
  locationButtonClassName?: string;
  isSlotSharing?: boolean;
  eventId?: string;
  isDraft?: boolean;
}

export default function LocationInput({
  value = '',
  onChange,
  onSave,
  readOnly,
  colorFamily,
  hasFocus,
  className,
  inputClassName,
  locationButtonClassName,
  isSlotSharing,
  // eventId, // needed for generating Amie video link, see TODO
  isDraft,
}: LocationInputProps) {
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  const { isVideoLink } = getLocationMetadata(value);

  /* TODO:
   * the useEffect below needs to be commented back in
   * once we are able to re-add HMSRoomProvider in pages/_app.tsx
   */

  // useEffect(() => {
  //   if (value === AMIE_VIDEO_LINK_PLACEHOLDER) {
  //     useAmieVideo({ value, eventId, onChange });
  //   }
  // }, [value, eventId]);

  const isLoadingPlaceholderActive =
    MEET_LINK_PLACEHOLDER === value ||
    AMIE_VIDEO_LINK_PLACEHOLDER === value ||
    MEET_LINK_PLACEHOLDER_DRAFT === value;

  return (
    <div
      className={classNames(
        'flex w-full items-center truncate',
        {
          'h-6': !isSlotSharing,
        },
        className
      )}
    >
      <PreloadImages />
      {(!isLoadingPlaceholderActive && !isVideoLink) || readOnly ? (
        <LocationCombobox
          value={value}
          onSave={onSave}
          onChange={onChange}
          readOnly={readOnly}
          hasFocus={hasFocus}
          colorMap={colorMap}
          inputClassName={inputClassName}
          locationButtonClassName={locationButtonClassName}
          isDraft={isDraft}
        />
      ) : (
        <LocationDropdown
          value={value}
          colorMap={colorMap}
          hasFocus={hasFocus}
          onSave={onSave}
          onChange={onChange}
          inputClassName={inputClassName}
          isLoading={isLoadingPlaceholderActive}
          className={classNames({ ['w-56']: isSlotSharing })}
          isDraft={isDraft}
          readOnly={readOnly}
          locationButtonClassName={locationButtonClassName}
          // do not show loading icon for slot sharing
          // it will make users wait endlessly if they select  Google Meet
          // as the url won't be generated until later
        />
      )}
    </div>
  );
}
