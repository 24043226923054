import React from 'react';

interface Props {
  href: string;
  children: React.ReactNode;
}

export default function ExternalLink({ href, children }: Props): JSX.Element {
  return (
    <a
      className="font-medium text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
}
