/* c595b9f46d5dd8bc15a56c4c1caab958788c1aaf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCalendarMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  colorFamily?: Types.InputMaybe<Types.ColorFamily>;
  emoji?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateCalendarMutation = { __typename?: 'mutation_root', updateCalendar: { __typename?: 'MinimalCalendarInfo', colorFamily: Types.ColorFamily, emoji?: string | null, id: string, name: string } };


export const UpdateCalendarDocument = gql`
    mutation UpdateCalendar($id: String!, $colorFamily: ColorFamily, $emoji: String, $name: String) {
  updateCalendar(
    input: {id: $id, colorFamily: $colorFamily, emoji: $emoji, name: $name}
  ) {
    colorFamily
    emoji
    id
    name
  }
}
    `;

export function useUpdateCalendarMutation() {
  return Urql.useMutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(UpdateCalendarDocument);
};