import React, { useCallback } from 'react';
import classNames from 'classnames';
import Switch from 'joy/Switch';

interface Props {
  children: React.ReactNode;
  selected: boolean;
  centered?: boolean;
  disabled?: boolean;
  onSelect: () => void;
  onlyButtonClickable?: boolean;
}

export default function SwitchGroup({
  children,
  selected,
  disabled,
  onlyButtonClickable,
  centered = false,
  onSelect,
}: Props): JSX.Element {
  const onContainerClick = useCallback(() => {
    if (!onlyButtonClickable) {
      onSelect();
    }
  }, [onlyButtonClickable, onSelect]);

  return (
    <div
      role="presentation"
      onClick={onContainerClick}
      className={classNames('flex justify-between', {
        'items-center': centered,
        'pointer-events-none': disabled,
        'group cursor-pointer': !onlyButtonClickable,
      })}
    >
      <div className="flex max-w-lg pr-2">{children}</div>

      <Switch
        selected={selected}
        onSelect={onSelect}
        onlyButtonClickable={onlyButtonClickable}
        disabled={disabled}
      />
    </div>
  );
}
