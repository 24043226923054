import { useDraggable } from '@dnd-kit/core';
import type { ColorFamily, New_Contact } from '@graphql-types@';
import classNames from 'classnames';
import { getAvatarStatus } from 'components/Status/utils';
import UserPopover from 'components/UserPopover';
import { useIsContactCalendarLoading } from 'hooks/contacts/useIsContactCalendarLoading';
import { useLoadCalendarsCurrentWeekEagerly } from 'hooks/useCalendarsEagerly';
import useHotkey from 'hooks/useHotkey';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import ContextMenu, {
  ContextMenuType,
  useContextMenu,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { DropdownItem } from 'joy/Dropdown';
import React, { useCallback, useMemo, useState } from 'react';
import { mergeRefs } from 'react-laag';
import { DraggableType } from 'types/drag-and-drop';
import { UserStatus } from 'types/status';
import { ARIA_LABEL_RIGHT_CLICK_MENU_OPTIONS } from 'utils/constants';
import { useCalendarColor } from './useAvailableCalendars';
import { useUnfollowUser, useViewUserProfile } from './useUserActions';

interface Props {
  active: boolean;
  index: number;
  color?: ColorFamily;
  user: Pick<
    New_Contact,
    'id' | 'emailAddress' | 'avatar' | 'displayName' | 'favoriteAt'
  >;
  status: UserStatus | null;
  onClick: () => void;
  reference?: React.Ref<HTMLDivElement>;
}

export default function ContactAvatar({
  active,
  index,
  color: colorOverride,
  user,
  status,
  onClick,
  reference,
}: Props): JSX.Element {
  const {
    setNodeRef,
    listeners,
    attributes,
    active: dragging,
  } = useDraggable({
    id: user.id || '',
    disabled: !user.id,
    data: {
      type: DraggableType.CONTACT,
      contact: user,
    },
  });
  const isLoading = useIsContactCalendarLoading(user.emailAddress || '');
  const colorFamily = useCalendarColor(user.emailAddress || '');
  const prefetchCalendarEvents = useLoadCalendarsCurrentWeekEagerly();

  useHotkey(`shift+${index}`, 'global', onClick);

  const unfollowContact = useUnfollowUser({
    userId: user.id || undefined,
    userEmail: user.emailAddress || undefined,
  });

  const viewUserProfile = useViewUserProfile({
    userEmail: user.emailAddress || undefined,
  });

  const menuItems: DropdownItem[] = useMemo(() => {
    const items: DropdownItem[] = [
      {
        type: 'option',
        value: 'View profile',
        onSelect: viewUserProfile,
      },
      {
        type: 'option',
        value: 'Show calendar',
        onSelect: onClick,
      },
      {
        type: 'option',
        value: 'Unfollow',
        onSelect: unfollowContact,
      },
    ];
    return items;
  }, [onClick, viewUserProfile, unfollowContact]);

  const contextMenu = useContextMenu();
  const isContextMenuOpen = contextMenu === ContextMenuType.Contact;
  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();

  const [isItemMenuActive, setIsItemMenuActive] = useState(false);

  const onContextMenuClose = useCallback(() => {
    closeContextMenu(ContextMenuType.Contact);
    setIsItemMenuActive(false);
  }, [closeContextMenu]);

  const onContextMenuOpen = useCallback(() => {
    openContextMenu(ContextMenuType.Contact);
    setIsItemMenuActive(true);
  }, [openContextMenu]);

  return (
    <ContextMenu
      items={menuItems}
      onClose={onContextMenuClose}
      placement="right-start"
    >
      <div
        ref={mergeRefs(setNodeRef, reference)}
        {...listeners}
        {...attributes}
        onMouseEnter={() => prefetchCalendarEvents(user.emailAddress)}
      >
        <UserPopover
          user={user}
          placement="right-start"
          status={status || undefined}
          // do not show popover if context menu is open
          // or the avatar is being dragged
          disabled={!!dragging?.id || isContextMenuOpen}
        >
          <Button
            ariaLabel={ARIA_LABEL_RIGHT_CLICK_MENU_OPTIONS}
            className="group flex h-full w-full items-center justify-center rounded-full"
            onClick={onClick}
            onContextMenu={onContextMenuOpen}
          >
            <Avatar
              isSidebar={true}
              size={34}
              alternateStatus={true}
              src={user.avatar}
              name={user?.displayName || user?.emailAddress || ''}
              isLoading={isLoading}
              ring={active ? colorOverride || colorFamily : undefined}
              status={getAvatarStatus(status?.status)}
              statusClassName="border-3 border-background"
              className={classNames(
                'transition-opacity group-hover:opacity-100',
                {
                  'opacity-100': active || isItemMenuActive,
                  'opacity-75': !active && !isItemMenuActive,
                  'opacity-20': dragging?.id === user.id,
                }
              )}
            />
          </Button>
        </UserPopover>
      </div>
    </ContextMenu>
  );
}
