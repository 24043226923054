import { logout } from 'utils/logout';
import React from 'react';
import useChangeLogCount from 'hooks/useChangelogCount';
import IconLogOut from './icons/IconLogOut';
import NavItem from './NavItem';
import NavItemSeparator from './NavItemSeparator';
import NavItemTitle from './NavItemTitle';
import { SettingsPage, SettingsPageGroup, SettingsPageType } from './types';
import { settingsPages } from './utils';

interface Props {
  active: SettingsPageType;
  onNavigate: (page: SettingsPageType) => void;
}

const userSettingsGroup: SettingsPageGroup = {
  title: 'User Settings',
  pages: [
    settingsPages[SettingsPageType.Profile],
    settingsPages[SettingsPageType.Accounts],
    settingsPages[SettingsPageType.Connections],
  ],
};

const appSettingsGroup: SettingsPageGroup = {
  title: 'App Settings',
  pages: [
    settingsPages[SettingsPageType.Calendar],
    settingsPages[SettingsPageType.Appearance],
    settingsPages[SettingsPageType.Meetings],
  ],
};

const miscSettingsGroup: SettingsPageGroup = {
  pages: [
    settingsPages[SettingsPageType.Notifications],
    settingsPages[SettingsPageType.Shortcuts],
    settingsPages[SettingsPageType.Download],
  ],
};

const bottomSettingsGroup: SettingsPageGroup = {
  pages: [
    settingsPages[SettingsPageType.WhatsNew],
    settingsPages[SettingsPageType.Feedback],
  ],
};

function buildGroup({
  group,
  active,
  onNavigate,
}: {
  group: SettingsPageGroup;
  active: SettingsPageType;
  onNavigate: Props['onNavigate'];
}) {
  const NavItems = group.pages.map((page) => (
    <NavItem
      key={page.type}
      active={active === page.type}
      icon={page.icon}
      notification={page.notification?.hasNotification}
      onClick={() => {
        if (page.notification?.callback) page.notification.callback();
        return onNavigate(page.type);
      }}
    >
      {page.title}
    </NavItem>
  ));

  const NavHeader = group.title ? (
    <NavItemTitle key={group.title}>{group.title}</NavItemTitle>
  ) : undefined;

  return [NavHeader, ...NavItems];
}

function useEnrichNavItemWithNotification({
  pageType,
  group,
  notification,
}: {
  pageType: SettingsPageType;
  group: SettingsPageGroup;
  notification: SettingsPage['notification'];
}): SettingsPageGroup {
  if (!notification) return group;

  return {
    ...group,
    pages: group.pages.map((page) => {
      if (page.type === pageType) {
        return {
          ...page,
          notification,
        };
      }

      return page;
    }),
  };
}

export default function Nav({ active, onNavigate }: Props): JSX.Element {
  const { hasNewItem, setHasReadChangelog } = useChangeLogCount();
  const enrichedBottomSettingsGroup = useEnrichNavItemWithNotification({
    pageType: SettingsPageType.WhatsNew,
    group: bottomSettingsGroup,
    notification: {
      callback: setHasReadChangelog,
      hasNotification: hasNewItem,
    },
  });

  return (
    <nav className="ml-auto flex w-44 flex-col py-16">
      <div className="-mt-1.5 flex flex-col">
        {buildGroup({
          group: userSettingsGroup,
          active,
          onNavigate,
        })}
        <NavItemSeparator />
        {buildGroup({
          group: appSettingsGroup,
          active,
          onNavigate,
        })}
        <NavItemSeparator />
        {buildGroup({
          group: miscSettingsGroup,
          active,
          onNavigate,
        })}
        <NavItemSeparator />
        {buildGroup({
          group: enrichedBottomSettingsGroup,
          active,
          onNavigate,
        })}
        <NavItemSeparator />
        <NavItem icon={IconLogOut} variant="red" onClick={() => logout()}>
          Log out
        </NavItem>
      </div>
    </nav>
  );
}
