import { useUser } from 'hooks/auth/authAtoms';
import { useCalendarHistorySubscription } from 'graphql/subscriptions/calendarHistory.graphql';
import { useFetchVisibleWeekEvents } from 'hooks/events/useGridEvents';
import useCalendar from 'hooks/useCalendar';
import { useVisibleCalendarIds } from 'hooks/useVisibleCalendars';
import useWindowFocus from 'hooks/useWindowFocus';
import React, { useEffect } from 'react';
import { dateYearOrdinal } from 'utils/time';
import usePrevious from 'hooks/usePrevious';
import {
  useLoadCalendarsNextWeekEagerly,
  useLoadCalendarsPreviousWeekEagerly,
} from 'hooks/useCalendarsEagerly';

export const SubscribeForCalendarsUpdates = React.memo(
  function SubscribeForCalendarsUpdatesContent() {
    const calendarIds = useVisibleCalendarIds();
    return (
      <>
        {calendarIds.map((calendarId) => (
          <SubscribeForCalendar calendarId={calendarId} key={calendarId} />
        ))}
      </>
    );
  }
);

const SubscribeForCalendar = React.memo(function SubscribeForCalendar(props: {
  calendarId: string;
}): null {
  const user = useUser();
  const { startDate, endDate } = useCalendar();
  const ordinalStart = dateYearOrdinal(startDate);
  const previousOrdinalStart = usePrevious(ordinalStart) ?? ordinalStart;
  const pause = !user?.didAuth;
  const { didRefocus } = useWindowFocus();
  const refreshCalendarEvents = useFetchVisibleWeekEvents();
  const prefetchNextWeekEvents = useLoadCalendarsNextWeekEagerly();
  const prefetchPrevWeekEvents = useLoadCalendarsPreviousWeekEagerly();
  const [subscriptionRes] = useCalendarHistorySubscription({
    variables: {
      calendarIds: [props.calendarId],
    },
    pause,
  });
  const calendarUpdatedAt = subscriptionRes.data?.calendarHistory[0]?.updatedAt;

  useEffect(() => {
    /**
     * Refetch events if subscription event arrives about a calendar update.
     * or if startDate or endDate changes
     */
    if (ordinalStart >= previousOrdinalStart) {
      prefetchNextWeekEvents(props.calendarId);
    } else {
      prefetchPrevWeekEvents(props.calendarId);
    }
    refreshCalendarEvents(props.calendarId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarUpdatedAt, startDate.toMillis(), endDate.toMillis()]);

  useEffect(() => {
    /**
     * Refetch events if window regains focus. Handle manually since
     * this doesn't get fetched with urql "provider".
     */
    if (didRefocus) {
      refreshCalendarEvents(props.calendarId);
    }
  }, [didRefocus, props.calendarId, refreshCalendarEvents]);

  return null;
});
