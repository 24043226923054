import classNames from 'classnames';
import React from 'react';

export function Card(props: { children: React.ReactNode; className?: string }) {
  return (
    <div className={classNames('flex flex-col relative', props.className)}>
      {props.children}
    </div>
  );
}
