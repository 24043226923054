import React from 'react';
import { AMIE_PATTERN_BG_HEIGHT } from 'utils/constants';
import { CalendarLink } from 'hooks/calendarLink/calendarLinkAtoms';
import { Card } from './Card';
import { CardInput } from './CardInput';
import { CardTitle } from './CardTitle';

export function TitleAndDescriptionCard({
  calendarLink,
  onChange,
}: {
  calendarLink: CalendarLink;
  onChange: (calendarLink: CalendarLink) => void;
}) {
  const handleChangeTitle = (title: string) => {
    onChange({ ...calendarLink, title });
  };
  const handleChangeDescription = (description: string) => {
    onChange({ ...calendarLink, description });
  };

  return (
    <Card className="w-full">
      <div className="fade fade-bg absolute top-0 left-0 -mt-4 h-16 w-full cursor-pointer overflow-hidden rounded-t-xl">
        <div
          className="bg-pattern-amie h-full w-full animate-backgroundScroll bg-contain bg-repeat-y"
          style={{
            height: AMIE_PATTERN_BG_HEIGHT * 3,
          }}
        />
      </div>
      <div className="mt-auto flex flex-col px-3">
        <CardTitle className="mb-2 text-green-500 dark:text-green-300">
          Title
        </CardTitle>
        <CardInput onChange={handleChangeTitle} value={calendarLink.title} />

        <CardTitle className="mb-2 mt-4  text-yellow-500 dark:text-yellow-200">
          Description
        </CardTitle>
        <CardInput
          onChange={handleChangeDescription}
          value={calendarLink.description}
          type="textarea"
          placeholder="e.g Let's meet to chat"
        />
      </div>
    </Card>
  );
}
