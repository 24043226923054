import classNames from 'classnames';
import IconCake from 'components/Icons/IconCake';
import IconQuestionMarkCircle from 'components/Icons/IconQuestionMarkCircle';
import { AnimatePresence } from 'framer-motion';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useLayer } from 'react-laag';
import { BirthdayDialog } from './WidgetBirthdayDialog';
import { WidgetBirthdayPicker } from './WidgetBirthdayPicker';

interface Props {
  date: string | null;
  isSelf: boolean;
  isSuggested: boolean;
  setDate: (date: Date) => Promise<void>;
  context?: 'profile' | 'settings' | 'onboarding';
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthDays = {
  january: 31,
  // TODO: Support 29 Feb: https://linear.app/amie/issue/AMIE-3349/support-29-february-as-birthday
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
};

export default function WidgetBirthday({
  date,
  isSelf,
  isSuggested,
  setDate,
  context = 'profile',
}: Props) {
  const parsedDate = date ? DateTime.fromISO(date) : DateTime.now();
  const hasValidDate = date != null && parsedDate.isValid;
  const relativeDate =
    parsedDate.ordinal < DateTime.now().ordinal
      ? parsedDate.plus({ year: 1 }).toRelativeCalendar({ unit: 'days' })
      : parsedDate
          .set({ year: DateTime.now().year })
          .toRelativeCalendar({ unit: 'days' });

  const unsetDescription = isSelf
    ? `You haven't set your birthday yet!`
    : 'Do you know it?';

  const [isEditing, setEditing] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: isEditing,
    onOutsideClick: () => setEditing(false),
    onParentClose: () => setEditing(false),
    placement: 'top-start',
    triggerOffset: 12,
  });

  const buttonTriggerProps = !hasValidDate ? triggerProps : {};
  const textTriggerProps = hasValidDate ? triggerProps : {};

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden rounded-3xl bg-gradient-to-t from-white to-white p-6 dark:bg-gradient-to-b dark:from-gray-600/10 dark:to-gray-800/40">
      <div className="fade-bg absolute inset-0 z-0 h-32 w-full">
        <div
          className="bg-pattern-birthday h-full w-full animate-backgroundBirthdayScroll bg-repeat opacity-5"
          style={{
            height: 128 * 2,
            backgroundSize: (() => {
              switch (context) {
                case 'settings':
                  return '50%';
                default:
                  return '100%';
              }
            })(),
          }}
        />
      </div>

      <div
        className="relative z-20 mt-auto flex flex-col"
        {...textTriggerProps}
        onClick={() => {
          if (!isSelf && !isSuggested) return;
          setEditing(!isEditing);
        }}
        tabIndex={-1}
        role="presentation"
      >
        <div className="mb-0.5 flex gap-0.5 text-pink-400 dark:text-pink-200">
          <small className="text-sm font-black uppercase tracking-wider">
            {hasValidDate ? `Birthday` : 'Birthday not set'}
          </small>
          {isSuggested && (
            <Tooltip content="Not yet verified by this person">
              <small className="flex cursor-default text-sm font-black uppercase tracking-wider opacity-60">
                <IconQuestionMarkCircle />
              </small>
            </Tooltip>
          )}
        </div>

        <div className={'flex text-left text-xl font-black'}>
          <Tooltip
            disabled={!hasValidDate || (!isSelf && !isSuggested)}
            content={isSelf ? 'Edit birthday' : 'Edit suggested date'}
            placement="right-center"
          >
            <p
              className={classNames('inline', {
                'cursor-pointer':
                  !isEditing && hasValidDate && (isSelf || isSuggested),
              })}
            >
              {hasValidDate ? parsedDate.toFormat('MMMM d') : unsetDescription}
            </p>
          </Tooltip>
        </div>

        {hasValidDate && (
          <small className="text-secondary mt-0.5 text-base font-medium">
            {capitalize(relativeDate || '')}
          </small>
        )}

        {!hasValidDate && (
          <div className="mt-4 flex">
            <Button
              {...buttonTriggerProps}
              onClick={() => setEditing(!isEditing)}
              className="flex w-full max-w-[220px] items-center justify-center rounded-full bg-gray-200 py-3 text-sm font-semibold dark:bg-gray-700 dark:hover:bg-gray-600"
            >
              <div className="mr-1.5 flex text-gray-400 dark:text-gray-500">
                <IconCake />
              </div>
              {isSelf ? 'Set date' : 'Suggest date'}
            </Button>
          </div>
        )}
      </div>
      {renderLayer(
        <AnimatePresence>
          {isEditing && (
            <BirthdayDialog
              title={hasValidDate ? 'Edit birthday' : 'Set birthday'}
              {...layerProps}
              onClose={() => setEditing(false)}
            >
              <WidgetBirthdayPicker
                date={date}
                onChange={(date) => {
                  setDate(date);
                  setEditing(false);
                }}
              />
            </BirthdayDialog>
          )}
        </AnimatePresence>
      )}
    </div>
  );
}
