import { Theme_Enum } from '@graphql-types@';
import classNames from 'classnames';
import { usePreferences } from 'hooks/usePreferences';
import React from 'react';
import { DownloadCardTitle } from './DownloadTitle';

export function DownloadMobileCard() {
  const { theme } = usePreferences();
  return (
    <div
      className={classNames(
        'relative h-[230px] w-full overflow-hidden rounded-3xl bg-white shadow-feint dark:bg-gray-750'
      )}
    >
      <div className="flex h-full justify-between">
        <div className="w-4/12 p-6">
          <DownloadCardTitle
            title={`Mobile App`}
            subtitle={`Scan to download on Testflight`}
          />
        </div>
        <div className="relative w-8/12 pr-10">
          <div className="flex h-full items-center">
            <div className="absolute bottom-0 left-0 mb-6 w-[150px]">
              <img
                src={`/assets/download/ios-${
                  theme === Theme_Enum.Dark ? 'dark' : 'light'
                }.png`}
                alt="mobile app"
              />
            </div>

            <div className="ml-auto rounded-2xl border border-gray-200 p-1 dark:border-none">
              <div className="overflow-hidden rounded-lg">
                <img
                  src="/assets/download/qr-code.png"
                  width={106}
                  height={106}
                  alt="qr code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
