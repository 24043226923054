import React from 'react';
import FollowToggle from 'components/FollowToggle';

interface Props {
  compact?: boolean;
  isFollowing: boolean;
  toggle: (state?: boolean) => Promise<void>;
}

export default React.memo(function PopoverFollowToggle({
  isFollowing,
  toggle,
}: Props) {
  return (
    <FollowToggle
      compact={true}
      isFollowing={isFollowing}
      handleClick={toggle}
    />
  );
});
