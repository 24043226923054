/* 60d01d2c14b4a1fced3f0a96bd0a01cd39423b64
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type DeleteLinkMutation = { __typename?: 'mutation_root', delete_shareCalendarLink_by_pk?: { __typename?: 'shareCalendarLink', id: any } | null };


export const DeleteLinkDocument = gql`
    mutation DeleteLink($id: uuid!) {
  delete_shareCalendarLink_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteLinkMutation() {
  return Urql.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(DeleteLinkDocument);
};