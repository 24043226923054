import { CalendarStartsOn_Enum } from '@graphql-types@';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import RadioList from '../controls/RadioList';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function CalendarStartDay(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.CalendarStartsOn;
  const preferenceValue = preferences?.calendarStartsOn;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Start day"
        description="Choose what day the calendar week should start."
      />

      <RadioList
        items={[
          {
            title: 'Sunday',
            selected: preferenceValue === CalendarStartsOn_Enum.Sunday,
            onSelect: () =>
              updatePreference(preferenceName, CalendarStartsOn_Enum.Sunday),
          },
          {
            title: 'Monday',
            selected: preferenceValue === CalendarStartsOn_Enum.Monday,
            onSelect: () =>
              updatePreference(preferenceName, CalendarStartsOn_Enum.Monday),
          },
          {
            title: 'Today',
            selected: preferenceValue === CalendarStartsOn_Enum.Rolling,
            onSelect: () =>
              updatePreference(preferenceName, CalendarStartsOn_Enum.Rolling),
          },
        ]}
      />
    </div>
  );
});
