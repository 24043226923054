import { motion } from 'framer-motion';
import useHotkey from 'hooks/useHotkey';
import React from 'react';

export const BirthdayDialog = React.forwardRef(
  (
    {
      title,
      onClose,
      children,
      ...props
    }: {
      title: string;
      children: React.ReactNode;
      onClose: () => void;
    },
    ref: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    useHotkey(
      'escape',
      {
        scope: 'all',
      },
      (e) => {
        e.preventDefault();
        onClose();
      }
    );

    useHotkey(
      'enter',
      {
        scope: 'all',
        enabledWithinInput: false,
      },
      (e) => {
        e.preventDefault();
        onClose();
      }
    );

    return (
      <motion.div
        {...props}
        ref={ref}
        initial={{
          opacity: 0,
          scale: 0.99,
          y: -8,
        }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{
          opacity: 0,
          scale: 0.99,
          y: -8,
        }}
        transition={{
          duration: 0.08,
        }}
      >
        <div className="bg-popover pointer-events-auto z-100 w-full rounded-xl p-4 shadow-feintXl transition-colors">
          <div className="text-base font-semibold">{title}</div>
          {children}
        </div>
      </motion.div>
    );
  }
);

BirthdayDialog.displayName = 'BirthdayDialog';
