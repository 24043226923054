import React from 'react';
import PageHeader from './PageHeader';
import NotifcationsEmail from './rows/NotifcationsEmail';
import NotifcationsPush from './rows/NotifcationsPush';
import NotifcationsTodoSchedule from './rows/NotifcationsTodoSchedule';
import RowSeparator from './rows/RowSeparator';

export default function SettingsPageNotifications(): JSX.Element {
  return (
    <>
      <PageHeader title="Notifications" />

      <NotifcationsPush />
      <RowSeparator />
      <NotifcationsEmail />
      <RowSeparator />
      <NotifcationsTodoSchedule />
    </>
  );
}
