import { usePreloadImages } from 'hooks/usePreloadImages';

const videoProviderImages = [
  `/assets/logo.svg`,
  `/assets/video-providers/around@3x.png`,
  `/assets/video-providers/meet@3x.png`,
  `/assets/video-providers/zoom@3x.png`,
];

export default function PreloadImages() {
  usePreloadImages(videoProviderImages);
  return null;
}
