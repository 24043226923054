import React from 'react';
import PageHeader from './PageHeader';
import FeedbackContext from 'components/Feedback/FeedbackContent';

export default function SettingsPageFeedback(): JSX.Element {
  return (
    <>
      <PageHeader title="Send feedback" />
      <div className="h-[190px]">
        <FeedbackContext showHeader={false} />
      </div>
    </>
  );
}
