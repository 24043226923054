/* 823862309d8e62fc63e0af97d6340d961902f938
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCalendarLinkMutationVariables = Types.Exact<{
  objects: Types.CreateCalendarLinkInput;
}>;


export type CreateCalendarLinkMutation = { __typename?: 'mutation_root', createCalendarLink: { __typename?: 'CreateCalendarLinkOutput', id: string, alias?: string | null } };


export const CreateCalendarLinkDocument = gql`
    mutation CreateCalendarLink($objects: CreateCalendarLinkInput!) {
  createCalendarLink(objects: $objects) {
    id
    alias
  }
}
    `;

export function useCreateCalendarLinkMutation() {
  return Urql.useMutation<CreateCalendarLinkMutation, CreateCalendarLinkMutationVariables>(CreateCalendarLinkDocument);
};