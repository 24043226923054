import classNames from 'classnames';
import IconCall from 'components/Icons/IconCall';
import IconExternalLink from 'components/Icons/IconExternalLink';
import IconMapPin from 'components/Icons/IconMapPin';
import { useUserEmail } from 'hooks/auth/authAtoms';
import { motion } from 'framer-motion';
import Tooltip from 'joy/Tooltip';
import React from 'react';
import {
  getLocationMetadata,
  getVideoProvider,
  handleVideoClick,
  MEET_LINK_PLACEHOLDER_DRAFT,
} from 'utils/video';

interface Props {
  location: string | undefined | null;
  visible?: boolean;
  className?: string;
}

export default function GoToLocationButton({
  location,
  className,
  visible,
}: Props): JSX.Element | null {
  const userEmail = useUserEmail();

  if (!location || !visible) return null;

  const { label, href, isExternal, isVideoLink } =
    getLocationMetadata(location);

  const videoProvider = getVideoProvider(location);

  return (
    <Tooltip disabled={label === undefined} content={label || ''}>
      <motion.div
        initial={{ opacity: 0, scale: 0.6, width: 0 }}
        animate={{ opacity: 1, scale: 1, width: 'auto' }}
        exit={{ opacity: 0, scale: 0.6, width: 0 }}
        transition={{ duration: 0.08 }}
        className="my-auto mx-2 flex items-center justify-center"
      >
        <a
          tabIndex={-1}
          onMouseDown={(event) => event.preventDefault()}
          onClick={(event) => {
            event.stopPropagation();
            if (!isVideoLink) return;

            handleVideoClick({
              event,
              url:
                videoProvider === MEET_LINK_PLACEHOLDER_DRAFT
                  ? `${location}?authuser=${userEmail}`
                  : location,
            });
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={href}
          className={classNames(
            'flex h-6 w-6 items-center justify-center rounded-full',
            'scale-100  transform transition-transform hover:scale-105 active:scale-100',
            'text-primary bg-gray-200 text-gray-500  dark:bg-gray-500 dark:text-gray-300',
            className
          )}
        >
          <span className="sr-only">{label}</span>
          {isVideoLink ? (
            <IconCall />
          ) : isExternal ? (
            <IconExternalLink />
          ) : (
            <IconMapPin />
          )}
        </a>
      </motion.div>
    </Tooltip>
  );
}
