import { useUserQuery } from 'graphql/queries/user.graphql';
import { usePopupInitiation } from 'hooks/useWindowPopup';
import React, { useState } from 'react';
import Connection from '../controls/Connection';
import IconSpotify from '../icons/IconSpotify';

export default React.memo(function ConnectionSpotify(): JSX.Element {
  const [userQueryRes, refetch] = useUserQuery();
  const [description, setDescription] = useState(
    'Track Spotify sessions in your calendar'
  );
  const { open } = usePopupInitiation({
    source: 'spotify',
    successCallback: () => {
      refetch({
        requestPolicy: 'network-only',
      });
    },
    errorCallback: () => {
      setDescription(
        '⚠️ Oops, we could not connect your Spotify account. Try again later!'
      );
    },
  });

  const onConnect = () => {
    return fetch('/api/spotify/login')
      .then((res) => res.json())
      .then((res) => {
        open(res.url);
      });
  };
  return (
    <Connection
      icon={IconSpotify}
      title="Spotify"
      connected={Boolean(userQueryRes.data?.new_user[0]?.spotifyRefreshToken)}
      comingSoon={false}
      onConnect={onConnect}
      onDisconnect={() => null}
      description={description}
    />
  );
});
