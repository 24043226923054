import { useContact, useContactUpdate } from 'hooks/useContacts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
  email: string;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default React.memo(function SectionNote({ email, setEditing }: Props) {
  const contact = useContact(email);
  const updateContact = useContactUpdate(email);
  const [noteValue, setNoteValue] = useState(contact?.notes || '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const limitLines = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.keyCode !== 13) return;

      event.preventDefault();
      event.currentTarget.blur();
    },
    []
  );

  const onBlur = useCallback(() => {
    updateContact({ notes: textareaRef.current?.value || '' });
    setEditing?.(false);
  }, [updateContact, setEditing]);

  useEffect(() => {
    if (typeof contact?.notes !== 'string') return;
    setNoteValue(contact?.notes);
  }, [contact?.notes]);

  if (!contact) return null;

  return (
    <div className="flex w-full flex-col space-y-0.5">
      <small className="text-secondary text-[11px] font-black uppercase leading-tight tracking-wider">
        Note
      </small>
      <TextareaAutosize
        ref={textareaRef}
        className="mt-0.5 -ml-1.5 -mr-1.5 resize-none rounded-md border-none bg-transparent p-1.5 text-s font-medium leading-snug outline-none transition-colors hover:bg-gray-100 focus:bg-gray-100 dark:hover:bg-gray-750 dark:focus:bg-gray-750"
        onChange={(event) => {
          setNoteValue(event.target.value);
          setEditing?.(true);
        }}
        onKeyDown={limitLines}
        onBlur={onBlur}
        spellCheck={false}
        value={noteValue}
        placeholder="Keep a note for yourself"
        maxLength={120}
        wrap="hard"
      />
    </div>
  );
});
