import React from 'react';
import dynamic from 'next/dynamic';
import PageHeader from './PageHeader';
// Dynamic import to delay loading markdown, etc...
const Changelog = dynamic(() => import('./Changelog'));

export default function SettingsPageWhatsNew(): JSX.Element {
  return (
    <>
      <PageHeader title="What's new" />
      <Changelog />
    </>
  );
}
