import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

type PopupSource = 'spotify' | 'zoom';

/**
 * Used within popup. Handles closing the popup and sending
 * url params back to originating component.
 */
export function useAfterPopupCallback({
  source,
}: {
  source: PopupSource;
}): void {
  const { query } = useRouter();
  const memoQuery = useMemo(() => query, [query]);

  useEffect(() => {
    if ('success' in memoQuery) {
      window.opener.postMessage({
        source,
        payload: {
          success: memoQuery.success === 'true' ? true : false,
        },
      });
    } else {
      window.opener.postMessage({
        source,
        payload: {
          error: 'No query params from callback.',
        },
      });
    }

    window.addEventListener('beforeunload', () => {
      window.opener.postMessage({ source, payload: 'close' });
    });

    window.close();
  }, [source, memoQuery]);
}

/**
 * Handles opening a popup flow when a third-party service
 * needs to send back query params via a callback url.
 */
export function usePopupInitiation(config: {
  source: PopupSource;
  successCallback: () => void;
  errorCallback: () => void;
}) {
  const { source, successCallback, errorCallback } = useMemo(
    () => config,
    [config]
  );

  const open = (url: string) => {
    const popup = window.open(
      url,
      source,
      'width=800,height=600,location=no,menubar=no,toolbar=no,left=100,top=100,scrollbars=no'
    );

    if (!popup) {
      console.error('No popup able to launch');
    }
  };

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (window.location.href.startsWith(event.origin) === false) return;
      if (event.data.source !== source) return;

      if (event.data.payload?.success) {
        return successCallback();
      }

      return errorCallback();
    };

    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  }, [source, successCallback, errorCallback]);

  return {
    open,
  };
}
