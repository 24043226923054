import React from 'react';
import { useUpdateModal } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import Button from 'joy/Button';
import IconClose from 'components/Icons/IconClose';
import { TEST_ID_CLOSE_PREFERENCE_PANEL } from 'utils/constants';
import Tooltip from 'joy/Tooltip';

export default function SettingsCloseButton(): JSX.Element {
  const { closeModal } = useUpdateModal();

  return (
    <Tooltip content="Close" shortcut="esc">
      <Button
        className="group flex h-10 w-10 scale-100 items-center justify-center rounded-full bg-gray-100 transition-all hover:scale-105 hover:bg-gray-200 active:scale-95 dark:bg-gray-700 dark:hover:bg-gray-600"
        onClick={() => closeModal(ModalType.Settings)}
        data-testid={TEST_ID_CLOSE_PREFERENCE_PANEL}
      >
        <div className="flex h-5 w-5 opacity-60 group-hover:opacity-80">
          <IconClose />
        </div>
      </Button>
    </Tooltip>
  );
}
