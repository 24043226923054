import useUserAgent from 'hooks/useUserAgent';
import Button from 'joy/Button';
import React from 'react';

export const WINDOWS_LINK = `https://github.com/amieso/electron-releases/releases/download/v1.0.7/Amie-Setup-1.0.7.exe`;
export const MAC_M1_LINK = `https://github.com/amieso/electron-releases/releases/download/v1.0.9/Amie-1.0.9-arm64-mac.zip`;
export const MAC_INTEL_LINK = `https://github.com/amieso/electron-releases/releases/download/v1.0.9/Amie-1.0.9-mac.zip`;

function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.download = 'Amie';
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function DownloadButtons() {
  const { isMac } = useUserAgent();

  return (
    <div>
      <Button
        className="flex h-12 items-center space-x-2 bg-gray-200 px-6 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
        variant="base"
        onClick={() => {
          if (isMac) {
            downloadURI(MAC_M1_LINK);
          } else {
            downloadURI(WINDOWS_LINK);
          }
        }}
      >
        <img
          src={`/assets/download/${isMac ? 'apple' : 'windows'}-icon.svg`}
          alt="Download"
        />
        <span className="font-semibold dark:text-gray-200">
          Download for {isMac ? 'Mac (M1)' : 'Windows'}
        </span>
      </Button>
      <p className="text-secondary mt-2 text-xs">
        Or for{' '}
        {isMac && (
          <>
            <a
              href={MAC_INTEL_LINK}
              onClick={(e) => {
                e.preventDefault();
                downloadURI(MAC_INTEL_LINK);
              }}
              target="_blank"
              className="hover:text-primary font-semibold"
              rel="noreferrer"
            >
              Mac with Intel
            </a>{' '}
          </>
        )}
        or{' '}
        <a
          href={isMac ? WINDOWS_LINK : MAC_M1_LINK}
          onClick={(e) => {
            e.preventDefault();
            downloadURI(isMac ? WINDOWS_LINK : MAC_M1_LINK);
          }}
          className="hover:text-primary font-semibold"
        >
          {isMac ? 'Windows' : 'Mac OS'}
        </a>
      </p>
    </div>
  );
}
