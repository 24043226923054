import useCurrentTime from 'hooks/useCurrentTime';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import { TWELVE_HOUR_FORMAT, TWENTY_FOUR_HOUR_FORMAT } from 'utils/format';
import RadioList from '../controls/RadioList';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function CalendarTimeFormat(): JSX.Element {
  const date = useCurrentTime();
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.Ui24HourClock;
  const preferenceValue = preferences.ui24HourClock;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Time Format"
        description="Choose the way times are displayed."
      />

      <RadioList
        items={[
          {
            title: '12 hour format',
            example: date ? date.toFormat(TWELVE_HOUR_FORMAT.long) : undefined,
            selected: preferenceValue === false,
            onSelect: () => updatePreference(preferenceName, false),
          },
          {
            title: '24 hour format',
            example: date
              ? date.toFormat(TWENTY_FOUR_HOUR_FORMAT.long)
              : undefined,
            selected: preferenceValue === true,
            onSelect: () => updatePreference(preferenceName, true),
          },
        ]}
      />
    </div>
  );
});
