import React, { useMemo, useRef, useState } from 'react';
import { ModalType } from 'types/modal';
import { useIsModalOpen } from 'hooks/useModal';
import { useFocusTrap } from 'hooks/useFocusTrap';
import Button from 'joy/Button';
import IconCaret from 'components/Icons/IconCaret';
import Dropdown from 'joy/Dropdown';
import { EventConfirmation } from 'hooks/useEventConfirmations';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  getConfirmationLabel,
  getConfirmationDescription,
  getButtonLabel,
  getConfirmationDiffArray,
  getAttendeeDiff,
} from './utils';
import { useUi24HourClock } from 'hooks/usePreferences';
import { getTimeFormat } from 'utils/format';
import Switch from 'joy/Switch';
import DiffAttendee from './DiffGuest';
import dynamic from 'next/dynamic';
import useHotkey from 'hooks/useHotkey';

const EventConfirmationDiff = dynamic(() => import('./EventConfirmationDiff'));

export default function ModalEventConfirmationContent({
  event,
  type,
  diff,
  isRecurring,
  onResolve,
}: EventConfirmation) {
  const hasExistingAttendees =
    event.attendees.filter(({ organizer }) => !organizer).length > 0;
  const [applyToAllEvents, setApplyToAllEvents] = useState(false);
  const [diffExpanded, setDiffExpanded] = useState(false);

  const isConfirmationModalOpen = useIsModalOpen(ModalType.EventConfirmation);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const ui24HourClock = useUi24HourClock();
  const timeFormatPreference = getTimeFormat(ui24HourClock);

  const diffArray = useMemo(() => {
    if (!diff) {
      return null;
    }
    return getConfirmationDiffArray(diff, timeFormatPreference.long);
  }, [diff, timeFormatPreference.long]);
  const diffChangesCount = diffArray ? diffArray.length : 0;

  const attendeeDiff = getAttendeeDiff(diff);
  const hasDiff = diffChangesCount > 0;
  const hasAttendeeDiff =
    attendeeDiff.added.length > 0 || attendeeDiff.removed.length > 0;

  const hasNewInvites = attendeeDiff.added.length > 0;

  useFocusTrap({ active: isConfirmationModalOpen, ref: popoverRef });

  useHotkey('cmd+enter', 'modal', () =>
    onResolve({
      cancelled: false,
      applyToAllEvents,
      notifyGuests: true,
    })
  );

  useHotkey('escape', 'modal', () =>
    onResolve({
      cancelled: true,
      applyToAllEvents: false,
      notifyGuests: false,
    })
  );

  return (
    <>
      <div className="relative flex flex-1 flex-col p-5">
        <div
          className={classNames('mb-3 flex self-start rounded-md py-1 px-2', {
            'bg-green-100 text-green-500 dark:bg-green-400/20 dark:text-green-300':
              type === 'create',
            'bg-blue-100 text-blue-500 dark:bg-blue-400/20 dark:text-blue-300':
              type === 'update',
            'bg-red-100 text-red-500 dark:bg-red-400/20 dark:text-red-300':
              type === 'delete',
          })}
        >
          <small className="text-[11px] font-black uppercase leading-snug tracking-wider">
            {getConfirmationLabel({
              event,
              hasDiff,
              addedGuests: attendeeDiff.added.length,
              removedGuests: attendeeDiff.removed.length,
              isRecurring,
              hasExistingAttendees,
              type,
            })}
          </small>
        </div>

        <p className="mb-0.5 text-base font-semibold">{event.title}</p>
        <p className="text-secondary text-sm">
          {getConfirmationDescription({
            event,
            hasDiff: hasDiff,
            addedGuests: attendeeDiff.added.length,
            removedGuests: attendeeDiff.removed.length,
            isRecurring,
            hasExistingAttendees,
            type,
          })}
        </p>
      </div>

      {(hasDiff || hasAttendeeDiff) && (
        <div className="flex flex-col gap-4 px-5 pb-5">
          {attendeeDiff.added.length > 0 && (
            <div className="flex flex-col self-start">
              <div className="mb-2 flex">
                <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  {attendeeDiff.added.length} new{' '}
                  {attendeeDiff.added.length === 1 ? 'invite' : 'invites'}
                </small>
              </div>

              <div className="flex flex-col gap-1">
                {attendeeDiff.added.map((attendee) => (
                  <DiffAttendee key={attendee.email} attendee={attendee} />
                ))}
              </div>
            </div>
          )}

          {attendeeDiff.removed.length > 0 && (
            <div className="flex flex-col self-start">
              <div className="mb-2 flex">
                <small className="text-secondary text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  {attendeeDiff.removed.length}{' '}
                  {attendeeDiff.removed.length === 1 ? 'guest' : 'guests'}{' '}
                  removed
                </small>
              </div>

              <div className="flex flex-col gap-1">
                {attendeeDiff.removed.map((attendee) => (
                  <DiffAttendee key={attendee.email} attendee={attendee} />
                ))}
              </div>
            </div>
          )}

          {hasDiff && diffArray !== null && (
            <div className="flex flex-col">
              <Button
                className="group flex items-center"
                onClick={() => setDiffExpanded((val) => !val)}
              >
                <small className="text-secondary mr-1 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors group-hover:text-gray-800 dark:group-hover:text-gray-200">
                  {diffChangesCount} {hasAttendeeDiff ? 'other' : ''}{' '}
                  {diffChangesCount === 1 ? 'change' : 'changes'}
                </small>

                <span
                  className={classNames(
                    'text-icons flex scale-75 transition-transform',
                    {
                      '-rotate-90': !diffExpanded,
                    }
                  )}
                >
                  <IconCaret />
                </span>
              </Button>

              <motion.div
                layout={true}
                className="flex flex-col overflow-hidden"
              >
                <AnimatePresence initial={false}>
                  {diffExpanded && (
                    <motion.div
                      layout={true}
                      initial={{ opacity: 0, height: 0, marginTop: 0 }}
                      animate={{ opacity: 1, height: 'auto', marginTop: 8 }}
                      exit={{ opacity: 0, height: 0, marginTop: 0 }}
                      className="flex flex-col gap-1"
                      transition={{ duration: 0.12 }}
                    >
                      <EventConfirmationDiff diff={diffArray} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </div>
          )}
        </div>
      )}

      <div
        className={classNames('flex w-full justify-between', {
          'mt-1.5 border-t p-5 dark:border-gray-700': isRecurring,
          'px-5 pb-5 pt-2': !isRecurring,
        })}
      >
        {isRecurring && (
          <Button
            as="div"
            role="button"
            className="group flex items-center gap-2"
            onClick={() => setApplyToAllEvents((val) => !val)}
          >
            <Switch
              isCompact={true}
              selected={applyToAllEvents}
              onSelect={() => setApplyToAllEvents((val) => !val)}
            />

            <small className="text-s font-medium">Apply to all events</small>
          </Button>
        )}

        <div className="ml-auto flex items-center gap-2">
          <Button
            className="flex h-9 scale-100 items-center rounded-md border border-gray-150 px-3 text-sm font-semibold text-gray-600 hover:bg-gray-150 active:scale-98 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 "
            onClick={() =>
              onResolve({
                cancelled: true,
                applyToAllEvents: false,
                notifyGuests: false,
              })
            }
          >
            {type === 'delete' ? 'Cancel' : 'Discard'}
          </Button>

          <div className="group flex h-9 items-center rounded-md text-white">
            <Button
              className={classNames('h-full  px-3 text-sm font-semibold', {
                'rounded-l-md': hasNewInvites || hasExistingAttendees,
                'rounded-md': !hasNewInvites && !hasExistingAttendees,
                'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700':
                  type !== 'delete',
                'bg-red-500 hover:bg-red-400 dark:bg-red-600 dark:hover:bg-red-700':
                  type === 'delete',
              })}
              onClick={() =>
                onResolve({
                  cancelled: false,
                  applyToAllEvents,
                  notifyGuests: true,
                })
              }
            >
              {getButtonLabel({
                event,
                hasDiff,
                addedGuests: attendeeDiff.added.length,
                removedGuests: attendeeDiff.removed.length,
                isRecurring,
                hasExistingAttendees,
                type,
              })}
            </Button>

            {(hasNewInvites || hasExistingAttendees) && (
              <Dropdown
                className="flex h-full w-8 items-center justify-center"
                items={[
                  {
                    value: `${getButtonLabel(
                      {
                        event,
                        hasDiff,
                        addedGuests: attendeeDiff.added.length,
                        removedGuests: attendeeDiff.removed.length,
                        isRecurring,
                        hasExistingAttendees,
                        type,
                      },
                      true
                    )} without notifiying`,
                    description: `Guests won't be sent an email`,
                    type: 'option',
                    onSelect: () =>
                      onResolve({
                        cancelled: false,
                        applyToAllEvents,
                        notifyGuests: false,
                      }),
                  },
                ]}
              >
                <Button
                  className={classNames(
                    'flex h-full w-full items-center justify-center rounded-r-md border-l border-white/20 group-hover:border-transparent dark:border-black/8',
                    {
                      'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700':
                        type !== 'delete',
                      'bg-red-500 hover:bg-red-400 dark:bg-red-600 dark:hover:bg-red-700':
                        type === 'delete',
                    }
                  )}
                >
                  <div className="scale-75 opacity-80">
                    <IconCaret />
                  </div>
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
